.view-our-category-wrape {
    margin-bottom: 90px;
}

.view-our-category-wrape .title-box {
    margin-bottom: 50px;
}

.view-our-category-wrape .title-box .title {
    font-size: 50px;
    line-height: 60px;
    font-weight: 600;
    margin-bottom: 5px;
}

.view-our-category-wrape .title-box .sub-title {
    color: #27447C;
    font-weight: 500;
}

.view-our-category-wrape .category-box-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 40px;
}

.view-our-category-wrape .category-box {
    display: flex;
    flex-direction: column;
}

.view-our-category-wrape .category-box .img-wrape {
    position: relative;
    display: flex;
}

.view-our-category-wrape .category-box img {
    width: 100%;
}

.view-our-category-wrape .category-box .img-wrape h6 {
    position: absolute;
    bottom: 0;
    left: 0;
    margin: 20px;
    color: #FFFFFF;
    font-size: 30px;
    line-height: 36px;
    font-weight: 500;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.view-our-category-wrape .bottom-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
    padding: 20px;
    border: 1px solid #CACACA;
    border-top: 0;
    height: 100%;
}

.view-our-category-wrape .bottom-info .detail {
    color: #27447C;
    line-height: 30px;
    font-weight: 500;
    max-width: 343px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.view-our-category-wrape .bottom-info .link {
    display: flex;
    min-width: fit-content;
}

.view-our-category-wrape .bottom-info .link img {
    width: 68px;
    height: 68px;
}
@media (max-width: 1249px) {
      .stepper-list-details .stepper-listitem-details {
            margin-right: 70px;
      }

      .stepper-list-details .stepper-listitem-details::after {
            width: calc(100% - -84px);
      }

      .stepper-max-content-details {
            overflow: auto;
            padding-bottom: 10px;
      }

      .stepper-list {
            min-width: 900px;
      }

}

@media (max-width: 1023px) {

      .banner-image .banner-image-content {
            padding: 60px 0;
      }

      .banner-image .banner-image-content .banner-title {
            font-size: 40px;
            line-height: 47px;
      }

      .banner-image .banner-image-content .banner-content {
            font-size: 16px;
            line-height: 20px;
      }

      .coming-soon-wrape {
            height: 50vh;
      }

      .coming-soon-wrape h2,
      .coming-soon-wrape h2 span {
            font-size: 30px;
            line-height: 36px;
      }
}

@media (max-width: 767px) {
      .breadcrumb {
            gap: 5px;
            padding: 10px 0;
      }

      .breadcrumb .breadcrumb-icon {
            width: 14px;
            height: 14px;
      }

      .banner-image .banner-image-content {
            padding: 40px 0;
      }

      .banner-image .banner-image-content .banner-title {
            font-size: 30px;
            line-height: 37px;
      }

      .common-table .common-table-head .common-table-th,
      .common-table .common-table-body .common-table-td {
            padding: 15px;
      }

      .dashboard-bg-main .table-list {
            gap: 0;
      }

      .dashboard-common-header {
            flex-direction: column;
            gap: 15px;
            align-items: flex-start;
            padding: 20px 15px;
      }

      .dashboard-common-header .header-search {
            max-width: 100%;
      }

      .dashboard-bg-main .common-table-body .common-table-td.table-row-action-td {
            padding: 15px;
      }

      .dashboard-common-header .header-search .header-search-input input {
            padding: 5px 10px 5px 36px;
      }

      .dashboard-common-header .header-search .search-icon {
            left: 10px;
            width: 16px;
            height: 16px;
      }

      .common-view-details-group .common-label-title {
            margin-bottom: 5px;
      }

      .blue-fill-btn-main .blue-fill-btn,
      .blue-fill-btn-main .blue-fill-btn:hover,
      .blue-border-btn-main .blue-border-btn,
      .blue-border-btn-main .blue-border-btn:hover,
      .orange-fill-btn-main .orange-fill-btn,
      .orange-fill-btn-main .orange-fill-btn:hover,
      .orange-outline-btn-main .orange-outline-inner,
      .orange-outline-btn-main .orange-outline-inner:hover,
      .orange-outline-btn-main .orange-outline-inner:focus,
      .gray-fill-btn-main .gray-fill-btn,
      .gray-fill-btn-main .gray-fill-btn:hover {
            padding: 7px 14px;
      }

      .comman-form-group .comman-form-label {
            margin-bottom: 5px;
      }

      .coming-soon-wrape {
            height: 40vh;
      }

      .coming-soon-wrape h2,
      .coming-soon-wrape h2 span {
            font-size: 24px;
            line-height: 30px;
            letter-spacing: 10px;
      }
}

@media (max-width: 549px) {

      .upload-main-content .upload-cloud {
            max-width: 80.8px;
            height: 70.2px;
      }

      .comman-modal-inner-title {
            margin-bottom: 30px;
      }

      .comman-modal-inner-title .comman-modal-title {
            font-size: 20px;
            line-height: 26px;
      }

      .modal-common-main .modal-common-style {
            padding: 30px 8px;
      }

      .modal-hgt-scroll {
            padding: 0 8px;
      }

      .payment-upload-title {
            margin-bottom: 10px;
      }

      .payment-upload-title .upload-pay-title {
            font-size: 18px;
            line-height: 22px;
      }
}


@media (max-width: 374px) {

      .upload-img-file-modal .upload-label-details .browse-cloud-text {
            font-size: 16px;
            line-height: 20px;
      }

      .comman-modal-inner-title {
            margin-bottom: 20px;
      }

      .radio-group-main .form-control-label {
            margin-right: 0px;
      }

      .upload-main-content .upload-cloud {
            max-width: 70.8px;
            height: 57.2px;
      }

      .upload-img-file-modal .upload-label-details,
      .upload-img-file-modal .upload-label-details:hover,
      .upload-img-file-modal .upload-label-details:focus {
            padding: 20px 15px;
      }
}
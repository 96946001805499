.modal-common-main .modal-common-style.contact-modal-inner-main {
      max-width: 848px;
}

.contact-modal-inner-main  .contact-modal-header-title {
      color: #151B26;
      font-size: 45px;
      font-weight: 400;
      line-height: 56px;
      margin-bottom: 9px;
}

.contact-content-box {
      margin-bottom: 28px;
}

.contact-content-inner .contact-modal-para {
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      margin-bottom: 32px;
}

.contact-content-flex {
      display: flex;
}

.contact-content-left-main {
      max-width: 166px;
      width: 100%;
      flex: 0 0 166px;
      margin-right: 10px;
}

.contact-content-right-main {
      flex: 1;
}

.contact-submit-btn-main .contact-submit-btn , .contact-submit-btn-main .contact-submit-btn:hover{
      padding: 8px 18px;
      border-radius: 3px;
background: #0D0E10;
color: #FFF;
font-size: 13px;
font-weight: 500;
line-height: 17px;
letter-spacing: 0.5px;
}
.contact-input-main .form-control-textarea{
      width: 100%;
      border: 1px solid #CACACA;
      padding: 11px 10px;
      height: 110px !important;
      resize: none;
      border-radius: 10px;
      color: #808080;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      font-family: "Inter";
      opacity: 0.6;
}
.contact-input-main .form-control-textarea::placeholder{
      color: #808080;
      font-size: 14px;
      line-height: 20px;
      opacity: 1;
}

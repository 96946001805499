.help-wrape {
    padding: 40px 0 60px;
    text-align: center;
    background: radial-gradient(4657.03% 138.98% at 100% 100%, rgba(46, 49, 146, 0.98) 12.19%, rgba(0, 0, 0, 0.00) 100%), #27447C;
    color: #FFFFFF;
}

.help-wrape h5 {
    font-size: 50px;
    line-height: 57px;
    font-weight: 500;
    margin-bottom: 20px;
}

.help-wrape p {
    font-size: 20px;
    line-height: 26px;
    font-weight: 500;
}

.help-wrape p a {
    color: #FFFFFF;
    border-bottom: 1px solid currentColor;
}
.notification-section .notification-title-box {
      padding: 30px 20px;
      margin-bottom: 10px;
      box-shadow: 0px 0px 12px 0px rgba(126, 133, 148, 0.08);
}

.notification-section .notification-title-box .notification-title {
      color: #27447C;
      font-family: "Poppins";
      font-size: 20px;
      font-weight: 500;
      line-height: 24px;
}

.notification-section .notification-content-main {
      display: flex;
      flex-direction: column;
      gap: 16px;
      padding: 20px 20px 30px;
}

.notification-section .notification-content-main .notification-content-box {
      display: flex;
      flex-direction: column;
      gap: 16px;
      padding: 20px;
      border: 1px solid #DEE2E7;
}

.notification-section .notification-content-main .notification-content-box :is(.notification-date, .notification-content) {
      color: #505050;
}

.notification-section .notification-content-main .notification-content-box .notification-status.delivered {
      color: #00B517;
}

.notification-section .notification-content-main .notification-content-box .notification-status.cancelled {
      color: #FF6900;
}

.notification-section .notification-pagination {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      gap: 12px;
      padding: 0 15px 30px;
}

.notification-section .notification-pagination .notification-pagination-btn {
      width: 50px;
      height: 50px;
      min-width: unset;
      border-radius: 50%;
      border: 2px solid #DEE2E7;
      box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.12);
      color: #333;
      font-weight: 600;
}

.notification-section .notification-pagination .notification-pagination-btn.disabled {
      opacity: 0.5;
      pointer-events: none;
}

.notification-section .notification-pagination .notification-pagination-btn.active {
      border-color: #333;
}

.notification-section .notification-pagination .notification-pagination-btn .nav-btn {
      width: 16px;
      height: 16px;
}

.notification-section .notification-pagination .notification-pagination-btn .nav-prev {
      transform: rotate(180deg);
}
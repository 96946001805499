@media (max-width: 767px) {
    .upload-po-details .enter-details-main {
        gap: 20px;
        margin-bottom: 30px;
    }

    .product-preview-modal .product-preview-bottom {
        gap: 20px;
        grid-template-columns: repeat(2, 1fr);
    }

    .product-preview-modal .product-preview-top .product-preview-title {
        max-width: 100%;
        margin-bottom: 10px;
    }

    .product-preview-modal .product-preview-top {
        margin-bottom: 30px;
        flex-direction: column;
    }
}

@media (max-width: 549px) {
    .upload-po-details .enter-details-main {
        flex-direction: column;
    }

    .upload-po-details .enter-details-main .comman-form-group .comman-form-label {
        font-size: 18px;
        line-height: 22px;
    }

    .product-preview-modal .product-preview-top .product-preview-img {
        border-radius: 8px;
    }
    
    .product-preview-modal .product-preview-bottom .product-preview-detail.common-view-details-group:last-child {
        grid-column: span 2;
    }
}

@media (max-width: 374px) {
    .product-preview-modal .product-preview-top .product-preview-img {
        width: auto;
        height: auto;
    }
}
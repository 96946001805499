.latest-posts-all {
    padding: 50px 0;
}

.latest-posts-all .latest-posts-title {
    font-size: 40px;
    line-height: 47px;
    font-weight: 600;
    margin-bottom: 20px;
}

.latest-posts-all .latest-posts-content {
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 50px;
}

.latest-posts-all .all-posts-box {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 50px;
    margin-bottom: 50px;
}

.latest-posts-all .all-posts-box .all-posts-item {
    position: relative;
    box-shadow: 0px 0px 12px -3px rgba(0, 0, 0, 0.24);
    border-radius: 8px;
}

.latest-posts-all .all-posts-box .all-posts-item::after {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 8px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.76) 0%, rgba(0, 0, 0, 0.00) 100%);
}

.latest-posts-all .all-posts-box .all-posts-item .blog-image {
    border-radius: 8px;
    width: 100%;
    height: 100%;
}

.latest-posts-all .all-posts-box .all-posts-item .all-posts-content {
    position: absolute;
    margin: 20px;
    bottom: 0;
    left: 0;
    font-size: 24px;
    line-height: 30px;
    font-weight: 600;
    color: #fff;
    z-index: 1;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.latest-posts-all .pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 40px;
}

.latest-posts-all .pagination .pagination-number {
    font-size: 24px;
    line-height: 30px;
    font-weight: 600;
}

.latest-posts-all .pagination .pagination-number .pagination-active {
    color: #27447C;
}

.latest-posts-all .pagination .pagination-btn {
    min-width: unset;
    border-radius: 0;
    padding: 0;
}

.latest-posts-all .pagination .pagination-btn .pagination-left {
    transform: rotate(180deg);
}
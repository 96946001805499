@media (max-width: 1023px) {
    .user-interface-wrape {
        margin-bottom: 60px;
    }

    .user-interface-wrape .user-interface-title {
        font-size: 40px;
        line-height: 47px;
        margin-bottom: 30px;
        max-width: 100%;
    }

    .user-interface-wrape .user-interface-box-wrapper {
        grid-template-columns: repeat(2, 1fr);
        gap: 30px;
    }

    .user-interface-wrape .user-interface-box .user-interface-img {
        margin-bottom: 10px;
    }
}

@media (max-width: 767px) {
    .user-interface-wrape {
        margin-bottom: 40px;
    }

    .user-interface-wrape .user-interface-title {
        font-size: 30px;
        line-height: 37px;
        margin-bottom: 20px;
    }

    .user-interface-wrape .user-interface-box-wrapper {
        grid-template-columns: repeat(1, 1fr);
        gap: 30px;
    }

    .user-interface-wrape .bottom-info .detail {
        font-size: 16px;
        line-height: 20px;
    }

    .user-interface-wrape .user-interface-box .user-interface-img {
        margin-bottom: 5px;
    }

    .user-interface-wrape .bottom-info .link .arrow {
        width: 16px;
        height: 16px;
    }
}

@media (max-width: 549px) {
    .user-interface-wrape .user-interface-title {
        font-size: 24px;
        line-height: 30px;
    }
}
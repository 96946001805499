@media (max-width: 767px) {
    .my-acc-content-main {
        padding: 60px 15px;
    }

    .my-verify-btn-main {
        margin-top: 30px;
    }

    .my-acc-content-main .my-acc-content-para {
        font-size: 16px;
        line-height: 21px;
    }
    .orange-fill-btn-main .veryfy-acc-btn{
        min-width: unset;
    }
}
@media (max-width: 1249px) {}

@media (max-width: 1023px) {}

@media (max-width: 767px) {}

@media (max-width: 549px) {
    .track-order-flex-justify {
        flex-direction: column;
        align-items: start;
    }
    .estimated-time-details {
        text-align: start;
    }

    .order-id-details {
        margin-bottom: 10px;
    }
    .flex-odrer-show{
        flex-direction: column;
    }
    .order-img-show-conteny .order-cart-show {
        width: 200px;
        height: 140px;
    }
    .order-img-show-conteny{
        margin: 0px 0px 10px 0px;
    }
}

@media (max-width: 374px) {}
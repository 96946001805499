.blog-wrape {
    padding: 40px 0;
    margin-bottom: 90px;
    position: relative;
}

.blog-wrape::before,
.blog-wrape::after {
    position: absolute;
    height: 100%;
    top: 0;
    content: "";
    z-index: -1;
}

.blog-wrape::before {
    width: 45%;
    background: #EE682B;
    left: 0;
}

.blog-wrape::after {
    width: 55%;
    background: #F5F5F5;
    right: 0;
}

.blog-wrape .top-content {
    display: flex;
    justify-content: space-between;
    gap: 80px;
    margin-bottom: 40px;
}

.blog-wrape .top-content .left-wrape {
    width: 45%;
    color: #ffffff;
    max-width: 445px;
}

.blog-wrape .top-content .right-wrape {
    width: 55%;
    color: #333333;
    max-width: 490px;
}

.blog-wrape .top-content .left-wrape h5 {
    font-size: 50px;
    line-height: 60px;
    font-weight: 600;
    margin-bottom: 15px;
}

.blog-wrape .top-content .left-wrape p {
    font-size: 20px;
    line-height: 26px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.blog-wrape .top-content .right-wrape .white-box {
    border-left: 8px solid #EE682B;
    padding-left: 40px;
}

.blog-wrape .top-content .right-wrape .white-box h5 {
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 15px;
    font-weight: 700;
}

.blog-wrape .top-content .right-wrape .white-box p {
    font-size: 20px;
    line-height: 26px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.blog-wrape .crousel-wrape {
    width: 100%;
    max-width: 712px;
    margin: 0 auto;
    position: relative;
}

.blog-wrape .box-grid .img-wrape {
    display: flex;
}

.blog-wrape .box-grid img {
    width: 100%;
}

.blog-wrape .bottom-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
    border: 1px solid #CACACA;
    border-top: 0;
    background: #ffffff;
    padding: 0 20px;
}

.blog-wrape .bottom-info .detail {
    font-size: 20px;
    line-height: 26px;
    font-weight: 500;
    color: #27447C;
    margin: 10px 0;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.blog-wrape .bottom-info .link {
    display: flex;
    min-width: fit-content;
}

.blog-wrape .bottom-info .link img {
    width: 68px;
    height: 68px;
}

.blog-wrape .slide-controller {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
}

.blog-wrape .slide-controller .control {
    display: flex;
    gap: 40px;
}

.blog-wrape .slide-controller .control .cus-swiper-button-prev,
.blog-wrape .slide-controller .control .cus-swiper-button-next {
    display: flex;
    min-width: fit-content;
    cursor: pointer;
}

.blog-wrape .slide-controller .control img {
    width: 40px;
    height: 40px;
}

.blog-wrape .my-custom {
    color: #ffffff;
    font-size: 24px;
    line-height: 30px;
    font-weight: 700;
    display: flex;
    width: auto;
}
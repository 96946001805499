.main-faq {
    background-color: #fafafa;
}

.main-faq .main-faq-wrapper {
    padding: 40px 0;
    display: flex;
    align-items: flex-start;
    gap: 30px;
}

.main-faq .main-faq-wrapper .sidebar-tab-desktop {
    width: 336px;
    border-radius: 8px;
    background: #FFF;
    box-shadow: 0px 0px 12px 0px rgba(126, 133, 148, 0.08);
    padding: 10px;
    position: sticky;
    top: 100px;
    overflow: hidden;
}

.main-faq .main-faq-wrapper .sidebar-tab-desktop .common-tab {
    overflow: auto;
    max-height: calc(100vh - 152px);
    padding-right: 5px;
    margin-right: -5px;
}

.main-faq .main-faq-wrapper .sidebar-tab-desktop .MuiTabs-indicator {
    display: none;
}

.main-faq .main-faq-wrapper .sidebar-tab-desktop .MuiTabs-flexContainer {
    border-radius: 8px;
    border: 1px solid #EBEBEB;
    background: #FFF;
    margin-bottom: 20px;
}

.main-faq .main-faq-wrapper .sidebar-tab-desktop .sidebar-tab-button{
    min-height: unset;
}

.main-faq .main-faq-wrapper .sidebar-tab-desktop .sidebar-tab-button button {
    min-width: unset;
    max-width: unset;
    min-height: unset;
    width: 50%;
    padding: 15px 30px;
    border-radius: 8px;
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    color: #27447C;
    text-transform: capitalize;
}

.main-faq .main-faq-wrapper .sidebar-tab-desktop .sidebar-tab-button button.Mui-selected {
    background-color: #27447C;
    color: #fff;
}

.main-faq .main-faq-wrapper .tab-button {
    display: block;
    padding: 15px 10px;
    color: #333333;
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    text-align: left;
    text-transform: capitalize;
    font-size: 16px;
    line-height: 20px;
}

.main-faq .main-faq-wrapper .tab-button:not(:last-child) {
    border-bottom: 1px solid #EBEBEB;
}

.main-faq .main-faq-wrapper .question-section {
    flex: 1;
}

.main-faq .main-faq-wrapper .question-section .faq-search-box {
    position: relative;
}

.main-faq .main-faq-wrapper .question-section .faq-search {
    width: 100%;
    border-radius: 8px;
    border: 1px solid #EBEBEB;
    background: #FFF;
}

.main-faq .main-faq-wrapper .question-section .faq-search::before,
.main-faq .main-faq-wrapper .question-section .faq-search::after {
    display: none;
}

.main-faq .main-faq-wrapper .question-section .faq-search input {
    padding: 16px 16px 16px 60px;
    font-weight: 500;
    color: #808080;
}

.main-faq .main-faq-wrapper .question-section .faq-search-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 16px;
    width: 34px;
    height: 34px;
}

.main-faq .main-faq-wrapper .question-section .topic-item-wrapper {
    margin-top: 20px;
}

.main-faq .main-faq-wrapper .question-section .MuiAccordionSummary-root {
    background-color: #f5f5f5;
    min-height: unset;
    gap: 10px;
}

.main-faq .main-faq-wrapper .question-section .MuiAccordion-root {
    border-radius: 8px;
    overflow: hidden;
}

.main-faq .main-faq-wrapper .question-section .MuiAccordion-root:not(:last-child) {
    margin-bottom: 20px;
}

.main-faq .main-faq-wrapper .question-section .MuiAccordionSummary-root.Mui-expanded {
    min-height: unset;
}

.main-faq .main-faq-wrapper .question-section .MuiAccordionSummary-content,
.main-faq .main-faq-wrapper .question-section .MuiAccordionSummary-content.Mui-expanded {
    margin: 15px 0;
}

.main-faq .main-faq-wrapper .question-section .MuiAccordionDetails-root {
    padding: 16px;
}

.main-faq .main-faq-wrapper .question-section .topic-question {
    font-weight: 500;
}

.main-faq .main-faq-wrapper .question-section .topic-answer {
    line-height: 26px;
}

.main-faq .main-faq-wrapper .topic-item .topic-item-title-box {
    position: relative;
    text-align: center;
    margin: 30px 0;
}

.main-faq .main-faq-wrapper .topic-item .topic-item-title {
    color: #808080;
    font-weight: 500;
    padding: 0 16px;
    background-color: #fafafa;
    display: inline-block;
    position: relative;
}

.main-faq .main-faq-wrapper .topic-item .topic-item-title-box::before {
    position: absolute;
    content: "";
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #ebebeb;
}
.states-wrape {
    margin-bottom: 60px;
}

.states-wrape .inner {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
}

.states-wrape .state {
    text-align: center;
    padding: 75px 15px;
    color: #FFFFFF;
    background: #27457C;
}

.states-wrape .state-title {
    font-size: 100px;
    line-height: 1;
    font-family: 'Poppins';
    font-weight: 700;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.states-wrape .state-title span{
    font-size: 80px;
    line-height: 1;
    font-family: 'Poppins';
    font-weight: 400;
}

.states-wrape .state-sub-title {
    font-size: 22px;
    line-height: 26px;
}

.states-wrape .state:nth-child(even) {
    background: #0B80C4;
}

.states-wrape .state:nth-child(odd) {
    background: #27457C;
}

.my-acc-content-main {
      padding: 100px 20px;
}

.my-acc-content-section {
      height: 100%;
}

.my-acc-content-main .my-acc-content-para {
      text-align: center;
      font-size: 20px;
      line-height: 26px;
      max-width: 500px;
}

.my-acc-content-row {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
}

.orange-fill-btn-main .veryfy-acc-btn {
      min-width: 267px;
}

.my-verify-btn-main {
      margin-top: 39px;
      display: flex;
      justify-content: center;
}
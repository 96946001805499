.my-cart-bg-main {
    background-color: #F5F5F5;
}

.mycart-details-content {
    padding: 24px 0px 40px 0px;
}

.mycart-col2 {
    /* max-width: 440px; */ 
    /* flex: 0 0 440px; */
    width: 34.646%;
    padding: 0px 10px;
}

.mycart-col1 {
    width: 65.354%;
    padding: 0px 10px;
}

.summary-details-show {
    border-radius: 8px;
    background: #FFF;
    padding: 23px 22px 16px 22px;
}

.mycart-row-details {
    display: flex;
    flex-wrap: wrap;
    margin: 0px -10px;
}

.summary-details-show {
    border-radius: 8px;
    background: #FFF;
    padding: 23px 22px 16px 22px;
}

.summary-card-details .summary-title {
    color: #000;
    font-size: 20px;
    font-style: normal;
    margin-bottom: 15px;
    font-family: 'Poppins';
    font-weight: 500;
    line-height: 26px;
}

.summary-details {
    padding: 15px 0px 30px 0px;
}

.summary-brand-details {
    border-radius: 8px;
    background: #EBEBEB;
    padding: 14.5px 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.summary-brand-details .brand-select-name {
    color: #333;
    font-family: "Poppins";
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
}

.summary-brand-details .brand-select-value {
    color: #333;
    font-family: "Poppins";
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
}

.terms-title .payment-terms-titles {
    color: #000;
    font-family: "Poppins";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 10px;
}

.send-enquiry-main-btn {
    margin-top: 50px;
}

.common-buyer-title .common-title-inner {
    color: #000;
    font-family: Inter;
    font-size: 20px;
    font-weight: 600;
}

.common-buyer-title {
    margin-bottom: 30px;
}

.item-show-content {
    border-radius: 8px;
    background: #FFF;
}

.show-item-content {
    padding: 11px 18px;
    border-bottom: 1px solid #EBEBEB;
}

.show-item-content .show-item-details {
    color: #000;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
}

.show-purchase-item {
    padding: 0 15px 30px;
}


.listing-title-details {
    display: flex;
    border-bottom: 1px solid #EBEBEB;
}

.listing-title-details .title-cart-col1 {
    width: 50%;
}

.listing-title-details .title-cart-col2 {
    width: 25%;
}

.listing-title-details .title-cart-col3 {
    width: 20%;
}

.listing-title-details .title-cart-col4 {
    width: 12%;
}

.common-cart-details .title-cart-listing {
    padding: 11px 0px;
    color: #000;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
}

.listing-cart-details-show .listing-title-details .common-cart-details {
    margin: 11px 0px;
}

.item-details-cart .cart-img-show {
    display: flex;
    border-radius: 4px;
    max-width: 120px;
    height: 84px;
    object-fit: cover;
    margin-right: 10px;
}

.item-details-cart {
    display: flex;
    align-items: center;
}

.description-details-cart .description-details {
    color: #000;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    max-width: 190px;
    margin-bottom: 13px;
}

.description-details-cart .brand-details-content {
    color: #808080;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
}


.listing-requirement .list-main-requirement {
    padding: 0px 0px 0px 10px;
}

.list-main-requirement .listitem-requiremnt {
    padding: 0px;
    font-size: 12px;
    font-weight: 400;
    color: #808080;
    line-height: 16px;
    margin-bottom: 10px;
}

.list-style-span {
    width: 4px;
    height: 4px;
    background: #808080;
    border-radius: 50%;
    margin-right: 10px;
}

.qty-check-details {
    display: flex;
}

.qty-check-details .qty-btn-main, .qty-check-details .qty-btn-main:hover, .qty-check-details .qty-btn-main:active {
    min-width: inherit;
    max-width: 19.206px;
    height: 19.206px;
    background: #F1F8FF;
    border: 1px solid #27447C;
    border-radius: 2.33px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
}

.qty-input-details input, .qty-input-details input:focus, .qty-input-details input:hover, .qty-input-details .Mui-focused{
    border-radius: 2.333px;
    border: 0.583px solid #EBEBEB;
    background: #FFF;
    text-align: center;
    max-width: 41px;
    height: 18px;
    padding: 0px 2px;
    color: #000;
    font-size: 9.333px;
    font-style: normal;
    font-weight: 500;
    line-height: 10.5px;
    font-family: 'Poppins';
}

.qty-check-details .qty-input-details {
    margin: 0px 6px;
}

.qty-input-details fieldset {
    border-color: transparent !important;
}

.qty-input-details:before,.qty-input-details:after {
    display: none;
}

.qty-input-main {
    display: flex;
}


.action-cart {
    display: flex;
    flex-direction: column;
}

.action-cart .common-action-cart {
    font-family: 'Poppins';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    margin-bottom: 10px;
    text-align: center;
}

.primary-edit-color {
    color: #27447C;
}

.danger-edit-color {
    color: #EB5757;
}

.listing-cart-details-show .listing-title-details .common-cart-details:last-child {
    border-left: 1px dashed #CACACA;
    padding: 0px 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.send-enquiry-main-btn .send-enquiry-btn{
    width: 100%;
}
.seller-analytics-main {
    padding: 50px 50px 10px;
}

.seller-analytics-main .analytics-item-box {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 50px;
    row-gap: 30px;
}

.seller-analytics-main .analytics-item-box .analytics-item {
    padding: 20px;
    border-radius: 10px;
    border: 1px solid #CACACA;
}

.seller-analytics-main .analytics-item-box .analytics-item-label {
    color: #717171;
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
    margin-bottom: 15px;
}

.seller-analytics-main .analytics-item-box .analytics-item-value {
    color: #333;
    font-size: 34px;
    font-weight: 600;
    line-height: 40px;
}
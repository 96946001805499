.testimonial-wrape {
    margin-bottom: 90px;
}

.testimonial-wrape h4 {
    font-size: 50px;
    line-height: 57px;
    font-weight: 600;
    color: #27447C;
    margin-bottom: 50px;
}

.testimonial-wrape .crousel-wrape {
    padding: 60px 50px;
    border-radius: 12px;
    background: #27447C;
}

.testimonial-wrape .crousel-wrape .swiper-button-prevs {
    position: absolute;
    left: 0;
    display: flex;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 1;
}

.testimonial-wrape .crousel-wrape .swiper-button-nexts {
    position: absolute;
    right: 0;
    display: flex;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 1;
}

.testimonial-wrape .swiper-slide {
    display: flex;
    padding: 0 40px;
    align-items: center;
    color: #FFFFFF;
}

.testimonial-wrape .left-wrape {
    display: flex;
    width: 40%;
}

.testimonial-wrape .right-wrape {
    width: 60%;
    padding-left: 90px;
}

.testimonial-wrape .left-wrape .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 16px;
}

.testimonial-wrape .left-wrape-img {
    min-width: 149px;
    min-height: 149px;
    max-width: 149px;
    max-height: 149px;
}

.testimonial-wrape .left-wrape img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.testimonial-wrape .left-wrape h6 {
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 6px;
}

.testimonial-wrape .left-wrape span {
    font-size: 14px;
    line-height: 18px;
}

.testimonial-wrape p {
    font-size: 20px;
    line-height: 26px;
    font-weight: 500;
}
@media (max-width: 1023px) {
    .recent-blogs .recent-blogs-wrapper {
        flex-direction: column;
    }

    .recent-blogs .recent-blogs-wrapper .recent-blogs-item,
    .recent-blogs .recent-blogs-wrapper .recent-blogs-list {
        width: 100%;
    }

    .recent-blogs .recent-blogs-wrapper .blogs-list-item-box .blogs-list-item {
        align-items: flex-start;
        gap: 20px;
    }

    .recent-blogs .recent-blogs-wrapper .blogs-list-item .blogs-list-item-title {
        margin-bottom: 10px;
    }
}

@media (max-width: 767px) {
    .recent-blogs .blogs-title {
        font-size: 30px;
        line-height: 36px;
        margin-bottom: 20px;
        margin-top: 0;
    }
    .recent-blogs .recent-blogs-wrapper .recent-blogs-item .blog-image {
        margin-bottom: 10px;
    }
    .recent-blogs .recent-blogs-wrapper .recent-blogs-item .blogs-item-title {
        font-size: 20px;
        line-height: 26px;
        margin-bottom: 10px;
    }
    .recent-blogs .recent-blogs-wrapper .recent-blogs-item .blogs-item-content {
        font-size: 16px;
        line-height: 20px;
    }
    .recent-blogs .recent-blogs-wrapper .recent-blogs-list .blogs-list-title {
        font-size: 24px;
        line-height: 30px;
        margin-bottom: 20px;
        gap: 5px;
    }
    .recent-blogs .recent-blogs-wrapper .recent-blogs-list .blogs-list-icon {
        width: 30px;
        height: 30px;
    }
    .recent-blogs .recent-blogs-wrapper .blogs-list-item-box .blogs-list-item {
        gap: 15px;
    }
    .recent-blogs .recent-blogs-wrapper .blogs-list-item-box .blogs-list-item:not(:last-child) {
        margin-bottom: 20px;
    }
    .recent-blogs .recent-blogs-wrapper .blogs-list-item .blog-image {
        width: 100px;
        height: 100px;
    }
    .recent-blogs .recent-blogs-wrapper {
        display: flex;
        gap: 30px;
        padding-bottom: 30px;
    }
}
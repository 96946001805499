.modal-review-btn-main {
      display: flex;
      justify-content: center;
      align-items: center;
}

.orange-fill-btn-main .review-modal-btn {
      margin-right: 6px;
}

.review-discription-main .form-group{
      display: flex;
      align-items: center;
}

.review-discription-main .form-control-textarea {
      width: 100%;
      border: 1px solid #CACACA;
      padding: 19px 24px;
      resize: none;
      max-width: 461px;
      margin: 0 auto;
      border-radius: 10px;
      color: #808080;
      font-family: "Inter";
      font-size: 14px;
      font-weight: 500;
      line-height: 18px;
}

.review-discription-main {
      margin: 0px auto 25px;
}

.review-modal-content-main .order-img-show-conteny .order-cart-show{
      width: 181px;
      height: 115px;
}

.review-star-main {
      display: flex;
      justify-content: center;
      margin-bottom: 25px;
}
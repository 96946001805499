.main-catalogue .catalogue-title {
    margin: 40px 0;
    color: #27447C;
    font-size: 34px;
    line-height: 40px;
}

.main-catalogue .catalogue-wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
    margin-bottom: 90px;
}
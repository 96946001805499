@media (max-width: 1249px) {}

@media (max-width: 1023px) {
      .my-account-flex-main {
            flex-direction: column;
            gap: 30px;
      }

      .my-account-left-main {
            max-width: 100%;
            width: 100%;
            flex: 0 0 100%;
            border-right: 0px;
      }
}

@media (max-width: 767px) {

      .user-img-main {
            width: 70px;
            height: 70px;
            margin-right: 15px;
      }

      .user-verify-bedge-main {
            top: -12px;
      }

      .user-img-main .user-name-small {
            font-size: 24px;
            line-height: 1;
      }

      .account-prfile-main {
            margin: 0;
            padding: 30px 15px;
      }

      .user-profile-flex {
            padding: 0;
            margin-bottom: 20px;
      }

      .account-veryfy-btn-main {
            margin: 0;
      }

      .user-content-box .user-name-text {
            font-size: 18px;
            line-height: 22px;
      }

      .user-content-box .user-number-text {
            font-size: 16px;
            line-height: 20px;
      }
}

@media (max-width: 549px) {
}

@media (max-width: 374px) {}
.order-product-details .cart-order-details {
    color: #000;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    max-width: 169px;
}


/* .myorder-table thead tr th:nth-child(1),
.myorder-table tbody tr td:nth-child(1) {
    width: 11%;
}

.myorder-table thead tr th:nth-child(2),
.myorder-table tbody tr td:nth-child(2) {
    width: 22%;
}

.myorder-table thead tr th:nth-child(3),
.myorder-table tbody tr td:nth-child(3) {
    width: 15%;
}

.myorder-table thead tr th:nth-child(4),
.myorder-table tbody tr td:nth-child(4) {
    width: 18%;
}

.myorder-table thead tr th:nth-child(5),
.myorder-table tbody tr td:nth-child(5) {
    width: 14%;
}

.myorder-table thead tr th:nth-child(6),
.myorder-table tbody tr td:nth-child(6) {
    width: 10%;
}

.myorder-table thead tr th:nth-child(7),
.myorder-table tbody tr td:nth-child(7) {
    width: 12%;
} */

.odrer-id-hash {
    color: #333;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
}

.order-product-details .cart-order-img {
    max-width: 91.429px;
    height: 64px;
    object-fit: cover;
    display: flex;
    margin-bottom: 8px;
}


.proof-btn-order {
    border-radius: 6px;
    border: 1px solid var(--stroke, #EBEBEB);
}

.proof-btn-order .proof-advance {
    padding: 4px 25px;
    color: #000;
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
}

.proof-btn-order .upload-file-modal,
.proof-btn-order .upload-file-modal:hover,
.proof-btn-order .upload-file-modal:focus {
    border-radius: 0px 0px 5px 5px;
    background: #D8D8D8;
    box-shadow: none;
    color: #5E5E5E;
    font-family: Inter;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    text-transform: capitalize;
    width: 100%;
    padding: 4px 6px;
}

.upload-file-modal .upload-icon {
    height: 20px;
    width: 20px;
    object-fit: contain;
    margin-right: 2px;
}

.confirmed-order {
    color: #808080;
    text-align: center;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.view-po-order {
    color: #0F5288;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    border-bottom: 1px solid #0F5288;
}

.select-payment-main .select-method-title {
    color: #333;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
    margin-bottom: 20px;
}

.radio-group-main .form-control-label {
    margin-left: 0px;
    margin-right: 40px;
}

.radio-group-main .form-control-label .MuiRadio-root {
    padding: 0px;
}

.radio-group-main .form-control-label .MuiRadio-root svg{
    width: 19px;
    height: 19px;
}

.radio-group-main .form-control-label .MuiFormControlLabel-label {
    margin-left: 5px;
    font-size: 14px;
    line-height: 20px;
}

.select-method-list {
    padding-bottom: 30px;
    border-bottom: 1px solid #EAEAEA;
    margin-bottom: 30px;
}

.utr-content-details {
    max-width: 291px;
}

.btnupload-payment-footer {
    display: flex;
    align-items: center;
    margin-top: 40px;
    justify-content: center;
}

.btnupload-payment-footer .btn-comman-mrl {
    margin: 0px 6px;
}

.upload-document-show {
    border-radius: 8px;
    background: #F1F8FF;
    padding: 10px 15px;
    margin-bottom: 10px;
}

.upload-mt-details {
    margin-top: 16px;
}

.upload-document-show .upload-mt-details:last-child {
    margin-bottom: 0px;
}

.upload-document-card {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.file-icon-name {
    display: flex;
    align-items: center;
}

.description-upload {
    max-width: 21.649px;
    height: 24px;
    object-fit: contain;
    margin-right: 15px;
}

.file-icon-name .file-payment-name {
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.upload-document-card {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.btn-delete-file,
.btn-delete-file:hover,
.btn-delete-file:focus {
    min-width: unset !important;
    background: transparent;
}

.order-yellow-text {
    color: #E9A657;
}

.order-green-text {
    color: #3BB77E;
}

.track-order-link {
    border-bottom: 1px solid #0B80C4;
    color: #0B80C4;
    max-width: max-content;
    margin: auto;
}

/* .myorder-table {
    min-width: 1020px;
    cursor: grab;
}

.myorder-table-container {
    overflow: auto;
    max-width: calc(100vw - 455px);
} */

.download-details {
    height: 22px;
    max-width: 22px;
    object-fit: contain;
}

.po-number-listing {
    margin-bottom: 10px;
}

.product-review-grid-6 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    margin-top: 40px;
}


.invoice-details-po {
    display: flex;
    align-items: start;
    gap: 12px;
    margin-bottom: 12px;
}

.download-invoice-list .download-invoice-content {
    color: #27447C;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
}

.download-invoice-list .invoice-btn-down {
    padding: 0;
    min-width: unset;
}

.invoice-btn-down {
    min-width: inherit;
    padding: 0px;
}

.download-invoice-list.MuiBox-root.css-0 {
    display: flex;
    align-items: center;
    gap: 8px;
}

.download-invoice-list .download-invoice-content iimg {
    width: 20px;
    height: 20px;
    object-fit: contain;
}

.delete-details {
    max-width: 24px;
    height: 24px;
}

.ratinig-track-content .rating-main svg {
    height: 20px;
    width: 20px;
}

.rating-track-order-list {
    display: flex;
    align-items: center;
    margin-top: 10px;
    gap: 10px;
}

.rat-box-edit .edit-rate-icon {
    min-width: 20px;
    padding: 0px;
}
@media (max-width: 1023px) {
    .seller-analytics-main {
        padding: 30px 30px 0;
    }

    .seller-analytics-main .analytics-item-box {
        gap: 30px;
    }
}

@media (max-width: 767px) {
    .seller-analytics-main .analytics-item-box {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 549px) {
    .seller-analytics-main .analytics-item-box {
        grid-template-columns: repeat(1, 1fr);
    }

    .seller-analytics-main {
        padding: 30px 15px 0;
    }

    .seller-analytics-main .analytics-item-box .analytics-item-value {
        font-size: 24px;
        line-height: 30px;
    }
}
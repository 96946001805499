@media (max-width: 1249px) {
    .mycart-row-details {
        flex-direction: column;
    }
    .mycart-col1, .mycart-col2{
        width: 100%;
    }
    .item-show-content{
        margin-bottom: 30px;
    }
}

@media (max-width: 1023px) {
    .listing-cart-details {
        min-width: 800px;
    }
    .show-purchase-item {
        overflow-x: auto;
    }
}

@media (max-width: 767px) {}

@media (max-width: 549px) {}

@media (max-width: 374px) {}
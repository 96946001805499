@media (max-width: 1249px) {
      .product-col-right {
            flex: 0 0 40%;
            max-width: 40%;
      }

      .product-brand-main .product-brand-ul {
            grid-template-columns: repeat(2, 1fr);
      }

      .product-drop-ul .prodcut-drop-li {
            width: calc(100% / 3);
      }
}

@media (max-width: 1023px) {
      .product-listing-row {
            flex-direction: column;
      }

      .product-col-right {
            flex: 0 0 100%;
            max-width: 100%;
            margin-left: 0px;
      }

      .page-title-main .page-title {
            font-size: 24px;
            line-height: 30px;
      }

      .main-you-may-like {
            margin-top: 60px;
      }

      .product-brand-main {
            margin: 30px 0;
      }

      .product-added-card {
            margin: 0 0px 30px;
      }

}

@media (max-width: 767px) {
      .product-drop-ul .prodcut-drop-li {
            width: calc(100% / 2);
      }

      .product-brand-main .product-brand-ul {
            grid-template-columns: repeat(1, 1fr);
      }

      .product-listing-row {
            flex-direction: column;
      }

      .product-col-right {
            flex: 0 0 100%;
            max-width: 100%;
            margin-left: 0px;
      }

      .you-may-like-content-main .you-may-like-ul {
            grid-template-columns: repeat(1, 1fr);
            gap: 20px;
      }

      .page-title-main .page-title {
            font-size: 20px;
            line-height: 24px;
            margin-bottom: 20px;
      }

      .payment-tem-drop-main {
            margin-bottom: 20px;
      }

      .blue-fill-btn-main .veryfy-modal-btn {
            min-width: 100%;
            padding: 18px 20px;
      }

      .goto-veryfication-modal-inner-main .veryfy-modal-para {
            max-width: 100%;
      }

      .main-you-may-like {
            padding: 30px 0px;
            margin-top: 30px;
      }

      .upload-blue-main .upload-blue-btn,
      .upload-blue-main .upload-blue-btn:hover {
            padding: 7px 14px;
      }

      .product-added-card,
      .product-bulk-card {
            padding: 15px;
      }
}

@media (max-width: 549px) {
      .product-drop-ul .prodcut-drop-li {
            width: calc(100% / 1);
      }

      .choose-file-right-main .choose-browse-text,
      .choose-file-left-main .choose-file-text {
            font-size: 14px;
            line-height: 18px;
            padding: 12px 15px;
      }
}
.seller-ra-main .blue-fill-btn-main .blue-fill-btn.bid-btn {
    padding: 8px 16px;
}

.place-bid-modal.modal-common-main .modal-common-style {
    max-width: 870px;
}

.place-bid-modal .place-bid-modal-wrapper {
    display: flex;
    gap: 16px;
}

.place-bid-modal .place-bid-modal-wrapper .bid-left {
    width: calc(43% - 8px);
}

.place-bid-modal .place-bid-modal-wrapper .bid-right {
    width: calc(57% - 8px);
}

.place-bid-modal .place-bid-modal-wrapper .bid-left .product-img-box {
    display: flex;
    margin-bottom: 20px;
}

.place-bid-modal .place-bid-modal-wrapper .product-img {
    width: 100%;
    border-radius: 10px;
}

.place-bid-modal .lowest-bid {
    margin-bottom: 16px;
}

.place-bid-modal .lowest-bid .lowest-bid-label {
    color: #808080;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 10px;
}

.place-bid-modal .lowest-bid .lowest-bid-value {
    font-size: 21px;
    font-weight: 500;
    line-height: 27px;
}

.place-bid-modal .lowest-bid-low {
    padding: 15px;
    border-radius: 6px;
    background-color: #F0F1F5;
    display: flex;
    align-items: center;
    gap: 15px;
    margin-bottom: 12px;
    max-width: 311px;
}

.place-bid-modal .lowest-bid-low .lowest-bid-low-value {
    color: #AEAEAE;
    font-size: 12px;
    line-height: 16px;
}

.place-bid-modal .bid-minus-btn-box {
    display: flex;
    gap: 6px;
    margin-bottom: 30px;
}

.place-bid-modal .bid-minus-btn-box .bid-minus-value {
    padding: 4px 8px;
    font-size: 14px;
    line-height: 18px;
    border: 1px solid #cacaca;
}

.place-bid-modal .bid-minus-btn-box .bid-minus-btn {
    padding: 1px 7px;
    border: 1px solid #CACACA;
    background: #000;
    border-radius: 0;
    min-width: unset;
}

.place-bid-modal .bid-minus-btn-box .bid-minus-btn .cust-dropdown-arrow-icon {
    filter: invert(1);
}

.place-bid-modal .bid-place-btn-box {
    display: flex;
    gap: 10px;
    max-width: 311px;
}

.place-bid-modal .bid-place-btn-box .blue-border-btn-main,
.place-bid-modal .bid-place-btn-box .blue-fill-btn-main,
.place-bid-modal .bid-place-btn-box .blue-border-btn-main .blue-border-btn,
.place-bid-modal .bid-place-btn-box .blue-fill-btn-main .blue-fill-btn {
    width: 100%;
}

.place-bid-modal .product-img-box-mobile {
    display: none;
}

.place-bid-modal .product-title-live-box {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 10px;
    margin-bottom: 20px;
}

.place-bid-modal .product-title-live-box .product-title {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 10px;
}

.place-bid-modal .product-title-live-box .product-label {
    color: #bebebe;
    font-size: 14px;
    line-height: 18px;
}

.place-bid-modal .product-title-live-box .product-live-box {
    display: flex;
    align-items: center;
    gap: 5px;
    background-color: #ebebeb;
    border-radius: 7px;
    padding: 6px 12px;
}

.place-bid-modal .product-title-live-box .product-live-light {
    min-width: 8px;
    min-height: 8px;
    max-width: 8px;
    max-height: 8px;
    border-radius: 50%;
    background-color: #EB5757;
}

.place-bid-modal .product-title-live-box .product-live-text {
    font-size: 12px;
    line-height: 18px;
    font-weight: 500;
}

.place-bid-modal .product-bid-details {
    background-color: #ebebeb;
    border-radius: 8px;
    padding: 8px 16px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 16px;
    color: #808080;
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 30px;
}

.place-bid-modal .product-bid-details .product-details-value {
    font-weight: 600;
    color: #000;
    margin-top: 2px;
}

.place-bid-modal .product-bid-details .product-details-value.time-left {
    color: #EB5757;
}

.place-bid-modal .product-order-details .product-order-details-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
    padding-bottom: 12px;
}

.place-bid-modal .product-order-details .product-order-details-item:not(:last-child) {
    border-bottom: 1px solid #cacaca;
    margin-bottom: 12px;
}

.place-bid-modal .product-order-details .product-order-label {
    color: #808080;
    font-size: 12px;
    line-height: 16px;
}

.place-bid-modal .product-order-details .product-order-value {
    font-size: 14px;
    line-height: 18px;
    font-weight: 600;
}

.bid-success-modal.modal-common-main .modal-common-style {
    max-width: 632px;
}

.bid-success-modal .bid-success-content {
    margin: 40px 0;
    text-align: center;
    font-size: 20px;
    line-height: 26px;
}

.bid-success-modal .blue-fill-btn-main {
    text-align: center;
}

.bid-success-modal .blue-fill-btn-main .blue-fill-btn.bid-success-btn {
    min-width: 157px;
}

.bid-secured-modal.modal-common-main .modal-common-style {
    max-width: 632px;
}

.bid-secured-modal .thumbs-up-header {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
}

.bid-secured-modal .thumbs-up-header.success {
    background-color: #3BB77E;
}

.bid-secured-modal .thumbs-up-header.lost {
    background-color: #EB5757;
}

.bid-secured-modal .thumbs-up-header.lost .thumbs-up-logo {
    transform: scaleX(-1) rotate(180deg);
}

.bid-secured-modal .bid-secured-content {
    margin-top: 57px;
    padding: 30px 0 40px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.bid-secured-modal .bid-secured-content .bid-secured-text {
    font-size: 20px;
    font-weight: 600;
    line-height: 26px;
}

.bid-secured-modal .bid-secured-content .bid-secured-text.success {
    color: #3BB77E;
}

.bid-secured-modal .bid-secured-content .bid-secured-text.lost {
    color: #EB5757;
}

.bid-secured-modal .bid-secured-content .bid-secured-para {
    color: #808080;
    font-size: 14px;
    line-height: 21px;
}

.bid-secured-modal .bid-secured-btn-box {
    display: flex;
    justify-content: center;
    gap: 20px;
}

.bid-secured-modal .bid-secured-btn-box button {
    min-width: 157px;
}
.main-page-not-found .main-content {
    min-height: 100vh;
    padding: 50px 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.main-page-not-found .page-not-found-wrapper {
    max-width: 600px;
    margin: 0 auto;
    text-align: center;
}

.main-page-not-found .page-not-found-wrapper .page-not-found-img {
    max-width: 400px;
    max-height: 400px;
    margin: 0 auto;
    display: flex;
    margin-bottom: 20px;
}

.main-page-not-found .page-not-found-wrapper .page-not-found-img svg {
    width: 100%;
    height: 100%;
}

.main-page-not-found .page-not-found-wrapper .page-not-found-btn-box a {
    margin-top: 20px;
    display: inline-block;
}
@media (max-width: 767px) {

      .business-bank-section .business-bank-title {
            margin: 30px 0px;
      }

      .business-content-section {
            padding: 20px 15px 0;
      }

      .bussiness-title-flex {
            padding: 20px 15px;
      }

      .bussiness-title-flex .edit-details-title {
            font-size: 14px;
            line-height: 18px;
      }
}
.my-quotations-main .download-btn-box {
  padding: 30px 20px;
}

.my-quotations-main .download-btn-box .download-btn {
  padding: 8px 40px;
  border-radius: 8px;
  background: #BBB;
  color: #FFF;
  text-transform: capitalize;
}

.my-quotations-main .table-top-container {
  margin-bottom: 10px;
}
@media (max-width: 1023px) {
    .latest-posts-all .all-posts-box {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 30px;
    }

    .latest-posts-all .all-posts-box .all-posts-item .all-posts-content {
        margin: 10px;
        font-size: 18px;
        line-height: 22px;
    }
}

@media (max-width: 767px) {
    .latest-posts-all {
        padding: 30px 0;
    }

    .latest-posts-all .latest-posts-title {
        font-size: 30px;
        line-height: 36px;
        margin-bottom: 10px;
    }

    .latest-posts-all .latest-posts-content {
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 30px;
    }

    .latest-posts-all .all-posts-box {
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
        margin-bottom: 30px;
    }

    .latest-posts-all .pagination {
        gap: 10px;
    }

    .latest-posts-all .pagination .pagination-number {
        font-size: 16px;
        line-height: 20px;
    }

    .latest-posts-all .pagination .pagination-btn img {
        width: 30px;
        height: 30px;
    }

    .latest-posts-all .all-posts-box .all-posts-item .all-posts-content {
        font-size: 16px;
        line-height: 20px;
    }
}

@media (max-width: 374px) {
    .latest-posts-all .all-posts-box {
        grid-template-columns: repeat(1, 1fr);
        gap: 30px;
    }
}
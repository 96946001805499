@media (max-width: 1023px) {
    .about-us-wrape {
        margin-bottom: 60px;
    }
}

@media (max-width: 767px) {
    .about-us-wrape {
        margin-bottom: 40px;
    }

    .about-us-wrape::after,
    .about-us-wrape::before {
        display: none;
    }

    .about-us-wrape .inner-wrape {
        padding: 0;
        margin: 0 -15px;
        grid-template-columns: repeat(1, 1fr);
        gap: 0;
    }

    .about-us-wrape .inner-wrape .left-section {
        background-color: #27447C;
        padding: 40px 15px;
    }

    .about-us-wrape .inner-wrape .right-section {
        background-color: #F5F5F5;
        padding: 40px 15px;
    }

    .about-us-wrape .left-section h2 {
        font-size: 24px;
        line-height: 30px;
        font-weight: 500;
        margin-bottom: 30px;
        gap: 20px;
    }

    .about-us-wrape .left-section h2 img {
        width: 60px;
    }

    .about-us-wrape .left-section p {
        font-size: 30px;
        line-height: 40px;
        font-weight: 600;
    }

    .about-us-wrape .right-section p {
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 30px;
    }

    .about-us-wrape .inner-wrape .left-section,
    .about-us-wrape .inner-wrape .right-section {
        padding: 30px 15px;
    }
}
.setting-content-main .profile-seting-text {
      color: #333;
      font-family: "Inter";
      font-size: 20px;
      font-weight: 500;
      line-height: 26px;
}

.setting-profile-flex {
      display: flex;
      align-items: center;
      margin: 20px 0px 30px;
}

.setting-profile-flex .setting-edit-text {
      color: #EE682B;
      font-family: "Inter";
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      cursor: pointer;
}

.setting-content-main {
      padding: 20px 20px 30px;
}

.my-setting-input-main {
      position: relative;
      margin-bottom: 28px;
}

.my-setting-input-main .comman-form-group {
      position: relative;
}

.my-setting-input-main .comman-form-control {
      width: 100%;
}

.my-setting-input-main .input-edit-text {
      color: #EE682B;
      font-family: "Inter";
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      position: absolute;
      right: 15px;
      top: 37px;
      cursor: pointer;
}

.my-setting-input-main .setting-validation-text {
      color: #808080;
      font-family: "Inter";
      font-size: 15px;
      font-weight: 400;
      line-height: 20px;
      margin: 10px 0px 0px;
      max-width: 439px;
      width: 100%;
}

.seting-account-content-main .setting-account-text {
      margin-bottom: 15px;
      color: #333;
      font-family: "Inter";
      font-size: 20px;
      font-weight: 500;
      line-height: 24px;
}


.setting-logout-flex .setting-logout-text {
      color: #EB5757;
      font-family: "Inter";
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      border-radius: 8px;
      border: 1px solid#EB5757;
      padding: 9px 14px;
      max-width: 183px;
      width: 100%;
      cursor: pointer;
      justify-content: space-between;
      align-items: center;
      text-transform: capitalize;
}

.logout-icon {
      height: 24px;
      width: 24px;
      object-fit: contain;
}
.setting-row{
      display: grid;
      column-gap: 40px;
      grid-template-columns: repeat(2, 1fr);
}
@media (max-width: 1023px) {

    .blog-wrape {
        margin-bottom: 60px;
    }

    .blog-wrape .crousel-wrape {
        max-width: 600px;
    }

    .blog-wrape .top-content {
        gap: 40px;
    }

    .blog-wrape .top-content .left-wrape h5 {
        font-size: 40px;
        line-height: 47px;
        margin-bottom: 10px;
    }
}

@media (max-width: 767px) {
    .blog-wrape {
        margin-bottom: 40px;
        padding: 0;
    }

    .blog-wrape::before,
    .blog-wrape::after {
        display: none;
    }

    .blog-wrape .top-content {
        flex-direction: column;
        gap: 30px;
        margin-bottom: 30px;
    }

    .blog-wrape .top-content .left-wrape {
        width: 100%;
        max-width: 100%;
        background-color: #EE682B;
        padding: 20px 15px;
    }

    .blog-wrape .top-content .left-wrape h5 {
        font-size: 30px;
        line-height: 36px;
    }

    .blog-wrape .top-content .right-wrape .white-box h5 {
        font-size: 20px;
        line-height: 26px;
        margin-bottom: 10px;
    }

    .blog-wrape .top-content .left-wrape p,
    .blog-wrape .top-content .right-wrape .white-box p {
        font-size: 18px;
        line-height: 22px;
    }

    .blog-wrape .top-content .right-wrape .white-box {
        border-left: 4px solid #EE682B;
        padding-left: 20px;
    }

    .blog-wrape .top-content .right-wrape {
        width: 100%;
        max-width: 100%;
    }

    .blog-wrape .my-custom {
        color: #333333;
        font-size: 18px;
        line-height: 22px;
    }

    .blog-wrape .slide-controller {
        margin-top: 15px;
    }

    .blog-wrape .slide-controller .control img {
        width: 30px;
        height: 30px;
    }

    .blog-wrape .slide-controller .control {
        gap: 20px;
    }

    .blog-wrape .bottom-info .link img {
        width: 55px;
        height: 55px;
    }

    .blog-wrape .bottom-info .detail {
        font-size: 18px;
        line-height: 22px;
    }

    .blog-wrape .bottom-info {
        padding: 0 15px;
    }
}

@media (max-width: 549px) {
    .blog-wrape .top-content {
        gap: 20px;
    }

    .blog-wrape .top-content .left-wrape h5 {
        font-size: 24px;
        line-height: 30px;
        margin-bottom: 5px;
    }

    .blog-wrape .top-content .left-wrape p,
    .blog-wrape .top-content .right-wrape .white-box p {
        font-size: 16px;
        line-height: 20px;
    }

    .blog-wrape .top-content .right-wrape .white-box {
        border-left: 2px solid #EE682B;
        padding-left: 10px;
    }

    .blog-wrape .top-content .right-wrape .white-box h5 {
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 5px;
    }

    .blog-wrape .top-content {
        gap: 20px;
        margin-bottom: 20px;
    }

    .blog-wrape .bottom-info .link img {
        width: 50px;
        height: 50px;
    }

    .blog-wrape .bottom-info {
        padding: 0 10px;
    }
}
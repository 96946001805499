@media (max-width: 1023px) {
    .who-we-are {
        margin-bottom: 60px;
    }

    .who-we-are .top-section {
        gap: 30px;
    }
}

@media (max-width: 767px) {
    .who-we-are {
        margin-bottom: 30px;
    }

    .who-we-are .top-section {
        flex-direction: column;
        gap: 10px;
        padding: 30px 0;
    }

    .who-we-are .top-section .title {
        max-width: 100%;
        font-size: 30px;
        line-height: 37px;
    }
}
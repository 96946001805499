@media (max-width: 1249px) {
    .home-hero-main .banner-content-inner {
        max-width: 700px;
    }

    .home-hero-main .banner-content .banner-content-title {
        font-size: 40px;
        line-height: 47px;
    }
}

@media (max-width: 1023px) {
    .home-hero-main .banner-content-inner {
        max-width: 100%;
    }

    .home-hero-main .banner-content .banner-content-title {
        font-size: 30px;
        line-height: 37px;
        margin-bottom: 20px;
    }

    .home-hero-main .search-area {
        margin-bottom: 20px;
    }

    .home-hero-main .search-area .form-control input {
        padding: 10px 116px 10px 16px;
        background: #ffffff;
        border-radius: 100rem;
    }

    .home-hero-main .search-area .search-btn {
        padding: 5px 20px 6px 20px;
    }

    .home-hero-main .swiper-pagination.swiper-pagination-bullets {
        bottom: 20px;
    }

    .home-hero-main div.swiper-button-prev,
    .home-hero-main div.swiper-button-next,
    .home-hero-main div.swiper-button-prev img,
    .home-hero-main div.swiper-button-next img {
        height: 30px;
        width: 30px;
    }

    .home-hero-main .controler {
        bottom: 30px;
        right: 5%;
        gap: 20px;
    }

    .home-hero-main .tab-wrape .tab-wrape-link {
        padding: 5px 15px;
    }
}

@media (max-width: 767px) {
    .home-hero-main .banner-content {
        position: unset;
        transform: unset;
        background-color: #EE682B;
        padding: 30px 0;
    }

    .home-hero-main .search-area .search-btn {
        background-color: #27447C;
    }

    .home-hero-main .banner-content .banner-content-title {
        font-size: 24px;
        line-height: 30px;
    }

    .home-hero-main .tab-wrape,
    .home-hero-main .tab-wrape .tab-wrape-inner {
        gap: 10px;
    }

    .home-hero-main .tab-wrape .tab-wrape-link {
        font-size: 12px;
        line-height: 16px;
    }

    .home-hero-main .swiper-slide::after {
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 62.72%, #000 100%), linear-gradient(180deg, #000 0%, rgba(0, 0, 0, 0.00) 36.76%);
    }
}

@media (max-width: 549px) {
    .home-hero-main .swiper-pagination.swiper-pagination-bullets {
        bottom: 10px;
    }

    .home-hero-main .swiper-pagination-bullet {
        width: 8px;
        height: 8px;
    }

    .home-hero-main .controler {
        display: none;
    }

    .home-hero-main .banner-content {
        padding: 20px 0 30px;
        order: 3;
    }

    .home-hero-main .link-wrape {
        position: unset;
        background-color: #EE682B;
        padding: 20px 15px 0 15px;
        order: 2;
        width: 100%;
        gap: 10px;
        flex-wrap: wrap;
    }

    .home-hero-main .link-wrape .link-text {
        padding: 5px 15px;
        border-radius: 100rem;
        border: 1px solid #FFF;
        font-size: 12px;
        line-height: 16px;
    }

    .home-hero-main .swiper-slide::after {
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 62.72%, #000 100%);
    }
}
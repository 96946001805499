/* start admin dashboard page */

.admin-main .dashboard-content .admin-page-title {
  color: #111827;
  font-size: 26px;
  line-height: 39px;
  font-weight: 600;
  margin-bottom: 20px;
}

.admin-main .admin-dashboard-inner-box {
  border: 1px solid #d1d5db;
  max-width: 100%;
  width: 100%;
  height: 90px;
  border-radius: 6px;
  padding: 15px 20px;
  display: flex;
  gap: 15px;
  justify-content: space-between;
  align-items: center;
}

.admin-main .admin-dashboard-inner-box .admin-dash-text {
  color: #6b7280;
  font-size: 12px;
  line-height: 18px;
}

.admin-main .admin-dashboard-inner-box .admin-dash-price {
  color: #111827;
  font-size: 30px;
  line-height: 46px;
  font-weight: 500;
}

.admin-main .dash-icons {
  height: 50px;
  width: 50px;
  object-fit: contain;
}

.admin-main .page-table-main .table-container {
  padding-bottom: 7px;
  background-color: transparent;
  box-shadow: none;
  overflow: auto;
  max-width: calc(100vw - 0px);
}

.admin-main .page-table-main .table {
  margin: 0px 19px;
  /* min-width: 1100px; */
}

.admin-main .page-table-main .table-th {
  font-size: 12px;
  line-height: 18px;
  color: #111827;
  font-weight: 500;
  padding: 10px;
  border-bottom: 1px solid #d1d5db;
}

.admin-main .page-table-main .table .table-td:first-child {
  color: #111827;
}

.admin-main .page-table-main .table-td {
  font-size: 12px;
  line-height: 18px;
  color: #6b7280;
  padding: 10px;
  border-bottom: 1px solid #d1d5db;
}

.admin-main .admin-sub-title-main .admin-sub-title {
  color: #111827;
  font-size: 20px;
  line-height: 30px;
  font-weight: 500;
}

.admin-main .admin-title-box {
  margin-bottom: 20px;
}

.admin-main .admin-footer-btn-box{
  display: flex;
  align-items: center;
  gap: 15px;
  flex-wrap: wrap;
}

.admin-main .admin-sub-title-main .admin-buyer-title {
  font-weight: 700;
  font-size: x-large;
}

.admin-main .view-btn-main {
  display: flex;
  gap: 15px;
}

.admin-search-common {
  position: relative;
  cursor: pointer;
  align-self: flex-start;
  flex: 1;
}

.admin-search-common .form-control input {
  padding: 11px 10px 11px 32px;
  color: #4A4A4A;
  font-size: 14px;
  line-height: 18px;
  height: unset;
  background: #F2F2F2;
  border-radius: 8px;
}

.admin-search-common .form-control fieldset {
  border-radius: 100rem;
  border: 1px solid #DBDBDB;
}

.admin-search-common .form-control .Mui-focused fieldset {
  border: 1px solid rgba(0, 0, 0, 0.87);
}

.admin-search-common .search-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 8px;
  width: 16px;
  height: 16px;
  object-fit: contain;
}

.admin-main .admin-dashboard-table-row {
  border-bottom: 1px solid #d1d5db;
  padding: 20px 19px;
}

.admin-main .admin-dash-box {
  border-radius: 8px;
  border: 1px solid #d1d5db;
  padding: 0px 0px 20px 0px;
}

.admin-main .admin-dashboad-row {
  margin-bottom: 24px;
}

.admin-main .display-row>* {
  padding: 0px 8px;
}

.admin-main .display-row {
  margin-left: -8px;
  margin-right: -8px;
}

.admin-main .table-lable-main {
  font-size: 12px;
  line-height: 18px;
}

.admin-main .paid-lable-dots {
  background-color: #04ab47;
}

.admin-main .paid-lable {
  color: #04ab47;
}

.admin-main .table-lable-dots {
  height: 7px;
  width: 7px;
  border-radius: 50%;
  margin-right: 4px;
}

.admin-main .table-lable-flex {
  display: flex;
  align-items: center;
}

.admin-main .failed-lable-dots {
  background-color: #e4004d;
}

.admin-main .failed-lable {
  color: #e4004d;
}

.admin-main .pending-lable-dots {
  background-color: #f39c12;
}

.admin-main .pending-lable {
  color: #f39c12;
}

/* end admin dashboard page */

/*  page-buyer-table-main*/
/* .admin-main . .table .table-th:nth-child(1),
.admin-main .page-buyer-table-main .table .table-td:nth-child(1) {
  width: 20%;
} */
.buyer-list-status {
  font-size: 13px;
  width: 90px;
  height: 35px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: "17px 20px 17px 20px;";
}
/* start header css */
.header-main {
      background-color: #fff;
      position: sticky;
      top: 0;
      z-index: 3;
}

.header-main-inner {
      padding: 20px 15px;
      max-width: 1400px;
      width: 100%;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 30px;
}

.header-logo-box .header-logo {
      width: 170px;
}

.header-nav-ul {
      display: flex;
      align-items: center;
      gap: 40px;
}

.header-nav-link {
      font-size: 16px;
      line-height: 20px;
      font-weight: 500;
      color: #000;
}

.header-nav-link:hover {
      color: #0B80C4;
}

.header-right-main {
      display: flex;
      align-items: center;
      gap: 25px;
      font-size: 14px;
      line-height: 18px;
}

.header-right-main .header-login-link:hover {
      color: #0B80C4;
}

.header-right-main .auth-wrape {
      display: flex;
      align-items: center;
      gap: 6px;
      cursor: pointer;
}

.header-search-box {
      position: relative;
      cursor: pointer;
}

.header-search-box .form-control input {
      padding: 8px 8px 8px 32px;
      color: #4A4A4A;
      font-size: 13px;
      line-height: 17px;
      height: unset;
      background: #F2F2F2;
      border-radius: 100rem;
}

.header-search-box .form-control fieldset {
      border-radius: 100rem;
      border: 1px solid #DBDBDB;
}

.header-search-box .form-control .Mui-focused fieldset {
      border: 1px solid rgba(0, 0, 0, 0.87);
}

.header-search-icon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 8px;
      width: 16px;
      height: 16px;
      object-fit: contain;
}

.header-cust-col .mobile-menu-btn {
      padding: 0;
      min-width: unset;
      display: none;
}

.header-cust-col .mobile-menu-btn img {
      width: 16px;
      height: 16px;
}

/* end header css */



/* start footer css */

.footer-row {
      padding: 60px 0;
}

.footer-logo-box {
      margin-bottom: 40px;
}

.footer-logo-box .footer-logo-Link .footer-logo {
      width: 250px;
}

.footer-col-box {
      display: grid;
      grid-template-columns: 1.2fr 1fr 1fr 1fr;
      gap: 40px;
}

.footer .footer-nav-titles {
      font-size: 20px;
      line-height: 26px;
      font-weight: 700;
      margin-bottom: 10px;
}

.footer-col .footer-address {
      font-size: 14px;
      line-height: 30px;
}

.footer-col .footer-contact {
      font-size: 14px;
      line-height: 18px;
      margin-top: 30px;
}

.footer-col .footer-contact-link {
      color: #000;
}

.footer-nav-main .footer-nav-ul {
      display: flex;
      flex-direction: column;
      gap: 12px;
}

.footer-nav-main .footer-nav-li {
      padding: 0px;
}

.footer-nav-main .footer-nav-link {
      font-size: 14px;
      line-height: 18px;
      color: #000000;
      text-decoration: none;
      cursor: pointer;
      display: flex;
      width: fit-content;
}

.footer-nav-main .footer-nav-link:hover {
      color: #0B80C4;
}

.footer-copy-right-box {
      border-top: 1px solid #D9DBE9;
      padding: 30px 0px;
      color: #353535;
      font-size: 18px;
      line-height: 22px;
      display: flex;
      row-gap: 5px;
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-end;
}

.footer-copy-right-box a {
      color: #27447C;
      border-bottom: 1px solid #27447C;
}

.footer-col .social-wrape {
      display: flex;
      gap: 30px;
}

.footer-col .app-store-box {
      display: flex;
      gap: 16px;
      margin-top: 30px;
}

.footer-col .app-store-box .app-store-link {
      width: calc(50% - 8px);
      display: flex;
}

.footer-col .app-store-box .app-store-link img {
      width: 100%;
      max-width: 120px;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      border-radius: 100rem;
}
/* end footer css */
.bussiness-title-box {
      background: #FFF;
      box-shadow: 0px 0px 12px 0px rgba(126, 133, 148, 0.08);
}

.bussiness-title-flex {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      padding: 30px 20px;
      margin-bottom: 10px;
      gap: 10px;
}

.bussiness-title-flex .business-title {
      color: #27447C;
      font-family: "Poppins";
      font-size: 20px;
      font-weight: 500;
      line-height: 24px;
}

.bussiness-title-flex .edit-details-title {
      color: #EE682B;
      font-family: "Inter";
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      cursor: pointer;
}

.business-content-section {
      padding: 30px 20px 0;
}

.verify-input-main .comman-form-control {
      width: 100%;
}

.bussines-details-main {
      border-bottom: 1px solid #D9D9D9;
}

.business-bank-section .business-bank-title {
      color: #000;
      font-family: "Inter";
      font-size: 20px;
      font-weight: 400;
      line-height: 24px;
      margin: 38px 0px 30px;
}
.key-feature-wrape {
    background: url(../../../../../assets/images/png/keyfeature.png) no-repeat;
    width: 100%;
    padding: 30px 0 40px;
    background-size: cover;
    margin-bottom: 90px;
}

.key-feature-wrape .key-feature-title {
    color: #FFFFFF;
    font-size: 50px;
    line-height: 57px;
    font-weight: 600;
    margin-bottom: 30px;
    text-align: center;
}

.key-feature-wrape .key-feature-subtitle {
    color: #FFFFFF;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
}

.key-feature-wrape .crousel-wrape {
    padding: 50px 0 50px 15px;
}

.key-feature-wrape .white-box {
    padding: 23px;
    border-radius: 12px;
    background: #FFF;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.key-feature-wrape .white-box img {
    margin-bottom: 17px;
}

.key-feature-wrape .white-box h6 {
    color: #27447C;
    font-weight: 500;
    margin-bottom: 10px;
}

.key-feature-wrape .white-box p {
    color: #27447C;
    font-size: 14px;
    line-height: 18px;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.key-feature-wrape .swiper-wrapper .swiper-slide {
    height: auto;
}

.key-feature-wrape .slide-controller {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.key-feature-wrape .slide-controller .cus-swiper-button-prev,
.key-feature-wrape .slide-controller .cus-swiper-button-next {
    cursor: pointer;
}

.key-feature-wrape .custom-pagination {
    color: #ffffff;
    font-size: 20px;
    line-height: 24px;
    width: auto;
    display: flex;
}
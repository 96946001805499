@media (max-width: 1249px) {
    .main-analytics-dashboard {
        padding: 44px 25px 44px 25px;
    }
    .analytics-main-grid .analytics-col1 {
        width: calc(100% / 2);
        margin-bottom: 15px;
    }

    .analytics-main-grid .analytics-col1:nth-child(2) {
        border-right: 0px;
    }
}


@media (max-width: 1023px) {
}

@media (max-width: 767px) {
    .main-analytics-dashboard{
        padding: 44px 15px 44px 15px;
    }
    .analytics-grid-col-row .dash-analytics-col2 {
        width: 100%;
        max-width: 100%;
        flex: 0 0 100%;
    }
}

@media (max-width: 549px) {
        
    .analytics-main-grid .analytics-col1 {
        width: calc(100% / 1);
        margin-bottom: 15px;
    }

    .analytics-main-grid .analytics-col1{
        border-right: 0px;
    }
}

@media (max-width: 374px) {

}
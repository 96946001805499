@media (max-width: 767px) {
      .verify-grid-row {
            grid-template-columns: repeat(1, 1fr);
      }

      .verify-input-main {
            margin-bottom: 30px;
      }

      .modal-header .modal-header-title {
            margin: 0px auto 30px;
      }

      .veryfy-modal-inner-main .verify-para {
            margin-bottom: 30px;
            font-weight: 400;
      }

      .verify-bussiness-main .verify-bussiness-title {
            margin-bottom: 20px;
      }

      .modal-auth-btn-main.modal-verfy-bt-main {
            margin-top: 10px;
            margin-bottom: 0;
      }

      .modal-verfy-bt-main .orange-fill-btn-main .verfy-modal-btn {
            min-width: unset;
      }
}
@media (max-width: 1249px) {
      .blog-details-right-main {
            margin-left: 30px;
      }
}

@media (max-width: 1023px) {
      .blog-details-img-right-main {
            max-width: 100%;
            flex: 0 0 100%;
            margin-left: 0px;
      }

      .blog-details-right-main {
            margin-left: 15px;
      }

      .blog-details-theory-content-main .blog-details-inner-titles {
            font-size: 20px;
            line-height: 30px;
            margin-bottom: 25px;
            max-width: 100%;
      }

      .blog-details-theory-content-main .blog-details-para {
            margin-bottom: 10px;
            font-size: 17px;
            line-height: 28px;
      }
      
      .blog-details-para-img {
            height: 100%;
            max-width: 100%;
      }
}

@media (max-width: 767px) {
      .blog-details-img-flex {
            flex-direction: column;
      }

      .blog-details-right-main {
            max-width: 100%;
            flex: 0 0 100%;
            margin-left: 0px;
            margin-bottom: 30px;
      }

      .blog-details-para-flex {
            flex-direction: column;
      }

      .blog-details-image {
            max-width: 100%;
            height: 300px;
            margin-bottom: 20px;
      }

}

@media (max-width: 549px) {}

@media (max-width: 374px) {}
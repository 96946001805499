.seller-confirmed-order-main {
    margin: -20px 0;
}

.seller-confirm-pending {
    color: #cacaca;
}

.modal-common-main .modal-common-style.update-sts-inner-main {
    max-width: 632px;
}

.update-sts-inner-main .update-sts-modal-title {
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
}

.update-sts-inner-main .modal-body {
    margin: 30px 0;
    text-align: center;
}

.update-sts-inner-main .update-sts-btn-main {
    display: flex;
    gap: 16px;
    justify-content: center;
}

.update-sts-inner-main .update-sts-box {
    display: inline-flex;
    flex-direction: column;
    gap: 12px;
}

.update-sts-inner-main .update-sts-box .update-sts-item {
    padding: 10px 15px;
    display: flex;
    justify-content: center;
    text-transform: capitalize;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    border: 1px solid #d9d9d9;
    background-color: #D9D9D9;
    color: #7E8894;
    text-align: center;
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
}

.update-sts-inner-main .update-sts-box .update-sts-item.order-confirmed {
    background-color: #2DA819;
    border: 1px solid #2DA819;
    color: #fff;
    font-weight: 400;
}

.update-sts-inner-main .update-sts-box .update-sts-item.manufacturing {
    border: 1px solid #2DA819;
    color: #343434;
    background-color: transparent;
}
.main-product-detail {
    background-color: #fafafa;
}
.main-product-detail .product-detail-wrapper {
    display: flex;
    align-items: flex-start;
    gap: 60px;
    margin: 26px 0 40px;
}

.main-product-detail .product-detail-wrapper .product-detail-left {
    width: 100%;
    max-width: 750px;
}

.main-product-detail .product-title-box {
    margin-bottom: 30px;
}

.main-product-detail .product-title-box .product-title {
    font-size: 30px;
    line-height: 36px;
    font-weight: 600;
    margin-bottom: 20px;
}

.main-product-detail .product-title-box .product-content-box {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    gap: 15px;
}

.main-product-detail .product-title-box .product-content-box .product-content {
    font-weight: 300;
    max-width: 500px;
    flex: 1;
}

.main-product-detail .product-title-box .product-review-box {
    display: flex;
    align-items: flex-end;
    gap: 15px;
}

.main-product-detail .product-title-box .product-review-box .product-review-star {
    display: flex;
    align-items: center;
    gap: 3px;
    font-size: 20px;
    line-height: 26px;
    font-weight: 500;
}

.main-product-detail .product-title-box .product-review-box .product-review-count {
    color: #191919;
    font-size: 14px;
    line-height: 18px;
    font-weight: 300;
}

.main-product-detail .product-image-box {
    display: flex;
    gap: 20px;
}

.main-product-detail .product-image-box .product-image-thumb-box {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.main-product-detail .product-image-box .product-image-thumb {
    width: 125px;
    height: 125px;
    border-radius: 4px;
    object-fit: cover;
}

.main-product-detail .product-image-box .product-image-more {
    width: 125px;
    height: 125px;
    border-radius: 4px;
    background-color: #f5f5f5;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    color: #27447C;
}

.main-product-detail .product-image-box .product-image-main{
    width: 100%;
}

.main-product-detail .product-image-box .product-image-main .product-image {
    display: flex;
    border-radius: 8px;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.main-product-detail .product-detail-wrapper .product-detail-right {
    flex: 1;
}

.main-product-detail .product-select-top {
    box-shadow: 0px 0px 12px 0px rgba(126, 133, 148, 0.08);
    border-radius: 8px;
    padding: 20px;
    background-color: #fff;
}

.main-product-detail .product-select-top .product-select-row:not(:last-child) {
    margin-bottom: 30px;
}

.main-product-detail .product-select-top .product-select-row .product-select-label {
    font-weight: 500;
    margin-bottom: 10px;
}

.main-product-detail .product-select-top .product-select-row .product-select-qty {
    display: inline-flex;
    border-radius: 8px;
    border: 1px solid #f0f0f0;
}

.main-product-detail .product-select-top .product-select-qty .MuiInput-root::before,
.main-product-detail .product-select-top .product-select-qty .MuiInput-root::after {
    display: none;
}

.main-product-detail .product-select-top .product-select-qty .MuiInput-input {
    height: 43px;
    width: 75px;
    padding: 10px;
    box-sizing: border-box;
    border-left: 1px solid #f0f0f0;
    border-right: 1px solid #f0f0f0;
    text-align: center;
}

.main-product-detail .product-select-top .product-select-row .product-select-qty-btn {
    padding: 10px 20px;
    height: 43px;
    min-width: unset;
    border-radius: 0;
    color: #000;
    font-size: 24px;
}

.main-product-detail .send-enquiry-btn {
    width: 100%;
    margin-top: 28px;
}

.main-product-detail .product-detail-tab-bg {
    background-color: #f5f5f5;
    padding: 40px 0 60px;
}

.main-product-detail .product-detail-tab-box {
    margin-bottom: 40px;
}

.main-product-detail [aria-label="product-detail-tab-box"] {
    gap: 24px;
    border-bottom: 1px solid #cacaca;
    display: inline-flex;
}

.main-product-detail .product-detail-tab-box .product-detail-tab-btn {
    font-size: 24px;
    line-height: 30px;
    font-weight: 500;
    padding: 20px 12px;
    color: #000;
}

.main-product-detail .product-detail-tab-wrapper .MuiTabs-indicator {
    height: 4px;
    background-color: #27447C;
}

.main-product-detail .product-detail-tab-wrapper .product-detail-tab-panel {
    line-height: 24px;
}
@media (max-width: 767px) {
    .place-bid-modal .place-bid-modal-wrapper {
        flex-direction: column-reverse;
        gap: 30px;
    }

    .place-bid-modal .place-bid-modal-wrapper .bid-left .product-img-box {
        display: none;
    }

    .place-bid-modal .product-img-box-mobile {
        display: flex;
        margin-bottom: 20px;
    }

    .place-bid-modal .product-order-details .product-order-details-item:last-child {
        border-bottom: 1px solid #cacaca;
    }

    .place-bid-modal .place-bid-modal-wrapper .bid-left,
    .place-bid-modal .place-bid-modal-wrapper .bid-right {
        width: 100%;
    }

    .place-bid-modal .lowest-bid-low,
    .place-bid-modal .bid-place-btn-box {
        max-width: 100%;
    }

    .bid-success-modal.modal-common-main .modal-common-style {
        max-width: 450px;
    }

    .bid-success-modal .bid-success-content {
        font-size: 16px;
        line-height: 20px;
        margin: 30px 0;
    }
}

@media (max-width: 549px) {
    .place-bid-modal .product-bid-details {
        grid-template-columns: repeat(2, 1fr);
    }

    .bid-secured-modal .thumbs-up-header {
        padding: 10px;
    }

    .bid-secured-modal .thumbs-up-header .thumbs-up-logo {
        width: 37px;
        height: 37px;
    }

    .bid-secured-modal .bid-secured-content {
        margin-top: 27px;
        padding: 30px 0;
        gap: 10px;
    }

    .bid-secured-modal .bid-secured-btn-box button {
        min-width: unset;
    }
}
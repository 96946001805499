@media (max-width: 1399px) {
    .app-work-wrape .down-wrape {
        width: 80%;
    }
}

@media (max-width: 1199px) {
    .app-work-wrape .down-wrape {
        left: 30px;
        width: 85%;
    }

    .app-work-wrape .up-wrape .sec.first,
    .app-work-wrape .up-wrape .sec.third,
    .app-work-wrape .up-wrape .sec.fifth {
        bottom: -130px;
    }

    .app-work-wrape .down-wrape .sec.first {
        top: -75px;
    }

    .app-work-wrape .down-wrape .sec.third {
        top: -65px;
    }
}

@media (max-width: 1023px) {
    .app-work-wrape h4 {
        font-size: 40px;
        line-height: 47px;
        margin-bottom: 60px;
    }

    .app-work-wrape .middle-wrape img {
        display: none;
    }

    .app-work-wrape .middle-wrape {
        position: relative;
        width: auto;
        max-width: unset;
        margin: unset;
        padding: 0 80px;
    }

    .app-work-wrape .middle-wrape::after {
        content: "";
        position: absolute;
        width: 5px;
        height: 100%;
        background: linear-gradient(92deg, #99EEB9, #7ABAC0, #6080C6, #5147D4, #7C54B3, #A85E9F, #D86986, #CFA176, #CEA076);
        border-radius: 100rem;
    }

    .app-work-wrape .flex-section {
        display: flex;
        gap: 15px;
    }

    .app-work-wrape .up-wrape,
    .app-work-wrape .down-wrape {
        max-width: 250px;
        left: unset;
        flex-direction: column;
        justify-content: flex-start;
        width: 100%;
    }

    .app-work-wrape .up-wrape {
        align-items: flex-end;
        text-align: end;
        margin: 0 0 0 auto;
    }

    .app-work-wrape .down-wrape {
        margin: 0 auto 0 0;
        padding-top: 100px;
    }

    .app-work-wrape .up-wrape .sec,
    .app-work-wrape .down-wrape .sec {
        max-width: 100% !important;
        top: unset !important;
        bottom: unset !important;
        height: unset;
    }

    .app-work-wrape .up-wrape .sec::after,
    .app-work-wrape .down-wrape .sec::after {
        position: absolute;
        content: "";
        border-bottom: 1px dashed #222222;
        width: 80px;
        top: 40px;
    }

    .app-work-wrape .down-wrape .sec::after {
        right: calc(100% + 10px);
    }

    .app-work-wrape .up-wrape .sec::after {
        left: calc(100% + 10px);
    }

    .app-work-wrape .up-wrape .sec::before,
    .app-work-wrape .down-wrape .sec::before {
        position: absolute;
        content: "";
        width: 26px;
        height: 26px;
        top: 28px;
        border-radius: 50%;
        z-index: 1;
    }

    .app-work-wrape .down-wrape .sec::before {
        right: calc(100% + 80px);
    }

    .app-work-wrape .up-wrape .sec::before {
        left: calc(100% + 84px);
    }

    .app-work-wrape .down-wrape .sec.second,
    .app-work-wrape .up-wrape .sec.third {
        margin-top: 10px;
    }

    .app-work-wrape .up-wrape .sec.fourth,
    .app-work-wrape .up-wrape .sec.fifth {
        margin-top: 40px;
    }

    .app-work-wrape .up-wrape .sec.first::before {
        background-color: #99EEB9;
    }

    .app-work-wrape .up-wrape .sec.second::before {
        background-color: #5D72CA;
    }

    .app-work-wrape .up-wrape .sec.third::before {
        background-color: #5F4CCB;
    }

    .app-work-wrape .up-wrape .sec.fourth::before {
        background-color: #C56591;
    }

    .app-work-wrape .up-wrape .sec.fifth::before {
        background-color: #CEA076;
    }

    .app-work-wrape .down-wrape .sec.first::before {
        background-color: #7CBDC0;
    }

    .app-work-wrape .down-wrape .sec.second::before {
        background-color: #5D72CA;
    }

    .app-work-wrape .down-wrape .sec.third::before {
        background-color: #8D59AC;
    }

    .app-work-wrape .down-wrape .sec.fourth::before {
        background-color: #D57F81;
    }
}

@media (max-width: 767px) {
    .app-work-wrape {
        padding: 40px 0;
        margin-bottom: 40px;
    }

    .app-work-wrape h4 {
        font-size: 30px;
        line-height: 36px;
        margin-bottom: 40px;
    }

    .app-work-wrape .middle-wrape {
        padding: 0 40px;
    }

    .app-work-wrape .up-wrape .sec::after,
    .app-work-wrape .down-wrape .sec::after {
        width: 40px;
    }

    .app-work-wrape .up-wrape .sec::before {
        left: calc(100% + 47px);
    }

    .app-work-wrape .down-wrape .sec::before {
        right: calc(100% + 42px);
    }

    .app-work-wrape .up-wrape .sec::before,
    .app-work-wrape .down-wrape .sec::before {
        width: 22px;
        height: 22px;
        top: 30px;
    }

    .app-work-wrape .up-wrape .sec img,
    .app-work-wrape .down-wrape .sec img {
        width: 50px;
    }

    .app-work-wrape .sec h6 {
        font-size: 16px;
        line-height: 20px;
        margin: 0 0 5px 0;
    }

    .app-work-wrape .sec p {
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 10px;
    }

    .app-work-wrape .down-wrape {
        padding-top: 70px;
    }

    .app-work-wrape .up-wrape .sec.fourth,
    .app-work-wrape .up-wrape .sec.fifth {
        margin-top: 10px;
    }
}

@media (max-width: 549px) {
    .app-work-wrape h4 {
        font-size: 24px;
        line-height: 30px;
        margin-bottom: 30px;
    }

    .app-work-wrape .flex-section {
        flex-direction: column;
        gap: 20px;
        position: relative;
        padding-left: 60px;
    }

    .app-work-wrape .middle-wrape {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
    }

    .app-work-wrape .middle-wrape::after {
        left: 5px;
    }

    .app-work-wrape .up-wrape .sec::after {
        left: unset;
        right: calc(100% + 10px);
    }

    .app-work-wrape .up-wrape .sec::before {
        left: unset;
        right: calc(100% + 42px);
    }

    .app-work-wrape .up-wrape {
        text-align: unset;
        margin: unset;
        max-width: 100%;
    }

    .app-work-wrape .down-wrape {
        margin: unset;
        padding: 0;
        max-width: 100%;
    }

    .app-work-wrape .down-wrape .sec.second,
    .app-work-wrape .up-wrape .sec.third,
    .app-work-wrape .up-wrape .sec.fourth,
    .app-work-wrape .up-wrape .sec.fifth {
        margin: 0;
    }
}
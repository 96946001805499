.join-team{
    position: relative;
}
.join-team::before{
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    background-image: url("../../../../../assets/images/png/about_subtract.png");
    width: 63%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: left;
    z-index: -1;
}
.join-team::after{
    position: absolute;
    content: "";
    background-image: url("../../../../../assets/images/jpg/about_join_team.jpg");
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right;
    z-index: -2;
}
.join-team .join-team-inner{
    padding: 60px 0;
    max-width: 540px;
    color: #fff;
    font-size: 24px;
    line-height: 30px;
}
.join-team .join-team-inner .join-team-title{
    font-weight: 700;
    margin-bottom: 30px;
}
.join-team .join-team-inner .join-team-content{
    margin-bottom: 60px;
}
.join-team .join-team-inner .join-team-btn{
    padding: 18px;
    background-color: #fff;
    color: #343434;
    border-radius: 100rem;
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 18px;
    text-transform: capitalize;
}
.join-team .join-team-inner .join-team-btn svg{
    font-size: 18px;
}
.numbers-talk{
    background-color: #27447C;
    border-radius: 20px;
    color: #fff;
    padding: 40px 15px 60px;
    margin-bottom: 90px;
}

.numbers-talk .title{
    font-size: 50px;
    line-height: 57px;
    font-weight: 600;
    margin-bottom: 20px;
    text-align: center;
}

.numbers-talk .content{
    text-align: center;
    max-width: 600px;
    margin: 0 auto 60px;
}

.numbers-talk .numbers-item-box{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
}

.numbers-talk .numbers-item-box .numbers-item-inner{
    width: fit-content;
    margin: 0 auto;
}

.numbers-talk .numbers-item-box .numbers-item:not(:last-child) {
    border-right: 2px dashed #fff;
}

.numbers-talk .numbers-item-box .numbers-item .number{
    font-size: 50px;
    line-height: 57px;
    font-family: "Poppins", "sans-serif";
    font-weight: 600;
    margin-bottom: 6px;
}

.numbers-talk .numbers-item-box .numbers-item .label{
    font-size: 20px;
    line-height: 27px;
    font-family: "Poppins", "sans-serif";
    font-weight: 300;
}
.modal-header .modal-header-title {
      color: #000;
      font-family: "Inter";
      font-size: 25px;
      font-weight: 500;
      line-height: 32px;
      text-align: center;
      display: flex;
      margin: 0px auto 40px;
}

.upload-file-with-text {
      position: relative;
}

.comman-form-group .upload-file-with-text .comman-form-control input{
      padding-right: 115px;
}

.upload-file-with-text .upload-file-right-main {
      position: absolute;
      top: 0;
      right: 0;
      border-radius: 0 7px 7px 0;
}

.upload-file-with-text .upload-file {
      max-width: 106px;
      width: 100%;
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      display: inline-block;
}

.upload-file-with-text .upload-file input {
      opacity: 0;
      z-index: 99;
      padding: 0;
      height: 40px;
      cursor: pointer;
}

.upload-file-with-text .upload-file fieldset {
      display: none;
}

.veryfy-modal-inner-main .verify-para {
      color: #A6A6A6;
      font-family: "Inter";
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      margin-bottom: 40px;
}

.verify-bussiness-main .verify-bussiness-title {
      color: #000;
      font-family: "Inter";
      font-size: 20px;
      font-weight: 400;
      line-height: 26px;
      margin-bottom: 38px;
}

.cloudupload-icons {
      height: 20px;
      width: 20px;
      object-fit: contain;
      margin-right: 4px;
}

.upload-file-main .upload-file-control input {
      padding: 9px 0px;
      color: #BEBEBE;
      font-family: "Inter";
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
}

.upload-file-main .upload-file-control input::placeholder {
      color: #BEBEBE;
      font-family: "Inter";
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
}

.upload-file-main {
      position: relative;
      cursor: pointer;
}

.upload-file-main .upload-file-control {
      opacity: 0;
      z-index: 99;
}

.upload-file-cust-main {
      position: absolute;
      width: 100%;
      top: 0px;
      opacity: 1;
      cursor: pointer;
      border-radius: 8px;
      border: 1px solid #D2D3D5;
}

.upload-file-left-main .upload-file-text {
      color: #808080;
      font-family: "Inter";
      font-size: 15px;
      font-weight: 400;
      line-height: 20px;
      padding: 11px 16px;
}

.upload-file-left-main {
      flex: 1 1;
}

.upload-file-right-main {
      max-width: 106px;
      width: 100%;
      flex: 0 0 106px;
      background-color: #D8D8D8;
      border-radius: 0 7px 7px 0;
}

.upload-file-right-main .upload-browse-text {
      color: #000;
      font-family: "Inter";
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      padding: 10px 15px;
      display: flex;
      align-items: center;
      justify-content: center;
}

.upload-file-cust-flex {
      display: flex;
      height: 40px;
}

.verify-grid-row {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 42px;
}

.verify-input-main .form-lable {
      margin-bottom: 9px;
      color: #000;
      font-family: "Inter";
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
}

.astric-sign {
      color: #000;
      font-family: "Inter";
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
}

.modal-footer-main {
      border-top: 1px solid#D9D9D9;
      margin: 56px 0px 0px 0px;
}

.after-upload-flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 10px 0px;
}

.after-upload-flex .after-upload-path-text {
      color: #BEBEBE;
      font-family: "Inter";
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      display: flex;
      align-items: center;
}

.description-icon {
      margin-right: 5px;
      height: 20px;
      width: 20px;
      object-fit: contain;
}

.delete-icon {
      height: 24px;
      width: 24px;
      object-fit: contain;
      margin-right: 10px;
}

.upload-done-right-main {
      background-color: transparent;
}

.upload-done-right-main .upload-done-text {
      justify-content: flex-end;
}

.modal-verfy-bt-main .orange-fill-btn-main .verfy-modal-btn {
      min-width: 286px;
}

.modal-auth-btn-main.modal-verfy-bt-main {
      margin-bottom: 10px;
}

.veryfy-bank-section {
      border-top: 1px solid #D9D9D9;
      ;
}

.verify-input-main {
      margin-bottom: 40px;
}
.home-hero-main {
    position: relative;
    display: flex;
    flex-wrap: wrap;

}

.home-hero-main .banner-slide {
    width: 100%;
    max-height: 680px;
    object-fit: cover;
}

.home-hero-main .swiper-slide {
    display: flex;
    position: relative;
}

.home-hero-main .swiper-slide::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 62.72%, #000 100%), linear-gradient(180deg, #000 0%, rgba(0, 0, 0, 0.00) 36.76%), linear-gradient(90deg, #000 4.02%, rgba(0, 0, 0, 0.00) 75.32%);
}

.home-hero-main .mobile-shows {
    display: none;
}

.home-hero-main .banner-content {
    position: absolute;
    z-index: 1;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    width: 100%;
}

.home-hero-main .banner-content-inner {
    max-width: 840px;
}

.home-hero-main .banner-content .banner-content-title {
    font-size: 58px;
    line-height: 70px;
    font-weight: 600;
    color: #ffffff;
    margin-bottom: 30px;
}

.home-hero-main .search-area {
    position: relative;
    margin-bottom: 30px;
}

.home-hero-main .search-area .form-control input {
    padding: 16px 160px 17px 30px;
    background: #ffffff;
    border-radius: 100rem;
}

.home-hero-main .search-area .form-control fieldset {
    display: none;
}

.home-hero-main .search-area .search-btn {
    border-radius: 100rem;
    background: #EE682B;
    padding: 5px 35px 6px 35px;
    height: calc(100% - 8px);
    font-size: 16px;
    line-height: 22px;
    font-weight: 500;
    color: #ffffff;
    position: absolute;
    right: 4px;
    top: 50%;
    transform: translateY(-50%);
    text-transform: capitalize;
}

.home-hero-main .search-area .search-btn:hover {
    background: #27447C;
}

.home-hero-main .tab-wrape {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 20px;
}

.home-hero-main .tab-wrape .tab-wrape-title {
    color: #ffffff;
    font-size: 16px;
    line-height: 20px;
}

.home-hero-main .tab-wrape .tab-wrape-inner {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 20px;
}

.home-hero-main .tab-wrape .tab-wrape-link {
    font-size: 14px;
    line-height: 18px;
    color: #ffffff;
    border-radius: 100rem;
    border: 1px solid #FFF;
    background: rgba(0, 0, 0, 0.20);
    padding: 8px 25px;
}

.home-hero-main .link-wrape {
    position: absolute;
    right: 20px;
    top: 30px;
    z-index: 1;
    display: flex;
    gap: 30px;
}

.home-hero-main .link-wrape .link-text {
    color: #ffffff;
    font-size: 14px;
    line-height: 18px;
}

.home-hero-main .link-wrape a:hover {
    opacity: 0.75;
}

.home-hero-main .controler.mobile-shows {
    display: none;
}

.home-hero-main .controler {
    position: absolute;
    bottom: 40px;
    right: 10%;
    display: flex;
    gap: 30px;
}

.home-hero-main div.swiper-button-prev,
.home-hero-main div.swiper-button-next {
    position: unset;
    height: 40px;
    width: 40px;
    margin: 0;
}

.home-hero-main div.swiper-button-prev img,
.home-hero-main div.swiper-button-next img {
    height: 40px;
    width: 40px;
}

.home-hero-main div.swiper-button-prev::after,
.home-hero-main div.swiper-button-next::after {
    display: none;
}

.home-hero-main .swiper-pagination.swiper-pagination-bullets {
    bottom: 30px;
    line-height: 0;
}

.home-hero-main .swiper-pagination-bullet {
    background: rgb(255, 255, 255);
    width: 10px;
    height: 10px;
}

.home-hero-main .swiper-pagination-bullet.swiper-pagination-bullet-active {
    background: #EE682B;
}

.you-may-like-content-main .you-may-like-ul {
      padding: 0px;
      margin: 0px;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 24px;
}

.you-may-like-ul .you-may-like-li {
      padding: 0px;
      display: block;
}

.you-may-round-arrow {
      width: 30px;
      height: 30px;
      object-fit: contain;
}

.you-may-like-content-box .you-may-para {
      color: #27447C;
      font-family: "Inter";
      font-size: 10px;
      font-weight: 500;
      line-height: 15px;
      max-width: 255px;
      width: 100%;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      min-height: 15px;
}

.you-may-flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
}

.you-may-like-content-box {
      border-radius: 0px 0px 8px 8px;
      border-right: 0.659px solid #CACACA;
      border-bottom: 0.659px solid #CACACA;
      border-left: 0.659px solid #CACACA;
      background: #FFF;
      padding: 15px;
}

.you-may-img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      min-height: 243px;
      border-radius: 8px 8px 0px 0px;
      max-height: 243px;
      display: flex;
}


.you-may-img-box {
      position: relative;
}

.you-may-img-box .you-may-product-name {
      color: #FFF;
      font-family: "Inter";
      font-size: 19px;
      font-weight: 500;
      line-height: 25px;
      position: absolute;
      bottom: 19px;
      left: 16px;
      display: -webkit-box;
      max-width: 100%;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      min-height: 25px;
}
.you-may-overlay{
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0.48%, rgba(0, 0, 0, 0.44) 81.91%);
      height: 100%;
      width: 100%;
      position: absolute;
      left: 0px;
      top: 0px;
}

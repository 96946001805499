.main-blogs-details {
      background: #F5F5F5;
}

.blogs-details-content-main {
      margin: 40px 0px 0px 0px;
}

.blog-details-image {
      width: 100%;
      max-width: 868px;
      height: 516px;
      object-fit: cover;
      margin-bottom: 47px;
}

.blog-details-img-flex {
      display: flex;
}

.blog-details-left-main {
      flex: 1;
}

.blog-details-right-main {
      max-width: 348px;
      flex: 0 0 348px;
      width: 100%;
      margin-left: 68px;
}

.blog-search-main {
      margin-bottom: 23px;
}

.blog-search-main .search-white-control {
      width: 100%;
}

.blog-search-main .search-white-control input {
      background-color: #fff;
      padding: 21px 44px;
}

.blog-search-main .search-white-control fieldset {
      border: 0px;
}

.blog-search-main .blog-search-title {
      margin-bottom: 10px;
      color: #000;
      font-family: "Inter";
      font-size: 20px;
      font-weight: 400;
      line-height: 26px;
}

.blogs-keyword-main {
      padding: 16px 26px;
      background-color: #FFF;
}

.blogs-keyword-main .blog-keyword-title {
      color: #000;
      font-family: "Inter";
      font-size: 24px;
      font-weight: 400;
      line-height: 30px;
      margin-bottom: 20px;
}

.blogs-keyword-main .blog-keyword-ul {
      padding: 0px;
      margin: 0px;
      list-style-type: none;
}

.blog-keyword-ul .blog-keyword-li {
      margin-bottom: 20px;
      color: #000;
      font-family: "Inter";
      font-size: 20px;
      font-weight: 400;
      line-height: 26px;
      padding: 0px;
      width: fit-content;
      cursor: pointer;
}

.blog-keyword-ul .blog-keyword-li:last-child {
      margin-bottom: 0px;
}

.blog-keyword-ul .blog-keyword-li.blog-keyword-active {
      color: #27447C;
      border-bottom: 2px solid #27447C;
}

.blog-details-theory-content-main .blog-details-inner-titles {
      color: #000;
      font-family: "Inter";
      font-size: 36px;
      font-weight: 500;
      line-height: 44px;
      margin-bottom: 53px;
      max-width: 1087px;
      width: 100%;
      text-transform: uppercase;
}

.blog-details-theory-content-main .blog-details-para {
      margin-bottom: 19px;
      color: #000;
      font-family: "Inter";
      font-size: 24px;
      font-weight: 400;
      line-height: 30px;
}

.blog-details-para-flex {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 33px;
}

.blog-details-para-left-main {
      flex: 1;
}

.blog-details-img-right-main {
      max-width: 491px;
      width: 100%;
      flex: 0 0 491px;
      margin-left: 32px;
}

.blog-details-para-img {
      border-radius: 8px;
      width: 100%;
      height: 326px;
      max-width: 491px;
}

.blog-details-para-left-main .blog-details-para {
      margin: 19px 0px 33px;
}
.about-us-wrape {
    position: relative;
    margin-bottom: 90px;
}

.about-us-wrape::before,
.about-us-wrape::after {
    position: absolute;
    height: 100%;
    top: 0;
    content: "";
    z-index: -1;
}

.about-us-wrape::before {
    width: 50%;
    background: #27447C;
    left: 0;
}

.about-us-wrape::after {
    width: 50%;
    background: #F5F5F5;
    right: 0;
}

.about-us-wrape .inner-wrape {
    padding: 50px 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 80px;
}

.about-us-wrape .left-section h2 {
    color: #FFFFFF;
    font-size: 30px;
    line-height: 36px;
    font-weight: 600;
    margin-bottom: 50px;
    display: flex;
    align-items: center;
    gap: 36px;
}

.about-us-wrape .left-section p {
    color: #FFFFFF;
    font-size: 50px;
    line-height: 64px;
    font-weight: 600;
}

.about-us-wrape .right-section p {
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 40px;
}

.about-us-wrape .right-section .link {
    color: #27447C;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
    font-family: 'poppins';
    font-size: 16px;
    line-height: 20px;
}

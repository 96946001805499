@media (max-width: 1249px) {
    .testimonial-wrape .right-wrape {
        padding-left: 30px;
    }
}

@media (max-width: 1023px) {
    .testimonial-wrape {
        margin-bottom: 60px;
    }

    .testimonial-wrape h4 {
        font-size: 40px;
        line-height: 47px;
        margin-bottom: 30px;
    }

    .testimonial-wrape .crousel-wrape {
        padding: 40px 10px;
    }

    .testimonial-wrape .left-wrape,
    .testimonial-wrape .right-wrape {
        width: 100%;
    }

    .testimonial-wrape .right-wrape {
        padding-left: 0;
    }

    .testimonial-wrape p {
        font-size: 18px;
        line-height: 22px;
        font-weight: 400;
    }

    .testimonial-wrape .swiper-slide {
        flex-direction: column;
        gap: 20px;
    }

    .testimonial-wrape .left-wrape-img {
        min-width: 80px;
        min-height: 80px;
        max-width: 80px;
        max-height: 80px;
    }
}

@media (max-width: 767px) {
    .testimonial-wrape {
        margin-bottom: 40px;
    }

    .testimonial-wrape h4 {
        font-size: 30px;
        line-height: 37px;
        margin-bottom: 20px;
    }

    .testimonial-wrape .crousel-wrape {
        padding: 30px 10px;
    }

    .testimonial-wrape .crousel-wrape .swiper-button-prevs,
    .testimonial-wrape .crousel-wrape .swiper-button-nexts {
        position: unset;
        transform: unset;
        background-color: #ffffff50;
        border-radius: 50%;
    }

    .testimonial-wrape .crousel-wrape .control {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 20px;
        margin-top: 20px;
    }

    .testimonial-wrape .swiper-slide {
        padding: 0;
    }
}

@media (max-width: 549px) {
    .testimonial-wrape p {
        font-size: 16px;
        line-height: 22px;
    }

    .testimonial-wrape .left-wrape-img {
        min-width: 60px;
        min-height: 60px;
        max-width: 60px;
        max-height: 60px;
    }

    .testimonial-wrape .left-wrape h6 {
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 4px;
    }

    .testimonial-wrape .left-wrape span {
        font-size: 12px;
        line-height: 16px;
    }
}
/* start admin login css */

.admin-main .admin-login-main-flex {
    display: flex;
    height: 100vh;
    justify-content: center;
    align-items: center;
}

.admin-main .admin-login-left-main {
    width: 100%;
    max-width: 50%;
    flex: 0 0 50%;
    padding: 40px 90px;
    height: 100%;
}

.admin-main .admin-login-right-main {
    width: 100%;
    max-width: 50%;
    flex: 0 0 50%;
    padding: 40px 0;
    height: 100%;
    overflow: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.admin-main .login-left-bg {
    background-image: url(../../../assets/images/svg/brand.svg);
    background-size: 300px;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 100%;
}

/* .view-btn-main .view-order-btn {
    font-size: 12px;
    line-height: 18px;
    background-color: #27447C;
    color: #FFFFFF;
}

.view-btn-main .view-order-btn:hover {
    font-size: 12px;
    line-height: 18px;
    background-color: #27447C;
    color: #FFFFFF;
} */
.admin-main .admin-login-main {
    max-width: 390px;
    margin: auto;
    width: 100%;
}

.admin-main .login-btn-main .login-btn {
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100%;
}

.admin-main .checkbox-main .MuiFormControlLabel-root {
    color: #6B7280;
    margin-left: 0px;
    margin-right: 0px;
}

.admin-main .checkbox-main {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.admin-main .checkbox-main .MuiButtonBase-root {
    margin-right: 10px;
    padding: 0px;
}


.admin-main .checkbox-main .MuiFormControlLabel-label {
    font-size: 14px;
    line-height: 21px;
}

.admin-main .admin-login-inner .admin-wel-text {
    color: #111827;
    font-size: 24px;
    line-height: 25px;
    font-weight: 700;
    margin-bottom: 4px;
}



.admin-main .admin-login-inner .admin-sign-para {
    margin-bottom: 32px;
    color: #6B7280;
}

.admin-main .input-box .form-lable {
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0px;
    font-weight: 500;
    margin-bottom: 6px;
    margin-top: 0px;
    color: #6B7280;
}

.admin-main .input-box {
    margin-bottom: 28px;
}

.admin-main .admin-forgot-para {
    color: #114A65;
    text-align: right;
}

.admin-main .input-box .MuiInputBase-root {
    width: 100%;
}

.admin-main .input-box .MuiInputBase-root fieldset:hover {
    border: 1px solid #D1D5DB;
    border-radius: 2px;
}

.admin-main .input-box .form-control-eye input {
    padding: 8px 15px;
}

.admin-main .forgot-row {
    display: flex;
    justify-content: space-between;
}

.admin-main .login-btn-main .disabled-btn:disabled {
    color: white;
    cursor: not-allowed;
}

.admin-main .login-btn-main .otp-btn:disabled {
    color: #decfce;
    cursor: not-allowed;
}

/* input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
} */
/* end admin login css */


/* start or text design */

.admin-main .or-text {
    text-align: center;
    color: #6B7280;
    font-size: 14px;
    line-height: 21px;
    font-weight: 500;
    margin: 30px 0px 0px;
    position: relative;
    max-width: max-content;
    background-color: #fff;
}

.admin-main .condition-content {
    text-align: center;
}

.admin-main .condition-content .condition-text {
    color: #6B7280;
    font-size: 14px;
    line-height: 21px;
}

.admin-main .condition-content .condition-span {
    color: #114a65;
    font-size: 14px;
    line-height: 21px;
    font-weight: 500;
    text-decoration: none;
}

.admin-main .or-text-main {
    position: relative;
    margin: 30px 0px;
}

.admin-main .or-text-main .or-cocntent-text {
    background: #fff;
    margin: auto;
    z-index: 9;
    position: relative;
    text-align: center;
    width: 44px;
    color: #6B7280;
    font-size: 14px;
    line-height: 21px;
    font-weight: 500;
}

.admin-main .or-text-main::after {
    content: '';
    width: 100%;
    height: 1px;
    background: #d3d5d6;
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
}

/* end or text design */
@media (max-width: 1023px) {
    .main-catalogue .catalogue-wrapper {
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
        margin-bottom: 60px;
    }
}

@media (max-width: 767px) {
    .main-catalogue .catalogue-title {
        margin: 30px 0;
        font-size: 24px;
        line-height: 30px;
    }
}

@media (max-width: 549px) {
    .main-catalogue .catalogue-title {
        margin: 20px 0;
    }

    .main-catalogue .catalogue-wrapper {
        grid-template-columns: repeat(1, 1fr);
        margin-bottom: 40px;
    }
}
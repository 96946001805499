@media (max-width: 1024px) {
    .the-team {
        margin-bottom: 60px;
    }

    .the-team .the-team-name-box .the-team-name-item {
        font-size: 20px;
        line-height: 26px;
    }

    .the-team .the-team-left {
        max-width: 250px;
    }

    .the-team .the-team-name-box .the-team-name-item:not(:last-child) {
        padding-bottom: 15px;
        margin-bottom: 15px;
    }

    .the-team .the-team-right .team-member-img1 {
        width: 126px;
        height: 131px;
    }
    
    .the-team .the-team-right .team-member-img2 {
        width: 204px;
        height: 151px;
    }
    
    .the-team .the-team-right .team-member-img3 {
        width: 160px;
        height: 166px;
    }
    
    .the-team .the-team-right .team-member-img4 {
        width: 239px;
        height: 116px;
    }
    
    .the-team .the-team-right .team-member-img5 {
        width: 376px;
        height: 119px;
    }
}

@media (max-width: 767px) {
    .the-team {
        flex-direction: column;
        gap: 30px;
        margin-bottom: 30px;
    }

    .the-team .the-team-left {
        max-width: 100%;
    }

    .the-team .the-team-left .the-team-title {
        font-size: 30px;
        line-height: 36px;
        margin-bottom: 20px;
    }
}

@media (max-width: 549px) {
    .the-team .the-team-right .the-team-img-top {
        flex-direction: column;
    }

    .the-team .the-team-right .the-team-img-top .the-team-img-top-left {
        align-items: flex-start;
    }

    body .the-team .the-team-right .team-member-img {
        width: 100%;
        height: 100%;
    }
}
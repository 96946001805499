@media (max-width: 1023px) {
      .body-overflow {
            overflow: hidden;
      }

      .admin-main .common-edit-image-box {
            gap: 16px;
      }

      .admin-main .admin-sidebar-logo-main .sidebar-close-btn {
            display: block;
      }

      .admin-main .admin-header-main.admin-header-deactive+.dashboard-containt-main,
      .admin-main .dashboard-containt-main {
            padding: 88px 15px 24px 15px;
      }

      .admin-main .admin-sidebar-logo-main {
            display: flex;
            justify-content: space-between;
            min-height: 60px;
      }

      .admin-main .close-icon {
            height: 23px;
            width: 23px;
            object-fit: contain;
            display: block;
      }

      .admin-main .admin-header-main.active .admin-sidebar-logo {
            opacity: 1;
            display: block;
      }

      .admin-main .admin-header-main .admin-bergur-button::after {
            -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
            background-color: rgba(0, 0, 0, .2);
            content: "";
            height: 100%;
            opacity: 0;
            overflow: hidden;
            position: fixed;
            right: 0;
            top: 0;
            transition: all .3s ease-in-out;
            visibility: hidden;
            width: 100%;
            z-index: 3;
      }

      .admin-main .admin-header-main.active .admin-bergur-button::after {
            opacity: 1;
            overflow: auto;
            visibility: visible;
      }

      .admin-main .admin-header-main {
            left: 0;
            padding: 15px;
      }
}


@media (max-width: 767px) {

      .admin-main .admin-dashboard-inner-box {
            max-width: 100%;
      }

      .admin-main .dashboard-content .admin-page-title {
            font-size: 20px;
            line-height: 25px;
      }

      .admin-main .admin-dashboard-inner-box .admin-dash-price {
            font-size: 20px;
            line-height: 25px;
      }


      .admin-main .export-btn-main .export-btn,
      .admin-main .export-btn-main .export-btn:hover {
            margin-right: 0px;
      }

      .admin-main .dashboard-content .user-list-page-title {
            margin-bottom: 20px;
      }

      .admin-main .bergurmenu-icon {
            min-width: 18px;
            min-height: 18px;
            max-width: 18px;
            max-height: 18px;
            object-fit: contain;
      }

      .admin-main .admin-header-profile-icon {
            min-width: 30px;
            min-height: 30px;
            max-width: 30px;
            max-height: 30px;
      }

      .admin-main .admin-header-right .admin-header-icon-box {
            margin-right: 15px;
      }

      .admin-main .admin-header-row .bergur-button {
            padding: 0px;
            min-width: 37px;
      }

      .admin-main .admin-sidebar-logo {
            max-width: 100px;
      }

      .admin-main .filter-main .MuiDrawer-paper {
            min-width: 300px;
            max-width: 300px;
      }

      .admin-main .progress-bar-text {
            right: 0px;
            top: -23px;
      }

      .admin-main .header-sidebar-logo {
            order: 2;
      }

      .admin-main .modal .modal-inner {
            max-width: 350px;
      }
}

@media (max-width: 549px) {
      .admin-main .comman-view-detail-group {
            flex-direction: column;
            gap: 5px;
      }

      .admin-main .dashboard-left-main {
            max-width: 250px;
      }
}

@media (max-width: 374px) {
      .admin-main .common-edit-image-box .common-edit-image {
            max-width: 100%;
      }
}
@media (max-width: 1023px) {
    .key-feature-wrape {
        margin-bottom: 60px;
        padding: 30px 0;
    }

    .key-feature-wrape .key-feature-title {
        font-size: 40px;
        line-height: 47px;
        margin-bottom: 10px;
    }

    .key-feature-wrape .crousel-wrape {
        padding: 40px 0 30px 15px;
    }
}

@media (max-width: 767px) {
    .key-feature-wrape {
        margin-bottom: 40px;
    }

    .key-feature-wrape .key-feature-title {
        font-size: 30px;
        line-height: 37px;
        margin-bottom: 5px;
    }

    .key-feature-wrape .crousel-wrape {
        padding: 30px 0 20px 15px;
    }

    .key-feature-wrape .slide-controller {
        gap: 10px;
    }

    .key-feature-wrape .slide-controller img {
        width: 30px;
        height: 30px;
    }
}

@media (max-width: 549px) {
    .key-feature-wrape .white-box {
        padding: 15px;
    }

    .key-feature-wrape .white-box img {
        margin-bottom: 10px;
        width: 50px;
        height: 50px;
    }

    .key-feature-wrape .key-feature-title {
        font-size: 24px;
        line-height: 30px;
    }
}
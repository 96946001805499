.join-us-wrape{
    margin-bottom: 60px;
}
.join-us-wrape .inner-wrape {
    display: flex;
}

.join-us-wrape .big-size {
    width: 40%;
    position: relative;
    display: flex;
}

.join-us-wrape .small-size {
    width: 30%;
    position: relative;
    display: flex;
}

.join-us-wrape .content {
    width: 100%;
    padding: 30px;
    position: absolute;
    height: 100%;
    color: #ffffff;
}

.join-us-wrape h3 {
    font-size: 32px;
    line-height: 38px;
    font-weight: 600;
    margin-bottom: 20px;
}

.join-us-wrape p {
    font-size: 16px;
    line-height: 23px;
    max-width: 360px;
}

.join-us-wrape p:not(:last-child){
    margin-bottom: 23px;
}

.join-us-wrape .join-img {
    width: 100%;
}

.join-us-wrape .content a {
    padding: 12px 25px;
    background: #ffffff;
    border-radius: 100rem;
    color: #27447C;
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
    position: absolute;
    bottom: 30px;
    right: 30px;
    display: inline-flex;
    align-items: center;
    gap: 15px;
}

.who-we-are {
    margin-bottom: 90px;
}

.who-we-are .top-section{
    padding: 40px 0;
    display: flex;
    align-items: center;
    gap: 90px;
}

.who-we-are .top-section .title{
    font-weight: 700;
    font-size: 50px;
    line-height: 57px;
    color: #27447C;
    width: 100%;
    max-width: 177px;
}

.who-we-are .top-section .content{
    font-weight: 500;
    flex: 1;
}

.who-we-are .image-section{
    display: flex;
}
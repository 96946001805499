@media (max-width: 767px) {
    .latest-posts {
        padding: 30px 0;
    }

    .latest-posts .latest-posts-title {
        font-size: 30px;
        line-height: 36px;
        margin-bottom: 20px;
    }

    .latest-posts .latest-posts-bottom {
        flex-direction: column;
        gap: 30px;
    }

    .latest-posts .latest-posts-bottom .latest-posts-left,
    .latest-posts .latest-posts-bottom .latest-posts-right {
        width: 100%;
    }

    .latest-posts .latest-posts-bottom .latest-posts-left .blog-image-box {
        margin-bottom: 20px;
    }

    .latest-posts .latest-posts-bottom .latest-posts-left .posts-title {
        font-size: 24px;
        line-height: 30px;
        margin-bottom: 10px;
    }

    .latest-posts .latest-posts-bottom .latest-posts-left .posts-content {
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 20px;
    }

    .latest-posts .latest-posts-bottom .latest-posts-left .blog-video-play {
        width: 50px;
        height: 50px;
    }

    .latest-posts .latest-posts-bottom .latest-posts-left .posts-btn {
        padding: 8px 16px;
    }

    .latest-posts .latest-posts-bottom .latest-posts-right .blog-image {
        margin-bottom: 10px;
    }

    .latest-posts .latest-posts-bottom .latest-posts-right .posts-content {
        font-size: 16px;
        line-height: 20px;
    }

    .latest-posts .latest-posts-bottom .latest-posts-right .latest-posts-item:not(:last-child) {
        margin-bottom: 30px;
    }
}
@media (max-width: 767px) {

    .my-address-title-box {
        padding: 20px 15px;
    }

    .my-address-btn-flex .my-address-change-text {
        font-size: 14px;
        line-height: 18px;
        margin-right: 15px;
    }

    .my-address-content-row {
        padding: 20px 15px 0;
    }

    .my-address-input-main {
        margin-bottom: 30px;
    }
}

@media (max-width: 549px) {
    .my-address-title-flex {
        flex-direction: column;
        align-items: flex-start;
    }

    .my-address-btn-flex {
        width: 100%;
        justify-content: space-between;
    }
}
@media (max-width: 1199px) {
    .header-right-main {
        gap: 15px;
    }

    .header-search-box {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #c9c9c9;
        border-radius: 50%;
        padding: 5px;
    }

    .header-search-box .form-control,
    .header-right-main .auth-wrape .header-login-text {
        display: none;
    }

    .header-search-icon {
        position: unset;
        transform: unset;
        width: 20px;
        height: 20px;
    }

    .header-right-main .auth-wrape {
        justify-content: center;
        background-color: #27447C;
        border-radius: 50%;
        padding: 5px;
    }

    .header-right-main .auth-wrape img {
        width: 20px;
        height: 20px;
        filter: brightness(100);
    }
}

@media (max-width: 1023px) {
    .header-cust-col .mobile-menu-btn {
        display: block;
    }

    .header-cust-col .mobile-menu-btn::after {
        content: "";
        position: fixed;
        z-index: 3;
        right: 0;
        top: 0;
        width: 100%;
        height: 100%;
        -webkit-backdrop-filter: blur(5px);
        backdrop-filter: blur(5px);
        background-color: rgba(0, 0, 0, 0.2);
        opacity: 0;
        visibility: hidden;
        overflow: hidden;
        transition: 0.3s all ease-in-out;
    }

    .header-cust-col .mobile-menu-btn.open::after {
        opacity: 1;
        visibility: visible;
        overflow: auto;
    }

    .header-main .header-cust-col.navigation {
        position: fixed;
        top: 0;
        right: -100%;
        background: #27447C;
        height: 100vh;
        width: 100%;
        max-width: 340px;
        z-index: 4;
        overflow: auto;
        transition: 0.5s all ease-in-out;
    }

    .header-main.open .header-cust-col.navigation {
        right: 0;
    }

    .header-nav-ul {
        flex-direction: column;
        align-items: flex-start;
        gap: 0;
        padding: 30px 20px;
    }

    .header-nav-li {
        width: 100%;
        border-bottom: 1px solid #f5f5f5;
    }

    .header-nav-link:hover {
        color: #fff;
    }

    .header-nav-li:first-child {
        border-top: 1px solid #f5f5f5;
    }

    .header-nav-link {
        color: #fff;
        padding: 30px 10px;
        display: block;
        font-weight: 400;
    }

    .footer-logo-box .footer-logo-Link .footer-logo {
        width: 170px;
    }

    .footer-col .social-wrape {
        gap: 15px;
    }

    .footer-col .app-store-box {
        flex-direction: column;
    }

    .footer-col .app-store-box .app-store-link {
        width: auto;
    }

    .footer-logo-box{
        margin-bottom: 30px;
    }

    .footer-row {
        padding: 40px 0;
    }
}

@media (max-width: 767px) {
    .header-logo-box .header-logo {
        width: 130px;
    }

    .header-nav-ul {
        padding: 30px 15px;
    }

    .header-nav-link {
        padding: 20px 10px;
    }

    .header-main-inner {
        padding: 15px;
    }

    .footer-col-box {
        grid-template-columns: 1fr;
        gap: 40px;
    }

    .footer-logo-box .footer-logo-Link .footer-logo {
        width: 130px;
    }

    .footer-row {
        padding: 30px 0;
    }

    .footer-logo-box {
        margin-bottom: 20px;
    }

    .footer-col .footer-contact {
        margin-top: 10px;
    }

    .footer .footer-nav-titles {
        font-size: 18px;
        line-height: 22px;
        font-weight: 600;
        margin-bottom: 10px;
    }

    .footer-nav-main .footer-nav-ul {
        gap: 8px;
    }

    .footer-col .footer-address {
        line-height: 20px;
    }

    .footer-copy-right-box {
        padding: 15px 0px;
        font-size: 14px;
        line-height: 18px;
        justify-content: center;
    }

    .footer-col .social-wrape img {
        width: 24px;
        height: 24px;
    }

    .footer-col .app-store-box {
        flex-direction: row;
        margin-top: 20px;
    }
}

@media (max-width: 549px) {
    .header-main .header-cust-col.navigation {
        max-width: 250px;
    }
}
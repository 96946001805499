.sidebar-dash-details{
    padding: 0px 20px 40px 20px;
}

.sidebar-tabs-inner-list .sidebar-tabs-link {
    display: flex;
    align-items: center;
    gap: 20px;
    cursor: pointer;
    /* width: 100%; */
}

.sidebar-tabs-inner-list {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: space-between;
}

.sidebar-icon-tabs {
    height: 24px;
    width: 24px;
    min-width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sidebar-tabs-inner-list {
    padding: 23px 20px;
    border-bottom: 0.5px solid #EBEBEB;
    width: 100%;
}

.side-tab-title .dash-side-title {
    color: #333;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
}

.drop-side-tab-arrow .drop-btn-arrow{
    min-width: unset;
    padding: 0px;
}

.side-tab-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.sidebar-tab-custom .sidebardash-main-list {
    padding: 0px;
}

.sidebardash-main-list .sidebardash-main-listitem {
    padding: 0px;
    width: 100%;
    flex-direction: column;
}

.inner-side-tab-listing .sidebar-dash-inner-list {
    padding: 10px 0px 0px 55px;
    width: 100%;
}

.sidebar-dash-inner-list .sidebar-dash-inner-listitem {
    padding: 6.5px;
}

.inner-side-tab-listing {
    width: 100%;
}

.sidebar-dash-inner-listitem .inner-refrence-sidelink {
    color: #4E4E4E;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
}

.sidebardash-main-list .sidebardash-main-listitem.active svg path {
    fill: #EE682B;
}

.sidebardash-main-list .sidebardash-main-listitem.active .dash-side-title {
    color: #EE682B;
}
.sidebar-dash-inner-list .sidebar-dash-inner-listitem.active .inner-refrence-sidelink {
    color: #EE682B;
}
.order-list-number {
    background: #EB5757;
    max-width: 14px;
    max-height: 14px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
}

.order-list-number .num-show-order {
    color: #FFF;
    font-family: Inter;
    font-size: 9px;
    font-style: normal;
    font-weight: 300;
    line-height: 13px;
}
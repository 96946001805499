@media (max-width: 1249px) {}

@media (max-width: 1023px) {
    .culture-row {
        grid-template-columns: repeat(2, 1fr);
    }

    .career-inqure-row {
        grid-template-columns: repeat(2, 1fr);
    }

    .career-content-main {
        padding: 50px 0px 50px;
    }

    .career-content-main .career-para {
        margin-bottom: 30px;
        font-size: 16px;
        line-height: 24px;
    }

    .our-culture-title-box .our-culture-title {
        margin-bottom: 28px;
        font-size: 24px;
        line-height: 34px;
    }

    .culture-main-box .culture-title {
        margin-bottom: 20px;
        font-size: 20px;
        line-height: 25px;
    }

    .culture-main-box {
        padding: 20px;
    }


    .culture-main-box .culture-para {
        font-size: 16px;
        line-height: 24px;
    }

    .career-inqure-title-main .career-inqure-title {
        font-size: 20px;
        line-height: 28px;
        margin-bottom: 17px;
    }

    .our-culture-section {
        margin: 0px 0px 50px;
    }

    .career-banner-section {
        height: 285px;
    }

    .career-care-box .career-care-text {
        font-size: 20px;
    }

    .career-care-box {
        padding: 0 30px;
        height: 40px;
    }

    .career-care-box::after {
        border-width: 20px;
    }
}

@media (max-width: 767px) {
    .career-content-main {
        padding: 30px 0px;
    }

    .culture-row {
        grid-template-columns: repeat(1, 1fr);
    }

    .career-inqure-row {
        grid-template-columns: repeat(1, 1fr);
    }
}

@media (max-width: 549px) {
    .career-banner-section {
        height: 225px;
    }

    .career-care-box .career-care-text {
        font-size: 16px;
    }

    .career-care-box {
        padding: 0 15px;
        height: 30px;
    }

    .career-care-box::after {
        border-width: 15px;
    }
}
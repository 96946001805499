.upload-po-details .enter-details-main {
    display: flex;
    gap: 40px;
    margin-bottom: 40px;
}

.upload-po-details .enter-details-main .comman-form-group {
    width: 100%;
}

.upload-po-details .enter-details-main .comman-form-group .comman-form-label {
    font-size: 20px;
    line-height: 26px;
    font-weight: 500;
}

.product-preview-modal.modal-common-main .modal-common-style {
    max-width: 1084px;
}

.product-preview-modal .product-preview-top {
    display: flex;
    gap: 20px;
    margin-bottom: 40px;
}

.product-preview-modal .product-preview-top .product-preview-img {
    width: 240px;
    height: 170px;
    object-fit: cover;
    border-radius: 20px;
}

.product-preview-modal .product-preview-top .product-preview-title {
    font-weight: 600;
    max-width: 310px;
    margin-bottom: 25px;
}

.product-preview-modal .product-preview-top .product-preview-content {
    color: #808080;
    line-height: 24px;
}

.product-preview-modal .product-preview-bottom {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 40px;
}

.product-preview-modal .product-preview-bottom .product-Supplier-list {
    padding: 0;
    list-style: disc;
    padding-left: 20px;
}

.product-preview-modal .product-preview-bottom .product-Supplier-list-item {
    display: list-item;
    padding: 0;
    line-height: 24px;
}

.product-preview-modal .product-preview-bottom .product-Supplier-list-item .Supplier-amount-label {
    color: #8B8B8B;
    font-size: 14px;
    font-weight: 500;
}

.product-preview-modal .product-preview-bottom .product-Supplier-list-item .Supplier-amount-value {
    color: #11A64A;
    font-size: 14px;
    font-weight: 500;
}
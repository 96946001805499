@media (max-width: 1023px) {
    .numbers-talk {
        margin-bottom: 60px;
    }

    .numbers-talk .numbers-item-box .numbers-item .number {
        font-size: 30px;
        line-height: 36px;
        margin-bottom: 0;
    }

    .numbers-talk .numbers-item-box .numbers-item .label {
        font-size: 16px;
        line-height: 20px;
    }
}

@media (max-width: 767px) {
    .numbers-talk {
        padding: 30px 15px 40px;
        margin-bottom: 30px;
    }

    .numbers-talk .numbers-item-box .numbers-item .number {
        font-size: 24px;
        line-height: 30px;
    }

    .numbers-talk .numbers-item-box .numbers-item .label {
        font-size: 14px;
        line-height: 18px;
    }

    .numbers-talk .title {
        font-size: 30px;
        line-height: 36px;
        margin-bottom: 10px;
    }

    .numbers-talk .content {
        margin: 0 auto 40px;
    }
}

@media (max-width: 549px) {
    .numbers-talk {
        padding: 30px 15px 30px;
    }

    .numbers-talk .numbers-item-box {
        grid-template-columns: repeat(2, 1fr);
        row-gap: 20px;
    }

    .numbers-talk .numbers-item-box .numbers-item:nth-child(2) {
        border: 0;
    }

    .numbers-talk .title {
        font-size: 24px;
        line-height: 30px;
    }

    .numbers-talk .content {
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 30px;
    }
}
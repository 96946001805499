.admin-main .grid-main .grid-item {
  background-color: transparent;
  box-shadow: none;
  border-radius: 0px;
  padding: 0px;
}

.admin-main .text-decoration-none {
  text-decoration: none !important;
}

.admin-main .flex-all {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
}

.admin-main .common-grid-main {
  padding: 30px 0px 30px;
  border-top: 1px solid #eaeaea;
}

.admin-main .cust-dropdown-bg {
  border: 1px solid #CACACA;
}

.admin-main .cust-dropdown-bg .MuiSelect-multiple {
  padding: 11px 10px;
  min-height: unset;
  font-size: 14px;
  line-height: 18px;
}

.admin-main .cust-dropdown-bg .MuiSelect-multiple .MuiListItemText-root {
  margin: 0;
}

.admin-main .cust-dropdown-bg .MuiSelect-multiple .MuiTypography-root {
  font-size: 14px;
  line-height: 18px;
}

.admin-main .table-download-icon {
  padding: 5px;
  border-radius: 50%;
  min-width: unset;
  background-color: #f5f5f5;
}

.admin-main .table-download-icon .download-icon {
  margin: 0;
}

.admin-main .common-table-img-box {
  display: flex;
}

.admin-main .common-table-img-box .common-table-img {
  width: 150px;
  height: 75px;
  object-fit: cover;
  border-radius: 8px;
}

.admin-main .common-table-para {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.admin-main .common-tab-button{
  margin-bottom: 30px;
  background-color: #f5f5f5;
  border-radius: 4px;
}

.admin-main .common-tab-button button{
  font-weight: 700;
  text-transform: capitalize;
}

/* start input field design css */

.admin-main .product-select-qty {
  display: inline-flex;
  border-radius: 8px;
  border: 1px solid #cacaca;
}

.admin-main .product-select-qty .MuiInput-root::before,
.admin-main .product-select-qty .MuiInput-root::after {
  display: none;
}

.admin-main .product-select-qty .MuiInput-input {
  height: 38px;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  border-left: 1px solid #cacaca;
  border-right: 1px solid #cacaca;
  text-align: center;
}

.admin-main .product-select-qty-btn {
  padding: 10px 20px;
  height: 38px;
  min-width: unset;
  border-radius: 0;
  color: #000;
  font-size: 24px;
}

.admin-main .form-group .form-control input {
  /* border: 1px solid #D1D5DB; */
  border-radius: 4px;
  width: 100%;
  padding: 10px 15px;
  background-color: transparent;
  color: #000;
  font-family: "Inter";
  font-size: 14px;
  line-height: 18px;
}

.admin-main .form-group .form-control input::placeholder {
  color: #cacaca;
  font-family: "Inter";
  font-size: 14px;
  line-height: 18px;
  opacity: 1;
}

.admin-main .form-group .form-control .Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: transparent;
  border-width: 0px;
}

.admin-main .form-control fieldset {
  border: 1px solid #d1d5db !important;
  border-radius: 8px;
}

.admin-main .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: #d1d5db !important;
  border-width: 1px;
}

.admin-main .form-control-textarea:focus-visible {
  outline: none !important;
}

.admin-main .common-edit-image-box {
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
}

.admin-main .common-edit-image-box .common-edit-image {
  display: flex;
  position: relative;
  width: 100%;
  max-width: 150px;
}

.admin-main .common-edit-image-box .common-edit-image .product-img {
  width: 100%;
  height: 100%;
  min-height: 120px;
  max-height: 120px;
  border-radius: 8px;
  object-fit: cover;
}

.admin-main .common-edit-image-box .common-edit-image .product-img-close {
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 2px;
  cursor: pointer;
  width: 20px;
  height: 20px;
  background-color: #f5f5f5;
  border-radius: 50%;
  z-index: 1;
}

.admin-main .add-product .upload-img-file-modal .upload-label-details,
.admin-main .add-product .upload-img-file-modal .upload-label-details:hover,
.admin-main .add-product .upload-img-file-modal .upload-label-details:focus {
  height: 100%;
  padding: 15px;
}

.admin-main .add-product .upload-main-content .upload-cloud {
  max-width: 50px;
  height: auto;
  margin: 0;
}

.admin-main .add-product .upload-img-file-modal .upload-label-details .browse-cloud-text {
  font-size: 16px;
  line-height: 20px;
}

.admin-main .comman-view-detail-group {
  display: flex;
  gap: 10px;
}

.admin-main .comman-view-detail-group.title-devider {
  font-size: 20px;
  line-height: 1;
  font-weight: 600;
}

.admin-main .comman-view-detail-group .comman-view-detail-label {
  color: #808080;
  font-size: 14px;
  line-height: 18px;
  min-width: fit-content;
  padding-top: 2px;
}

.admin-main .comman-view-detail-group .comman-view-detail-value {
  font-weight: 500;
}

.admin-main .download-pdf-box {
  display: flex;
  gap: 10px;
}

.admin-main .download-pdf-box .download-pdf-btn {
  padding: 0;
  min-width: unset;
}

.admin-main .download-pdf-box .download-pdf-btn img {
  width: 24px;
  height: 24px;
}

.admin-main .order-status-drop-main .input-box {
  margin: 0;
}

.admin-main .order-status-drop-main .dropdown-select .MuiSelect-select {
  font-size: 14px;
  line-height: normal;
}

.admin-main .invoice-box{
  text-align: center;
}

.admin-main .invoice-box img{
  box-shadow: 0 0 10px #00000050;
}

/* end input field design css */

.admin-main .page-border {
  border: 1px solid #d1d5db;
  border-radius: 6px;
  padding: 20px;
}

.admin-main .btn-main-primary .btn-primary,
.admin-main .btn-main-primary .btn-primary:hover {
  background-color: #114a65;
  border-radius: 4px;
  width: 100%;
  max-width: 380px;
  color: #ffffff;
  font-size: 14px;
  line-height: 20px;
  padding: 10px 15px;
  text-transform: capitalize;
}

.admin-main .border-btn-main .border-btn {
  border: 1px solid #d1d5db;
  border-radius: 4px;
  background-color: #ffffff;
  padding: 10px 15px;
  font-size: 12px;
  line-height: 18px;
  color: #6b7280;
  text-transform: capitalize;
}

.admin-main .common-para {
  font-size: 14px !important;
  line-height: 21px !important;
}

/* start switch css */

.admin-main .switch-main {
  margin-left: 20px;
}

.admin-main .switch-lable .MuiTypography-root {
  font-size: 14px;
  line-height: 21px;
  color: #6b7280;
}

/* end switch css */

/* start pagination css */

.admin-main .pagination-main {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 27px;
}

.admin-main .pagination-main .MuiPagination-ul {
  margin-right: 20px;
}

.admin-main .pagination-main .MuiPaginationItem-root {
  height: 20px;
  min-width: 20px;
  border-radius: 4px;
  padding: 0px;
  font-size: 12px;
  line-height: 18px;
  color: #6b7280;
  margin: 0 4px;
}

.admin-main .pagination-main .MuiPaginationItem-root.Mui-selected:hover {
  background-color: #114a65;
  color: #ffffff;
}

.admin-main .pagination-main .MuiPaginationItem-root.Mui-selected {
  background-color: #114a65;
  color: #ffffff;
}

/* end pagination css */

/* start progrss bar css */

.admin-main .progress-bar-main {
  max-width: 470px;
  width: 100%;
  position: relative;
}

.admin-main .progress-bar-main .progress-bar .MuiLinearProgress-bar {
  border-radius: 8px;
  background-color: #114a65;
}

.admin-main .progress-bar-content {
  margin-top: 30px;
}

.admin-main .progress-bar-content .admin-page-title {
  font-size: 20px;
  line-height: 30px;
  color: #111827;
  font-weight: 500;
}

.admin-main .progress-bar-text {
  position: absolute;
  right: -35px;
  top: -5px;
  font-size: 14px;
  line-height: 21px;
  color: #6b7280;
  font-weight: 500;
}

/* end progrss bar css */

/* start error text css */
.admin-main .error-text {
  color: red;
  font-size: 12px;
  line-height: 15px;
}

/* end error text css */

/* start dropdown css */

.admin-main .dropdown-box .form-control {
  width: 100%;
  position: relative;
}

/* .admin-main .dropdown-select .MuiSelect-select {
      color: #6B7280;
      font-size: 12px;
      line-height: 20px;
      display: flex;
      padding: 10px 15px;
      border-radius: 4px;
} */

/* .admin-main .grey-down {
      position: absolute;
      top: 17.92px;
      right: 21.92px;
      height: 10px;
      width: 10px;
      object-fit: contain;
} */

.admin-main .dropdown-box .MuiMenu-list .drop-menuitem {
  color: #6b7280;
  font-size: 12px;
  line-height: 20px;
}

/* / end dropdown css / */
.seller-quotations-main{
    margin: -20px 0;
}

.dashboard-bg-main .seller-qty {
    color: #bebebe;
}

.dashboard-bg-main .seller-date,
.dashboard-bg-main .value-added,
.dashboard-bg-main .enquiry-order {
    font-weight: 600;
}

.dashboard-bg-main .insert-value .comman-form-control input {
    text-align: center;
    background-color: #fafafa;
    color: #808080;
}

.dashboard-bg-main .insert-value .comman-form-control input::placeholder {
    color: #808080;
}

.dashboard-bg-main .table-row-action.seller {
    justify-content: space-between;
    align-items: center;
}

.dashboard-bg-main .table-row-action.seller .button-box {
    display: flex;
    gap: 12px;
}

.dashboard-bg-main .table-row-action.seller .button-box button {
    padding: 10px 40px;
}

.seller-quotation-table-container:not(:last-child) {
    margin-bottom: 30px;
}
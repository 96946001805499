@media (max-width: 1199px) {
    .main-product-detail .product-detail-wrapper {
        gap: 40px;
    }

    .main-product-detail .product-detail-wrapper .product-detail-left {
        max-width: 650px;
    }
}

@media (max-width: 1023px) {
    .main-product-detail .product-detail-wrapper {
        flex-direction: column;
    }

    .main-product-detail .product-detail-wrapper .product-detail-left {
        max-width: 100%;
    }

    .main-product-detail .product-detail-wrapper .product-detail-right {
        width: 100%;
    }
}

@media (max-width: 767px) {
    .main-product-detail .product-image-box {
        flex-direction: column-reverse;
    }

    .main-product-detail .product-image-box .product-image-thumb-box {
        flex-direction: row;
    }

    .main-product-detail .product-title-box .product-content-box {
        flex-direction: column;
        align-items: flex-start;
    }

    .main-product-detail .product-title-box .product-content-box .product-content {
        max-width: 100%;
    }

    .main-product-detail .product-image-box .product-image-thumb,
    .main-product-detail .product-image-box .product-image-more {
        width: 100px;
        height: 100px;
    }

    .main-product-detail .product-image-box .product-image-more svg {
        width: 24px;
        height: 24px;
    }

    .main-product-detail .product-detail-tab-bg {
        padding: 20px 0 30px;
    }

    .main-product-detail .product-detail-tab-box .product-detail-tab-btn {
        font-size: 18px;
        line-height: 22px;
        padding: 10px 15px;
    }

    .main-product-detail [aria-label="product-detail-tab-box"] {
        gap: 0;
    }

    .main-product-detail .product-detail-tab-wrapper .MuiTabs-indicator {
        height: 3px;
    }

    .main-product-detail .product-detail-tab-box {
        margin-bottom: 30px;
    }
}

@media (max-width: 549px) {
    .main-product-detail .product-detail-wrapper {
        gap: 30px;
        margin: 14px 0 30px;
    }

    .main-product-detail .product-title-box .product-title {
        font-size: 24px;
        line-height: 30px;
        margin-bottom: 10px;
    }

    .main-product-detail .product-title-box {
        margin-bottom: 20px;
    }

    .main-product-detail .product-title-box .product-review-box .product-review-star {
        font-size: 18px;
        line-height: 22px;
        gap: 5px;
    }

    .main-product-detail .product-title-box .product-review-box {
        gap: 10px;
    }

    .main-product-detail .product-title-box .product-review-box .product-review-star svg {
        width: 20px;
        height: 20px;
    }

    .main-product-detail .product-image-box .product-image-thumb-box {
        gap: 10px;
    }

    .main-product-detail .product-image-box .product-image-more svg {
        width: 16px;
        height: 16px;
    }

    .main-product-detail .product-image-box .product-image-thumb {
        width: 60px;
        height: 60px;
    }

    .main-product-detail .product-image-box .product-image-more {
        width: 60px;
        height: 60px;
        gap: 2px;
        font-size: 14px;
        line-height: 20px;
    }

    .main-product-detail .product-detail-tab-box .product-detail-tab-btn {
        font-size: 14px;
        line-height: 18px;
    }

    .main-product-detail .product-select-top {
        padding: 20px 15px;
    }

    .main-product-detail .product-select-top .product-select-qty .MuiInput-input {
        width: 60px;
    }

    .main-product-detail .product-detail-tab-wrapper .MuiTabs-indicator {
        height: 2px;
    }
}
@media (max-width:1023px) {
    .join-team .join-team-inner {
        max-width: 450px;
        padding: 40px;
    }

    .join-team .join-team-inner .join-team-content {
        margin-bottom: 30px;
    }
}

@media (max-width:767px) {
    .join-team .join-team-inner {
        max-width: 300px;
        padding: 30px 0;
        font-size: 20px;
        line-height: 26px;
    }

    .join-team .join-team-inner .join-team-title {
        margin-bottom: 20px;
    }

    .join-team .join-team-inner .join-team-content {
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 20px;
    }

    .join-team .join-team-inner .join-team-btn {
        padding: 10px 20px;
        gap: 10px;
    }
}

@media (max-width:767px) {
    .join-team::before {
        width: 100%;
    }

    .join-team{
        background-color: #ee682b;
    }

    .join-team .join-team-inner {
        max-width: 100%;
    }
    .join-team::after{
        display: none;
    }
}
@media (max-width: 1023px) {
    .main-faq .main-faq-wrapper {
        flex-direction: column;
    }

    .main-faq .main-faq-wrapper .sidebar-tab-desktop {
        width: 100%;
        position: unset;
        overflow: unset;
    }

    .main-faq .main-faq-wrapper .sidebar-tab-desktop .common-tab {
        display: none;
    }

    .main-faq .main-faq-wrapper .sidebar-tab-desktop .MuiTabs-flexContainer {
        margin-bottom: 0;
    }

    .main-faq .main-faq-wrapper .question-section .faq-search input {
        padding: 8px 8px 8px 40px;
        font-weight: 500;
        color: #808080;
    }

    .main-faq .main-faq-wrapper .question-section .faq-search-icon {
        left: 10px;
        width: 20px;
        height: 20px;
    }

    .main-faq .main-faq-wrapper .question-section .topic-item-wrapper {
        margin-top: 30px;
    }
}

@media (max-width: 767px) {
    .main-faq .main-faq-wrapper {
        padding: 30px 0;
        gap: 20px;
    }

    .main-faq .main-faq-wrapper .question-section .topic-item-wrapper {
        margin-top: 20px;
    }

    .main-faq .main-faq-wrapper .sidebar-tab-desktop .sidebar-tab-button button {
        padding: 10px 20px;
        font-size: 14px;
        line-height: 18px;
    }
}
.modal-common-main .modal-common-style.login-modal-inner-main {
  max-width: 423px;
}

.auth-modal-inner .auth-modal-title {
  color: #000;
  font-family: "Inter";
  font-size: 20px;
  font-weight: 600;
  line-height: 26px;
  text-align: center;
  margin: 0px auto 45px;
}

.auth-modal-inner .auth-forget-text {
  color: #000;
  font-family: "Inter";
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  cursor: pointer;
  margin: 10px 0px 37px;
}

.dont-para-main .dont-modal-para {
  color: #454545;
  font-family: "Inter";
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
}

.dont-para-main .auth-redirect-link {
  color: #0f5288;
  font-family: "Inter";
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  line-height: 20px;
}

.modal-auth-btn-main {
  margin-bottom: 30px;
  justify-content: center;
  display: flex;
}

.modal-auth-btn-main .orange-fill-btn-main,
.modal-auth-btn-main .orange-fill-btn-main .orange-fill-btn {
  width: 100%;
  max-width: 257px;
}

.modal-common-main .MuiBackdrop-root {
  background: rgba(0, 0, 0, 0.6);
}

.auth-radio-main {
  margin: 0px 0px 30px;
}

.auth-radio-main .radio-group-main .form-control-label {
  margin-left: 0px;
  margin-right: 20px;
}

.register-content-main {
  padding: 30px 0px 35px;
  border-top: 1px solid #eaeaea;
}

.register-content-main .terms-gst-checkbox {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.register-content-main .terms-gst-checkbox .MuiFormControlLabel-root {
  margin: 0;
}

.register-content-main .terms-gst-checkbox .MuiCheckbox-root {
  padding-left: 0;
  padding-top: 0;
  padding-bottom: 0;
}

.register-content-main .terms-gst-checkbox .MuiCheckbox-root .MuiSvgIcon-root {
  font-size: 18px;
}

.register-content-main .terms-gst-checkbox .MuiTypography-root {
  font-size: 14px;
  line-height: 18px;
}

.auth-content-main .register-title-main {
  color: #333;
  font-family: "Inter";
  font-size: 20px;
  font-weight: 500;
  line-height: 26px;
  margin-bottom: 20px;
}

.otp-gren-tick {
  min-width: 20px;
  min-height: 20px;
  max-width: 20px;
  max-height: 20px;
  border-radius: 50%;
}

.comman-form-group .comman-verify-link {
  position: absolute;
  right: 0;
  top: 100%;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: #0f5288;
  cursor: pointer;
}

.auth-input-main {
  position: relative;
}

.forgot-btn-footer {
  margin-top: 37px;
}

.forgot-btn-footer .modal-auth-btn-main {
  margin-bottom: 0px;
}

.cus-pass-field .form-control input {
  border: 0px;
}

.comman-form-group .cus-pass-field {
  margin-bottom: 0px;
}

.comman-otp-details .input-box {
  margin-bottom: 0px;
}

.comman-form-group .comman-verify-otp-link {
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: #0f5288;
  cursor: pointer;
}

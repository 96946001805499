.app-work-wrape {
    background: #fafafa;
    padding: 60px 0;
    margin-bottom: 60px;
}

.app-work-wrape h4 {
    font-size: 50px;
    line-height: 60px;
    font-weight: 500;
    color: #27447C;
    margin-bottom: 90px;
    text-align: center;
}

.app-work-wrape .flex-section {
    margin: 0 15px;
}

.app-work-wrape .middle-wrape {
    width: 100%;
    max-width: 1321px;
    margin: 0 auto;
}

.app-work-wrape .middle-wrape img {
    width: 100%;
}

.app-work-wrape .up-wrape,
.app-work-wrape .down-wrape {
    position: relative;
    display: flex;
    gap: 20px;
    justify-content: space-between;
}

.app-work-wrape .up-wrape {
    max-width: 1372px;
    margin: 0 auto;
}

.app-work-wrape .down-wrape {
    max-width: 1050px;
    width: 75%;
    margin: 0 auto;
    left: 50px;
}

.app-work-wrape .up-wrape .sec,
.app-work-wrape .down-wrape .sec {
    width: 100%;
    height: 100%;
    position: relative;
}

.app-work-wrape .sec h6 {
    font-size: 18px;
    line-height: 22px;
    font-weight: 600;
    color: #333333;
    margin: 10px 0;
}

.app-work-wrape .sec p {
    color: #333333;
    margin-bottom: 10px;
}

.app-work-wrape .up-wrape .sec.first {
    max-width: 203px;
    bottom: -150px;
}

.app-work-wrape .up-wrape .sec.second {
    max-width: 146px;
}

.app-work-wrape .up-wrape .sec.third {
    max-width: 179px;
    bottom: -150px;
}

.app-work-wrape .up-wrape .sec.fourth {
    max-width: 239px;
    bottom: -50px;
}

.app-work-wrape .up-wrape .sec.fifth {
    max-width: 145px;
    bottom: -170px;
}

.app-work-wrape .down-wrape .sec.first {
    max-width: 203px;
    top: -90px;
}

.app-work-wrape .down-wrape .sec.second {
    max-width: 203px;
}

.app-work-wrape .down-wrape .sec.third {
    max-width: 269px;
    top: -80px;
}

.app-work-wrape .down-wrape .sec.fourth {
    max-width: 203px;
}
@media (max-width: 767px) {
      .notification-section .notification-title-box {
            padding: 20px 15px;
      }

      .notification-section .notification-content-main {
            padding: 20px 15px 20px;
      }

      .notification-section .notification-content-main .notification-content-box {
            gap: 10px;
            padding: 15px;
      }
      .notification-section .notification-pagination .notification-pagination-btn {
            width: 32px;
            height: 32px;
            border: 1px solid #DEE2E7;
        }
}
.my-address-title-box {
      background: #FFF;
      box-shadow: 0px 0px 12px 0px rgba(126, 133, 148, 0.08);
      padding: 30px 20px;
      margin-bottom: 10px;
}

.my-address-title-flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 10px;
}

.my-address-title-flex .my-address-title {
      color: #27447C;
      font-family: "Poppins";
      font-size: 20px;
      font-weight: 500;
      line-height: 24px;
}

.my-address-btn-flex {
      display: flex;
      align-items: center;
}

.my-address-btn-flex .my-address-change-text {
      margin-right: 27px;
      color: #EE682B;
      font-family: "Inter";
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      cursor: pointer;
}

.my-address-input-main .comman-form-control {
      width: 100%;
}

.my-address-content-row {
      padding: 30px 20px 0;
}

.my-address-input-main {
      margin-bottom: 40px;
}
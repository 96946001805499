.career-banner-section {
      height: 630px;
      width: 100%;
      background-repeat: no-repeat;
      background-size: cover;
      position: relative;
      background-image: linear-gradient(129deg, rgb(38 9 27 / 50%) 100%, rgb(38 9 27 / 50%) 0%), url(../../../../assets/images/png/career.png);
}


.career-care-box {
      background-color: #000;
      padding: 0 40px;
      height: 80px;
      position: relative;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      left: 0;
      bottom: 0;
}

.career-care-box .career-care-text {
      color: #FFF;
      text-align: center;
      font-size: 44px;
      line-height: 1;
}

.career-care-box::after {
      content: "";
      position: absolute;
      top: 0;
      left: 100%;
      border-bottom: solid 40px #000;
      border-left: solid 40px #000;
      border-right: solid 40px transparent;
      border-top: solid 40px transparent;
}

.career-content-main .career-para {
      color: #333;
      text-align: center;
      font-family: "Inter";
      font-size: 20px;
      font-weight: 400;
      line-height: 26px;
      margin-bottom: 105px;
}

.career-content-main {
      padding: 103px 0px 77px;
}

.main-career {
      background: #F5F5F5;
}

.our-culture-title-box .our-culture-title {
      margin-bottom: 67px;
      color: #000;
      font-family: "Inter";
      font-size: 40px;
      font-weight: 600;
      line-height: 47px;
      text-align: center;
}

.culture-row {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 30px;
}

.culture-main-box {
      background: #F1F8FF;
      box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.12);
      padding: 40px 20px;
      width: 100%;
}

.culture-main-box .culture-title {
      margin-bottom: 30px;
      color: var(--Primary-1, #27447C);
      text-align: center;
      font-family: "Inter";
      font-size: 32px;
      font-weight: 600;
      line-height: 40px;
      display: -webkit-box;
      max-width: 100%;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
}

.our-culture-section {
      margin: 0px 0px 77px;
}

.culture-main-box .culture-para {
      color: #333;
      text-align: center;
      font-family: "Inter";
      font-size: 20px;
      font-weight: 400;
      line-height: 26px;
      display: -webkit-box;
      max-width: 100%;
      -webkit-line-clamp: 6;
      -webkit-box-orient: vertical;
      overflow: hidden;
}

.career-inqure-title-main .career-inqure-title {
      color: #000;
      text-align: center;
      font-family: "Inter";
      font-size: 36px;
      font-weight: 400;
      line-height: 42px;
      margin-bottom: 35px;
}

.career-inqure-row {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      column-gap: 40px;
      row-gap: 20px;
}

.career-inqure-main-box {
      border: 1px solid #27447C;
      padding: 14px 45px;
      min-height: 64px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
}

.career-inqure-main-box .career-inqure-lables {
      color: #27447C;
      text-align: center;
      font-family: "Inter";
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: 3.1px;
      text-transform: uppercase;
      display: -webkit-box;
      max-width: 100%;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
}
.dashboard-bg-main {
    background-color: #FAFAFA;
    padding: 30px 0px;
}

/* .bg-tab-main {
    background-color: #fff;
} */
.dashboard-tab-col1, .dashboard-tab-col2{
    background-color: #fff;
}

.dashboard-tab-row {
    display: flex;
    flex-wrap: wrap;
}

.dashboard-tab-col1 {
    width: 24%;
}

.dashboard-tab-col2 {
    width: 76%;
    border-left: 1px solid #EBEBEB;
    padding-bottom: 40px;
}

.dash-tabs-content .dash-tab-inner-details {
    display: flex;
    flex-direction: row;
    justify-content: start;
    color: #333;
    text-transform: capitalize;
    font-size: 16px;
    line-height: 20px;
    min-height: 70px;
    padding: 15px 20px;
    border-bottom: 0.5px solid #EBEBEB;
    width: 100%;
}
.dash-tabs-content .dash-tab-inner-details:last-child{
    border-bottom: 0px;
}

.dash-tabs-content .dash-tab-inner-details .user-dash-icons {
    margin-bottom: 0px;
    margin-right: 20px;
    display: flex;
}

.dash-tabs-content {
    padding: 0px 20px;
}

.dash-tabs-content .dash-tab-inner-details.Mui-selected {
    color: #EE682B;
}

.dash-tabs-content .dash-tab-inner-details.Mui-selected .user-dash-icons svg path{
    fill: #EE682B;
}

.dash-tabs-content .MuiTabs-indicator {
    display: none;
}

.drop-with-tab-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.drop-with-tab-content .drop-btn-arrow {
    min-width: initial;
    padding: 0px;
}

.qutation-title-tab{
    display: flex;
}

.drop-list-tab-dash{
    width: 100%;
}

.quatation-list-main .quatation-listing-main {
    padding: 29.5px 0px 0px 0px;
}

.quatation-listing-main .quatation-listitem-details {
    padding: 0px 0px 15px 58px;
}

.quatation-listitem-details .quatation-listing-redirect {
    font-size: 16px;
    color: #4E4E4E;
}
@media (max-width: 1249px) {}

@media (max-width: 1023px) {}

@media (max-width: 767px) {
    .product-review-grid-6 {
        grid-template-columns: repeat(1, 1fr);
        margin-top: 20px;
    }

    .po-number-listing {
        margin-bottom: 5px;
    }

    .invoice-details-po {
        justify-content: space-between;
    }
}

@media (max-width: 549px) {
    .radio-group-main .form-control-label {
        margin-bottom: 10px;
    }

    .delete-details {
        max-width: 19px;
        height: 20px;
    }

    .file-icon-name .file-payment-name {
        font-size: 14px;
        line-height: 18px;
    }

    .description-upload {
        margin-right: 9px;
    }

    .download-invoice-list .download-invoice-content {
        display: none;
    }
}

@media (max-width: 374px) {}
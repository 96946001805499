@media (max-width: 1023px) {
    .join-us-wrape .inner-wrape {
        flex-direction: column;
    }

    .join-us-wrape .big-size,
    .join-us-wrape .small-size {
        width: 100%;
    }

    .join-us-wrape .join-img {
        position: absolute;
        z-index: -1;
        height: 100%;
        object-fit: cover;
    }

    .join-us-wrape .content,
    .join-us-wrape .content a {
        position: unset;
    }
}

@media (max-width: 767px) {
    .join-us-wrape {
        margin-bottom: 40px;
    }

    .join-us-wrape .content {
        padding: 30px 15px;
    }

    .join-us-wrape h3 {
        font-size: 24px;
        line-height: 30px;
        margin-bottom: 10px;
    }

    .join-us-wrape .content a {
        padding: 7px 14px;
        gap: 5px;
    }
}
@media (max-width: 1249px) {}

@media (max-width: 1023px) {

    .dashboard-tab-col1,
    .dashboard-tab-col2 {
        width: 100%;
    }

    .dashboard-tab-row {
        gap: 30px;
    }

    .dashboard-tab-col2 {
        padding-bottom: 30px;
        border: 0;
    }

    .dash-tabs-content .dash-tab-inner-details {
        max-width: 100%;
    }
}

@media (max-width: 767px) {
    .dash-tabs-content {
        padding: 0px 15px;
    }

    .dash-tabs-content .dash-tab-inner-details {
        padding: 10px;
    }
}

@media (max-width: 549px) {}

@media (max-width: 374px) {
    .dash-analytic-card .order-title-content-main .order-title-list {
        font-size: 15px;
        line-height: 19px;
    }
}
@media (max-width: 1249px) {
    .inner-side-tab-listing .sidebar-dash-inner-list {
        padding: 10px 0px 0px 50px;
    }
    .sidebar-tabs-inner-list {
        padding: 23px 15px;
    }
    .sidebar-dash-details {
        padding: 0px 15px 40px 15px;
    }
 
}


@media (max-width: 1023px) {}

@media (max-width: 767px) {
    .sidebar-dash-details {
        padding: 0px 15px 30px;
    }
}

@media (max-width: 549px) {
    .sidebar-tabs-inner-list .sidebar-tabs-link {
        gap: 10px;
    }
    .sidebar-tabs-inner-list {
        padding: 15px 5px;
    }
    .inner-side-tab-listing .sidebar-dash-inner-list {
        padding: 5px 0px 0px 40px;
    }
    .sidebar-dash-inner-listitem .inner-refrence-sidelink, .side-tab-title .dash-side-title {
        font-size: 14px;
        line-height: 18px;
    }
}

@media (max-width: 374px) {
}
@media (max-width: 767px) {
    .help-wrape {
        padding: 30px 0 40px;
    }

    .help-wrape h5 {
        font-size: 30px;
        line-height: 37px;
        margin-bottom: 15px;
    }

    .help-wrape p {
        font-size: 16px;
        line-height: 22px;
        font-weight: 400;
    }
}

@media (max-width: 549px) {
    .help-wrape h5 {
        font-size: 24px;
        line-height: 30px;
    }

    .help-wrape p {
        font-size: 14px;
        line-height: 20px;
    }

    .help-wrape p a {
        text-decoration: underline;
        border: none;
    }
}
@media (max-width: 1249px) {
  
}

@media (max-width: 1023px) {
      .contact-modal-inner-main .contact-modal-header-title {
            font-size: 32px;
            line-height: 40px;
        }
}

@media (max-width: 767px) {
      .contact-content-left-main {
            max-width: 100%;
            flex: 0 0 100%;
            margin-right: 0px;
        }
        .contact-content-flex {
         flex-direction: column;
        }
     
}

@media (max-width: 549px) {
  
}

@media (max-width: 374px) {
  
}
.product-listing-section {
      padding: 30px 0px 0px;
}

.product-listing-title-flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
}

.product-listing-title-flex .filter-title-text {
      color: #000;
      font-family: "Inter";
      font-size: 25px;
      font-weight: 500;
      line-height: 31px;
}

.product-listing-title-flex .filter-clear-text {
      color: #EE682B;
      font-family: "Inter";
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      cursor: pointer;
}

.product-listing-row {
      display: flex;
}

.product-col-left {
      width: 100%;
      flex: 1;
}

.product-col-right {
      width: 100%;
      flex: 0 0 30%;
      max-width: 30%;
      margin-left: 22px;
}

.product-drop-flex .product-drop-ul {
      padding: 0px;
      display: flex;
      flex-wrap: wrap;
      margin: -11px;
}

.product-drop-ul .prodcut-drop-li {
      width: calc(100% / 4);
      padding: 11px;
}

.product-brand-main .product-brand-ul {
      padding: 0px;
      margin: 0px;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 20px;
}

.product-brand-main .prodcut-brand-li {
      padding: 0px;
}

.product-brand-main {
      margin: 30px 0px 90px;
}

.blue-fill-btn-main .blue-fill-btn.prodct-cart-btn {
      width: 100%;
}

.blue-fill-btn-main .blue-fill-btn.prodct-cart-btn:hover {
      background-color: transparent;
      color: #27447C;
}

.product-added-card {
      padding: 20px;
      margin: 50px 0px 40px;
}

.product-added-card .product-added-text {
      font-family: "Inter";
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      color: #808080;
      margin-bottom: 10px;
      display: -webkit-box;
      max-width: 100%;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      min-height: 18px;
}

.product-added-flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 9px 12px;
      border-bottom: 1px solid #EBEBEB;
}

.product-added-flex .product-item-name {
      color: #333;
      font-family: "Inter";
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      display: -webkit-box;
      max-width: 100%;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
}

.product-added-flex .product-item-remove-text {
      color: #EB5757;
      font-family: "Inter";
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      display: -webkit-box;
      max-width: 100%;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      cursor: pointer;
}

.orange-fill-btn-main .go-tocart-btn {
      width: 100%;
      margin-top: 30px;
}

.product-bulk-card {
      padding: 18px 21px;
}

.bulk-order-flex .bulk-order-text {
      color: #000;
      font-family: "Inter";
      font-size: 18px;
      font-weight: 500;
      line-height: 22px;
      display: -webkit-box;
      max-width: 100%;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
}

.bulk-order-flex .download-sample-text {
      color: #EE682B;
      font-family: "Inter";
      font-size: 14px;
      font-weight: 500;
      line-height: 18px;
      display: flex;
      align-items: center;
}

.bulk-order-flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
}

.download-icon {
      height: 24px;
      width: 24px;
      object-fit: contain;
      margin-right: 2px;
}

.upload-blue-main {
      margin: 20px 0px 30px;
}

.upload-blue-main .upload-blue-btn,
.upload-blue-main .upload-blue-btn:hover {
      color: #FFF;
      font-family: "Inter";
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      border-radius: 8px;
      background: #27447C;
      width: 100%;
      text-transform: capitalize;
      padding: 10px 20px;
}

.upload-file-icon {
      height: 24px;
      width: 24px;
      margin-right: 10px;
      object-fit: contain;
}

.product-bulk-card .payment-term-text {
      color: #000;
      font-family: "Inter";
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      margin-bottom: 10px;
}

.payment-tem-drop-main {
      margin-bottom: 52px;
}

.gray-fill-btn-main .gray-send-inquery-btn {
      width: 100%;
}

.modal-common-main .modal-common-style.goto-veryfication-modal-inner-main{
      max-width: 488px;
}

.goto-veryfication-modal-inner-main .veryfy-modal-para {
      color: #000;
      text-align: center;
      font-family: "Inter";
      font-size: 18px;
      font-weight: 400;
      line-height: 22px;
      margin: 35px auto 30px;
      max-width: 375px;
}

.modal-veryfy-btn-main {
      display: flex;
      margin: auto;
      justify-content: center;
}

.modal-logo {
      width: 169px;
      object-fit: contain;
      height: 35px;
}

.modal-logo-main {
      display: flex;
      margin: auto;
      justify-content: center;
}

.main-product-listing {
      background: #FAFAFA;
}

.page-title-main .page-title {
      color: #000;
      font-size: 34px;
      font-weight: 500;
      line-height: 40px;
      margin-bottom: 30px;
}

.main-you-may-like {
      background-color: #F1F8FF;
      padding: 50px 0px;
}

.choose-file-cust-flex {
      display: flex;
}

.choose-file-left-main {
      flex: 1;
}

.choose-file-left-main .choose-file-text {
      color: #808080;
      font-family: "Inter";
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      padding: 12px 20px;
}

.choose-file-right-main {
      max-width: 132px;
      width: 100%;
      flex: 0 0 132px;
      background-color: #D8D8D8;
      border-radius: 0px 7px 7px 0px;
}

.choose-file-right-main .choose-browse-text {
      color: #515151;
      font-family: "Inter";
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      padding: 12px 20px;
      display: flex;
}

.choose-file-main {
      position: relative;
      margin: 22px 0px 20px;
      cursor: pointer;
      border-radius: 8px;
      /* overflow: hidden; */
}

.choose-file-main .choose-file-control {
      opacity: 0;
      z-index: 99;
}

.choose-file-cust-main {
      position: absolute;
      width: 100%;
      top: 0px;
      opacity: 1;
      cursor: pointer;
      border-radius: 8px;
      border: 1px solid #CACACA
}

.choose-file-main .choose-file-control input {
      padding: 12px 0px;
      cursor: pointer;
}
.down-info-icon{
      height: 20px;
      width: 20px;
      object-fit: contain;
      margin-left: 12px;
}
.user-interface-wrape {
    margin-bottom: 90px;
}

.user-interface-wrape .user-interface-title {
    text-transform: uppercase;
    font-size: 50px;
    line-height: 57px;
    font-weight: 600;
    color: #27447C;
    margin-bottom: 70px;
    max-width: 500px;
}

.user-interface-wrape .user-interface-box-wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 50px;
}

.user-interface-wrape .user-interface-box .user-interface-img {
    width: 100%;
    margin-bottom: 20px;
}

.user-interface-wrape .bottom-info {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 10px;
}

.user-interface-wrape .bottom-info .detail {
    font-size: 20px;
    line-height: 26px;
    font-family: "Poppins";
}

.user-interface-wrape .bottom-info .link {
    color: #27447C;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    gap: 6px;
    min-width: fit-content;
}

.user-interface-wrape .bottom-info .link .arrow {
    width: 20px;
    height: 20px;
}

@media (max-width: 1023px) {
    .view-our-category-wrape {
        margin-bottom: 60px;
    }

    .view-our-category-wrape .title-box .title {
        font-size: 40px;
        line-height: 47px;
        margin-bottom: 5px;
    }

    .view-our-category-wrape .title-box {
        margin-bottom: 30px;
    }

    .view-our-category-wrape .category-box-wrapper {
        gap: 30px;
    }

    .view-our-category-wrape .category-box .img-wrape h6 {
        margin: 15px;
        font-size: 24px;
        line-height: 30px;
    }

    .view-our-category-wrape .bottom-info {
        padding: 15px;
    }

    .view-our-category-wrape .bottom-info .link img {
        width: 50px;
        height: 50px;
    }
}

@media (max-width: 767px) {
    .view-our-category-wrape {
        margin-bottom: 40px;
    }

    .view-our-category-wrape .title-box .title {
        font-size: 30px;
        line-height: 37px;
    }

    .view-our-category-wrape .title-box {
        margin-bottom: 20px;
    }

    .view-our-category-wrape .category-box-wrapper {
        grid-template-columns: repeat(1, 1fr);
    }
}

@media (max-width: 549px) {
    .view-our-category-wrape .category-box .img-wrape h6 {
        margin: 10px;
        font-size: 20px;
        line-height: 26px;
    }

    .view-our-category-wrape .bottom-info {
        padding: 10px;
        gap: 10px;
    }

    .view-our-category-wrape .bottom-info .detail {
        font-size: 14px;
        line-height: 20px;
    }

    .view-our-category-wrape .bottom-info .link img {
        width: 34px;
        height: 34px;
    }

    .view-our-category-wrape .title-box .sub-title {
        font-size: 14px;
        line-height: 18px;
    }

    .view-our-category-wrape .title-box .title {
        font-size: 24px;
        line-height: 30px;
    }
}
/* start admin dashboard page structure css */
.admin-main .dashboard-containt-main {
    background-color: #FFFFFF;
    min-height: 100vh;
    padding: 88px 24px 24px 314px;
    transition: 0.5s all;
}

.admin-main .admin-header-main.admin-header-deactive+.dashboard-containt-main {
    padding: 88px 24px 24px 24px;
}

.admin-main .dashboard-main {
    width: 100%;
}

.admin-main .bergurmenu-icon {
    min-width: 25px;
    min-height: 25px;
    max-width: 25px;
    max-height: 25px;
}

/* end admin dashboard page structure css */


/* start admin sidebar css */
.admin-main .admin-sidebar-logo-main .sidebar-close-btn {
    display: none;
    padding: 0;
    min-width: unset;
}

.admin-main .admin-sidebar-icons {
    margin-right: 8px;
    height: 20px;
    width: 20px;
    object-fit: contain;
}

.admin-main .dashboard-left-main {
    background-color: #27447C;
    height: 100vh;
    left: 0;
    overflow: auto;
    position: fixed;
    top: 0;
    width: 100%;
    max-width: 290px;
    z-index: 99;
    border-right: 1px solid #E5E7EB;
    transition: 0.5s all;
}

.dashboard-left-main.admin-sidebar-deactive {
    left: -290px;
}

.admin-main .admin-sidebar-logo {
    max-width: 150px;
    width: 100%;
    object-fit: contain;
}

.admin-main .close-icon {
    display: none;
}

.admin-main .admin-sidebar-logo-main {
    padding: 16px;
    background-color: white;
    min-height: 66px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.admin-main .admin-sidebar-list-main {
    padding: 16px;
    overflow: auto;
    height: calc(100vh - 66px);
}

.admin-main .admin-header-drop-main .drop-header-btn {
    padding: 0px;
    min-width: unset;
}

.admin-main .admin-sidebar-list-main .admin-sidebar-list {
    padding: 0px;
}

.admin-main .admin-sidebar-list-main .admin-sidebar-listitem {
    padding: 0px;
}

.admin-main .admin-sidebar-list-main .admin-sidebar-listitem:not(:last-child) {
    margin-bottom: 10px;
}

.admin-main .admin-sidebar-list-main .admin-sidebar-link {
    font-size: 14px;
    line-height: 21px;
    color: #FFFFFF;
    padding: 10px 12px 10px;
    width: 100%;
    text-decoration: none;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.admin-main .admin-sidebar-list-main .admin-sidebar-listitem:last-child .admin-sidebar-link {
    margin-bottom: 0;
}

.admin-main .admin-sidebar-list-main .admin-sidebar-link.active {
    color: white;
    background: linear-gradient(45deg, #3e53a0, transparent);
    border-radius: 4px;
}

.admin-main .admin-sidebar-listitem .expandless-icon,
.admin-main .admin-sidebar-listitem .expandmore-icon {
    position: absolute;
    right: 10px;
    top: 10px;
}

.admin-main .admin-sidebar-list-main .admin-sidebar-submenulist .admin-sidebar-listitem {
    margin-bottom: 0;
    padding-left: 28px;
}

/* end admin sidebar css */


/* start admin header css */
.admin-main .admin-header-main {
    background-color: #FFFFFF;
    position: fixed;
    top: 0px;
    left: 290px;
    right: 0px;
    z-index: 9;
    padding: 15px 20px;
    border-bottom: 1px solid #E5E7EB;
    transition: 0.5s all;
}

.admin-main .admin-header-deactive {
    left: 0px;
}

.admin-main .admin-header-main.active .admin-sidebar-logo {
    opacity: 0;
    display: none;
}

.admin-main .admin-header-logo-main .admin-bergur-button {
    padding: 0px;
    min-width: unset;
}

.admin-main .toggle-btn {
    padding: 0;
}

.admin-main .toggle-btn:hover {
    background-color: transparent;
}

.admin-main .admin-header-logo-main {
    display: flex;
    flex-direction: row-reverse;
    gap: 10px;
}

.admin-main .admin-header-left {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
}

.admin-main .admin-header-right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.admin-main .admin-header-right .admin-header-icon-box {
    padding: 0px;
    min-width: unset;
    margin-right: 23px;
}

.admin-main .admin-header-icon {
    min-width: 20px;
    min-height: 20px;
    max-width: 20px;
    max-height: 20px;
    object-fit: contain;
}

.admin-main .admin-header-profile-icon {
    min-width: 36px;
    min-height: 36px;
    max-width: 36px;
    max-height: 36px;
    object-fit: cover;
}

/* end admin header css */


/* start header profile dropdown  */
.admin-main .flex-drop-main .admin-header-drop {
    font-size: 12px;
    line-height: 18px;
    color: #6B7280;
    text-align: start;
    margin-left: 12px;
    text-transform: capitalize;
}

.admin-main .flex-drop-main .header-drop-title {
    font-weight: 700;
}

.admin-main .flex-drop-main {
    display: flex;
    align-items: center;
}

.admin-main .drop-header {
    height: 15px;
    width: 15px;
    margin-right: 6px;
}

.admin-main .drop-header-menu .MuiPaper-rounded {
    box-shadow: 10px 10px 20px #0000001A;
    border: 1px solid #E5E7EB;
    margin-top: 13px;
    border-radius: 1px;
}

.admin-main .drop-header {
    height: 15px;
    width: 15px;
    margin-right: 6px;
}

.admin-main .drop-header-menu .drop-header-menuitem {
    font-size: 12px;
    line-height: 14px;
    color: #6B7280;
    border-bottom: 1px solid #E5E7EB;
    padding: 11px 16px;
}

.admin-main .drop-header-menu ul {
    padding: 0px;
}

/* end header profile dropdown  */
.latest-posts {
    background-color: #F1F8FF;
    padding: 50px 0;
}

.latest-posts .latest-posts-title {
    font-size: 40px;
    line-height: 47px;
    font-weight: 600;
    margin-bottom: 30px;
}

.latest-posts .latest-posts-bottom {
    display: flex;
    gap: 40px;
}

.latest-posts .latest-posts-bottom .latest-posts-left {
    width: calc(60% - 20px);
}

.latest-posts .latest-posts-bottom .latest-posts-left .blog-image-box {
    position: relative;
    display: flex;
    margin-bottom: 30px;
}

.latest-posts .latest-posts-bottom .latest-posts-left .blog-video-play {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.latest-posts .latest-posts-bottom .latest-posts-left .blog-image {
    border-radius: 8px;
    width: 100%;
}

.latest-posts .latest-posts-bottom .latest-posts-left .posts-title {
    font-size: 30px;
    line-height: 36px;
    font-weight: 600;
    margin-bottom: 20px;
}

.latest-posts .latest-posts-bottom .latest-posts-left .posts-content {
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 30px;
}

.latest-posts .latest-posts-bottom .latest-posts-left .posts-btn {
    padding: 12px 24px;
    text-align: center;
    border-radius: 100rem;
    background: #0B80C5;
    color: #fff;
    text-transform: capitalize;
}

.latest-posts .latest-posts-bottom .latest-posts-right {
    width: calc(40% - 20px);
}

.latest-posts .latest-posts-bottom .latest-posts-right .latest-posts-item:not(:last-child) {
    margin-bottom: 40px;
}

.latest-posts .latest-posts-bottom .latest-posts-right .blog-image{
    border-radius: 8px;
    margin-bottom: 30px;
    width: 100%;
}

.latest-posts .latest-posts-bottom .latest-posts-right .posts-content{
    font-size: 18px;
    line-height: 22px;
    font-weight: 500;
}
.rfq-sent-main .rfq-table-container {
    margin-top: 10px;
}

.rfq-sent-main .rfq-table-container {
    margin-top: 10px;
}

.rfq-sent-main .rfq-table-container .rfq-table-list,
.rfq-sent-main .rfq-table-container .rfq-table-list-item {
    padding: 0;
}

.rfq-sent-main .rfq-table-container .rfq-table-list {
    list-style: disc;
    list-style-position: inside;
    width: fit-content;
    margin: auto;
}

.rfq-sent-main .rfq-table-container .rfq-table-list-item {
    display: list-item;
    line-height: 20px;
}

.rfq-sent-main .rfq-table-container .rfq-table-list-item::marker {
    font-size: 12px;
}

.rfq-sent-main .rfq-table-container .rfq-bid-value {
    color: #CACACA;
}
.the-team {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 30px;
    margin-bottom: 90px;
}

.the-team .the-team-left {
    max-width: 405px;
    width: 100%;
}

.the-team .the-team-left .the-team-title {
    font-size: 40px;
    line-height: 47px;
    font-weight: 700;
    margin-bottom: 50px;
    color: #27447C;
}

.the-team .the-team-name-box .the-team-name-item {
    font-size: 28px;
    line-height: 34px;
}

.the-team .the-team-name-box .the-team-name-item:not(:last-child) {
    border-bottom: 2px solid #27447C;
    padding-bottom: 30px;
    margin-bottom: 30px;
}

.the-team .the-team-name-box .the-team-name-item .the-team-name {
    font-weight: 700;
}

.the-team .the-team-name-box .the-team-name-item .the-team-designation {
    font-weight: 500;
}

.the-team .the-team-right {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.the-team .the-team-right .the-team-img-top{
    display: flex;
    gap: 15px;
}

.the-team .the-team-right .the-team-img-top .the-team-img-top-left{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 15px;
}

.the-team .the-team-right .the-team-img-top .the-team-img-top-right{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
}

.the-team .the-team-right .the-team-img-bottom{
    display: flex;
    justify-content: center;
}

.the-team .the-team-right .team-member-img1 {
    width: 176px;
    height: 181px;
    object-fit: cover;
}

.the-team .the-team-right .team-member-img2 {
    width: 274px;
    height: 241px;
    object-fit: cover;
}

.the-team .the-team-right .team-member-img3 {
    width: 230px;
    height: 236px;
    object-fit: cover;
}

.the-team .the-team-right .team-member-img4 {
    width: 289px;
    height: 186px;
    object-fit: cover;
}

.the-team .the-team-right .team-member-img5 {
    width: 426px;
    height: 149px;
    object-fit: cover;
}
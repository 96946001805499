@media (max-width: 1249px) {
    .states-wrape .state-title {
        font-size: 60px;
        margin-bottom: 5px;
    }

    .states-wrape .state-title span {
        font-size: 60px;
    }

    .states-wrape .state-sub-title {
        font-size: 18px;
        line-height: 22px;
    }
}

@media (max-width: 1024px) {
    .states-wrape .inner {
        grid-template-columns: repeat(2, 1fr);
    }

    .states-wrape .state {
        padding: 60px 15px;
    }

    .states-wrape .state:nth-child(1) {
        background: #27457C;
    }

    .states-wrape .state:nth-child(2) {
        background: #0B80C4;
    }

    .states-wrape .state:nth-child(3) {
        background: #0B80C4;
    }

    .states-wrape .state:nth-child(4) {
        background: #27457C;
    }
}

@media (max-width: 767px) {
    .states-wrape {
        margin-bottom: 40px;
    }
}

@media (max-width: 549px) {

    .states-wrape .state-title {
        font-size: 40px;
        margin-bottom: 5px;
    }

    .states-wrape .state-title span {
        font-size: 40px;
    }

    .states-wrape .state-sub-title {
        font-size: 10px;
        line-height: 14px;
    }

    .states-wrape .state {
        padding: 30px 15px;
    }

    .states-wrape .inner {
        gap: 5px;
    }
}
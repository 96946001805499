.main-analytics-dashboard{
    padding: 44px 46px 44px 59px;
}

.analytics-user-list-main {
    box-shadow: 0px 10px 20px 0px rgba(126, 133, 148, 0.08);
    background: #fff;
    padding: 23px 20px;
}

.analytics-main-grid {
    display: flex;
    flex-wrap: wrap;
}

.analytics-main-grid .analytics-col1 {
    width: calc(100% / 4);
    border-right: 1px solid #E3E3E3;
}

.order-title-content-main .order-title-list {
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    color: #717171;
    margin-bottom: 20px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.order-title-content-main  .order-review-listing {
    font-size: 36px;
    font-weight: 600;
    color: #333;
    line-height: 42px;
    text-align: center;
}

.order-title-list .dot-analytics {
    width: 8px;
    height: 8px;
    display: block;
    border-radius: 50%;
    margin-right: 8px;
}

.analytics-main-grid .order-title-list .green-dots,
.dash-analytic-card .order-title-list .green-dots{
    background: #3BB77E;
}

.analytics-main-grid .order-title-list .orange-dots,
.dash-analytic-card  .order-title-list .orange-dots {
    background: #EE682B;
}


.analytics-details-grid-list .analytics-grid-col-row {
    display: flex;
    flex-wrap: wrap;
    margin: 0px -10px;
}

.analytics-grid-col-row .dash-analytics-col2 {
    width: 50%;
    max-width: 50%;
    flex: 0 0 50%;
    padding: 0px 10px;
}

.analytics-details-grid-list {
    padding-top: 61px;
}

.dash-analytic-card {
    border-radius: 10px;
    border: 1px solid #CACACA;
    background: #FFF;
    padding: 21px 23px;
    margin-bottom: 35px;
}

.analytics-main-grid .analytics-col1:last-child{
    border-right: 0px;
}

.dash-analytic-card  .order-title-content-main .order-title-list{
    font-size: 22px;
    line-height: 28px;
}

.dash-analytic-card .order-title-content-main .order-review-listing {
    font-size: 60px;
    line-height: 68px;
    padding: 34.5px 0px 54.5px 0px;
}

.dash-analytic-card .order-title-content-main .order-title-list {
    justify-content: start;
}
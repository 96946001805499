@font-face {
  font-family: "Inter";
  src: url("../fonts/inter/Inter-Thin.woff2") format("woff2"),
    url("../fonts/inter/Inter-Thin.woff") format("woff"),
    url("../fonts/inter/Inter-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url("../fonts/inter/Inter-ExtraLight.woff2") format("woff2"),
    url("../fonts/inter/Inter-ExtraLight.woff") format("woff"),
    url("../fonts/inter/Inter-ExtraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url("../fonts/inter/Inter-Light.woff2") format("woff2"),
    url("../fonts/inter/Inter-Light.woff") format("woff"),
    url("../fonts/inter/Inter-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url("../fonts/inter/Inter-Regular.woff2") format("woff2"),
    url("../fonts/inter/Inter-Regular.woff") format("woff"),
    url("../fonts/inter/Inter-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url("../fonts/inter/Inter-Medium.woff2") format("woff2"),
    url("../fonts/inter/Inter-Medium.woff") format("woff"),
    url("../fonts/inter/Inter-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url("../fonts/inter/Inter-SemiBold.woff2") format("woff2"),
    url("../fonts/inter/Inter-SemiBold.woff") format("woff"),
    url("../fonts/inter/Inter-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url("../fonts/inter/Inter-Bold.woff2") format("woff2"),
    url("../fonts/inter/Inter-Bold.woff") format("woff"),
    url("../fonts/inter/Inter-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url("../fonts/inter/Inter-ExtraBold.woff2") format("woff2"),
    url("../fonts/inter/Inter-ExtraBold.woff") format("woff"),
    url("../fonts/inter/Inter-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url("../fonts/inter/Inter-Black.woff2") format("woff2"),
    url("../fonts/inter/Inter-Black.woff") format("woff"),
    url("../fonts/inter/Inter-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("../fonts/poppins/Poppins-Thin.woff2") format("woff2"),
    url("../fonts/poppins/Poppins-Thin.woff") format("woff"),
    url("../fonts/poppins/Poppins-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("../fonts/poppins/Poppins-ExtraLight.woff2") format("woff2"),
    url("../fonts/poppins/Poppins-ExtraLight.woff") format("woff"),
    url("../fonts/poppins/Poppins-ExtraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("../fonts/poppins/Poppins-Light.woff2") format("woff2"),
    url("../fonts/poppins/Poppins-Light.woff") format("woff"),
    url("../fonts/poppins/Poppins-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("../fonts/poppins/Poppins-Regular.woff2") format("woff2"),
    url("../fonts/poppins/Poppins-Regular.woff") format("woff"),
    url("../fonts/poppins/Poppins-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("../fonts/poppins/Poppins-Medium.woff2") format("woff2"),
    url("../fonts/poppins/Poppins-Medium.woff") format("woff"),
    url("../fonts/poppins/Poppins-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("../fonts/poppins/Poppins-SemiBold.woff2") format("woff2"),
    url("../fonts/poppins/Poppins-SemiBold.woff") format("woff"),
    url("../fonts/poppins/Poppins-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("../fonts/poppins/Poppins-Bold.woff2") format("woff2"),
    url("../fonts/poppins/Poppins-Bold.woff") format("woff"),
    url("../fonts/poppins/Poppins-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("../fonts/poppins/Poppins-ExtraBold.woff2") format("woff2"),
    url("../fonts/poppins/Poppins-ExtraBold.woff") format("woff"),
    url("../fonts/poppins/Poppins-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("../fonts/poppins/Poppins-Black.woff2") format("woff2"),
    url("../fonts/poppins/Poppins-Black.woff") format("woff"),
    url("../fonts/poppins/Poppins-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

* {
  box-sizing: border-box;
  padding: 0px;
  margin: 0px;
}

html {
  scroll-behavior: smooth;
  scroll-padding-block-start: 100px;
}

body {
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  font-family: "Inter", "sans-serif";
  color: #000;
}

body.mobile-nav-open {
  overflow: hidden;
}

.cus-container {
  max-width: 1280px;
  padding: 0 15px;
  margin: 0 auto;
}

img {
  max-width: 100%;
  height: auto;
}

a {
  text-decoration: none;
}

/* error css */
.error-text {
  color: red;
  font-size: 12px;
  line-height: 15px;
}

/* error css */

/* coming-soon css */
.coming-soon-wrape {
  display: flex;
  height: 60vh;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5;
}

.coming-soon-wrape h2 {
  font-size: 40px;
  line-height: 47px;
  font-weight: 600;
  color: #27457c;
  letter-spacing: 15px;
  text-transform: uppercase;
  text-align: center;
}

.coming-soon-wrape h2 span {
  font-size: 40px;
  color: #0b80c4;
}

/* coming-soon css */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-transition-delay: 9999s;
  transition-delay: 9999s;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.cus-scrollbar::-webkit-scrollbar {
  width: 3px;
  height: 5px;
  background-color: #ebebeb;
  border-radius: 100rem;
}

.cus-scrollbar::-webkit-scrollbar-thumb {
  background: #27447c;
  border-radius: 100rem;
}

.cus-scrollbar {
  scrollbar-color: #27447c #ebebeb;
  scrollbar-width: thin;
}

/* Breadcrumb Start */
.breadcrumb {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 16px 0;
}

.breadcrumb .breadcrumb-text {
  font-size: 14px;
  line-height: 20px;
  font-weight: 300;
  color: #000;
}

.breadcrumb .breadcrumb-icon {
  width: 20px;
  height: 20px;
}

/* Breadcrumb End */

/* Loader */
.spinner-box {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1301;
  position: fixed;
  left: 0;
  top: 0;
}

.spinner {
  position: relative;
  width: 12.4px;
  height: 12.4px;
}

.spinner::before,
.spinner::after {
  content: "";
  width: 100%;
  height: 100%;
  display: block;
  animation: spinner-b4c8mmmd 0.5s backwards,
    spinner-49opz7md 1.25s 0.5s infinite ease;
  border: 4.6px solid #ee682b;
  border-radius: 50%;
  box-shadow: 0 -25.6px 0 -5.6px #ee682b;
  position: absolute;
}

.spinner::after {
  animation-delay: 0s, 1.25s;
}

@keyframes spinner-b4c8mmmd {
  from {
    box-shadow: 0 0 0 -5.6px #ee682b;
  }
}

@keyframes spinner-49opz7md {
  to {
    transform: rotate(360deg);
  }
}

/* Loader */

/* Banner Image Start */
.banner-image {
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
}

.banner-image .banner-image-content {
  padding: 84px 0;
  position: relative;
  z-index: 1;
}

.banner-image .banner-image-content .banner-title {
  font-size: 60px;
  line-height: 67px;
}

.banner-image .banner-image-content .banner-content {
  font-size: 20px;
  line-height: 26px;
  margin-top: 10px;
}

/* Banner Image End */

/* dashboard-common Start */
.dashboard-common-header {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
  box-shadow: 0px 0px 12px 0px rgba(126, 133, 148, 0.08);
}

.dashboard-common-header .header-title {
  color: #27447c;
  font-family: "Poppins";
  font-size: 20px;
  line-height: 26px;
  font-weight: 500;
}

.dashboard-common-header .header-search {
  position: relative;
  width: 100%;
  max-width: 363px;
}

.dashboard-common-header .header-search .header-search-input {
  width: 100%;
  border-radius: 100rem;
  border: 1px solid #e4e4e4;
  background: #f6f6f6;
}

.dashboard-common-header .header-search .header-search-input input {
  padding: 8px 13px 8px 47px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 300;
  font-style: italic;
  color: #a6a6a6;
}

.dashboard-common-header .header-search .header-search-input::before,
.dashboard-common-header .header-search .header-search-input::after {
  display: none;
}

.dashboard-common-header .header-search .search-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 13px;
  width: 24px;
  height: 24px;
}

.dashboard-bg-main .table-list,
.dashboard-bg-main .table-list-item,
.review-modal-inner-main .table-list,
.review-modal-inner-main .table-list-item {
  padding: 0;
}

.dashboard-bg-main .table-list,
.review-modal-inner-main .table-list {
  list-style: disc;
  list-style-position: inside;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  width: fit-content;
  margin: auto;
}

.dashboard-bg-main .table-list-item,
.review-modal-inner-main .table-list-item {
  display: list-item;
  color: #bebebe;
}

.dashboard-bg-main .table-list-item::marker,
.review-modal-inner-main .table-list-item::marker {
  font-size: 12px;
}

.dashboard-bg-main .action-btn {
  min-width: unset;
  padding: 0;
  border-radius: 0;
}

.action-btn-menu .MuiMenu-paper {
  box-shadow: none;
}

.action-btn-menu .MuiList-root {
  border-radius: 8px;
  padding: 0;
  border: 1px solid #ebebeb;
}

.action-btn-menu .MuiList-root .MuiMenuItem-root {
  padding: 10px 14px;
  color: #000;
  font-size: 14px;
  line-height: 18px;
}

.action-btn-menu .MuiList-root .MuiMenuItem-root:not(:last-child) {
  border-bottom: 1px solid #ebebeb;
}

.dashboard-bg-main .action-select .MuiCheckbox-root {
  padding: 0;
}

.dashboard-bg-main .action-select .MuiCheckbox-root svg {
  color: #000;
}

.dashboard-bg-main .bid-value {
  font-weight: 600;
}

.dashboard-bg-main .ra-value {
  color: #3bb77e;
}

.dashboard-bg-main .common-table-body .common-table-td.table-row-action-td {
  background-color: #f5f5f5;
  padding: 16px 50px;
}

.dashboard-bg-main .table-row-action {
  display: flex;
  gap: 12px;
}

/* dashboard-common end */

.common-table .common-table-head {
  background: #ededed;
}

.common-table .common-table-head tr th {
  color: #000;
  font-family: "Poppins";
  line-height: 18px;
  border-bottom: 0;
}

.common-table .common-table-body tr td {
  color: #000;
  font-size: 14px;
  line-height: 18px;
  border-bottom: 1px solid #ebebeb;
}

.common-table .common-table-head .common-table-th,
.common-table .common-table-body .common-table-td {
  padding: 20px 15px;
}

.action-common-table.common-table .common-table-body tr td {
  border-bottom: 0;
}

.action-common-table.common-table {
  border-collapse: separate;
  border-spacing: 0 20px;
}

.action-common-table.common-table
  .common-table-body
  tr
  .action-common-table-td {
  border-top: 1px solid #ebebeb;
  border-bottom: 1px solid #ebebeb;
}

/* start custom dropdown with checkbox  */
.cust-dropdown-bg {
  border-radius: 8px;
  border: 1px solid var(--stroke, #ebebeb);
  background: #fff;
  box-shadow: 0px 0px 12px 0px rgba(126, 133, 148, 0.08);
}

.cust-dropdown-bg fieldset {
  border: 0;
}

.cust-dropdown-main {
  padding: 8px 16px;
  margin-bottom: 1px;
}

.cust-dropdown-main .cust-dropdown-button-main {
  color: #000000;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "Inter";
}

.cust-dropdown-inner-main .cust-dropdown-ul {
  margin: 0px;
  padding: 0px;
}

.cust-dropdown-inner-main .cust-dropdown-ul svg {
  color: #000;
}

.cust-dropdown-ul .cust-dropdown-li {
  padding: 0px 10px;
  margin: 0px;
}

.cust-dropdown-check-flex {
  padding: 15px 0px;
  margin: 0px;
  border-bottom: 1px solid#EBEBEB;
  width: 100%;
}

.cust-dropdown-li:last-child .cust-dropdown-check-flex {
  border-bottom: 0px;
}

.cust-dropdown-arrow-icon {
  height: 24px;
  width: 24px;
  object-fit: contain;
}

.cust-drodown-outer-main {
  width: 100%;
}

.checkbox-main .checkbox-lable {
  color: #333333;
  margin-left: 0px;
  margin-right: 0px;
  font-family: "Inter";
  font-weight: 400;
}

.checkbox-main {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.checkbox-main .MuiButtonBase-root {
  margin-right: 10px;
  padding: 0px;
}

.cust-dropdown-bg .cust-dropdown-ul .MuiSelect-multiple {
  padding: 12px 40px 12px 16px;
}

.MuiMenu-list[aria-labelledby="demo-multiple-checkbox-label"]
  .cust-dropdown-li
  .check-dropdown-cus {
  padding: 0px 10px 0px 0px;
}

.MuiMenu-list[aria-labelledby="demo-multiple-checkbox-label"]
  .cust-dropdown-li {
  padding: 15px 5px;
  border-bottom: 1px solid var(--stroke, #ebebeb);
  margin: 0px 5px;
}

.MuiMenu-list[aria-labelledby="demo-multiple-checkbox-label"]
  .cust-dropdown-li {
  background-color: transparent;
}

.MuiMenu-list[aria-labelledby="demo-multiple-checkbox-label"]
  .cust-dropdown-li
  .check-text-details
  span {
  color: #333;
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
}

.MuiMenu-list[aria-labelledby="demo-multiple-checkbox-label"]
  .cust-dropdown-li
  .check-dropdown-cus
  svg
  path {
  color: #333333;
}

.MuiMenu-list[aria-labelledby="demo-multiple-checkbox-label"] {
  padding: 0px;
}

/* end custom dropdown with checkbox  */

/* product card css */
.brand-main {
  border-radius: 5px;
  width: 100%;
  background: #fff;
  box-shadow: 0px 0px 8.57143px 0px rgba(126, 133, 148, 0.08);
}

.brand-content-main {
  padding: 10px 13px;
}

.brand-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 7px;
}

.brand-img-box {
  display: flex;
}

.brand-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  min-height: 180px;
}

.brand-flex .brand-name {
  color: #27447c;
  font-size: 11px;
  line-height: 15px;
  font-family: "Poppins";
  font-weight: 600;
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  min-height: 15px;
}

.brand-flex .brand-estate-text {
  color: #bebebe;
  font-family: "Poppins";
  font-size: 8px;
  font-weight: 500;
  line-height: 15px;
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.brand-content-main .brand-para {
  color: #333;
  font-family: "Inter";
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  margin-bottom: 13px;
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

/* product card css */

/* start common button */
.blue-border-btn-main .blue-border-btn,
.blue-border-btn-main .blue-border-btn:hover {
  border-radius: 8px;
  border: 1px solid #27447c;
  padding: 10px 20px;
  color: #27447c;
  font-size: 14px;
  line-height: 18px;
  text-transform: math-auto;
}

.blue-fill-btn-main .blue-fill-btn,
.blue-fill-btn-main .blue-fill-btn:hover {
  border-radius: 8px;
  background-color: #27447c;
  border: 1px solid #27447c;
  padding: 10px 20px;
  color: #ffffff;
  font-size: 14px;
  line-height: 18px;
  text-transform: math-auto;
}

.orange-fill-btn-main .orange-fill-btn,
.orange-fill-btn-main .orange-fill-btn:hover {
  border-radius: 8px;
  background-color: #ee682b;
  border: 1px solid #ee682b;
  padding: 10px 20px;
  color: #ffffff;
  font-size: 14px;
  line-height: 18px;
  text-transform: math-auto;
}

.gray-fill-btn-main .gray-fill-btn,
.gray-fill-btn-main .gray-fill-btn:hover {
  border-radius: 8px;
  background: #c9c9c9;
  color: #fff;
  font-size: 14px;
  line-height: 18px;
  text-transform: math-auto;
  padding: 10px 20px;
}

/* end common button */

.common-card {
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 0px 12px 0px rgba(126, 133, 148, 0.08);
}

/* dropdown css */
.dropdown-box .form-control {
  width: 100%;
  position: relative;
  border-radius: 8px;
  background: #fff;
}

.dropdown-box .form-control .dropdown-select fieldset,
.dropdown-box .form-control .dropdown-select:hover fieldset {
  border: 1px solid #ebebeb;
  border-radius: 8px;
}

.dropdown-box .form-control svg {
  color: #000;
}

.dropdown-select .MuiSelect-select {
  display: flex;
  padding: 10px 15px;
  border-radius: 4px;
  color: #000;
  font-family: "Inter";
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}

.dropdown-box .MuiMenu-list .drop-menuitem {
  color: #6b7280;
  font-size: 12px;
  line-height: 20px;
  font-family: "Poppins";
}

/* dropdown css */

.drop-side-tab-arrow .arrowup-drop.sidebar-menu-open {
  transform: unset;
}

.drop-side-tab-arrow .arrowup-drop {
  transform: rotate(180deg);
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-close-icon {
  height: 18px;
  width: 18px;
  object-fit: contain;
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
  z-index: 1;
}

.modal-common-main .modal-common-style {
  border: 0px;
  border-radius: 20px;
  overflow: hidden;
  background: #fff;
  max-width: 746px;
  width: calc(100% - 30px);
  padding: 30px 15px;
}

.modal-common-main .modal-common-style:focus-visible {
  outline: none;
}

.comman-modal-inner-title {
  text-align: center;
  margin-bottom: 50px;
}

.comman-modal-inner-title .comman-modal-title {
  color: #000;
  font-size: 25px;
  font-weight: 500;
  line-height: 31px;
}

.comman-form-group .error-text {
  color: red;
  font-size: 12px;
  line-height: 16px;
  margin-top: 5px;
}

.comman-form-group .error-text.error-text-side-content {
  position: absolute;
  top: 100%;
  left: 0;
}

.comman-form-group .comman-form-control input {
  padding: 11px 10px;
  height: unset;
  font-size: 14px;
  line-height: 18px;
}

.comman-form-group .comman-form-control input::-webkit-calendar-picker-indicator{
  cursor: pointer;
}

.comman-form-group .comman-form-control fieldset,
.comman-form-group .comman-form-control fieldset:hover,
.comman-form-group .comman-form-control fieldset:focus {
  border-color: #cacaca !important;
  padding: 0px;
  border-radius: 8px;
}

.comman-form-group .comman-form-label {
  margin-bottom: 10px;
  color: #000;
  font-size: 14px;
  line-height: 18px;
  margin-top: 0px;
}
.comman-form-group .comman-form-label.bold {
  font-size: 17px;
  line-height: 26px;
  font-weight: 600;
}

.comman-form-group .comman-form-control input::placeholder {
  color: #cacaca;
  opacity: 1;
  font-weight: 400;
}

.comman-form-control {
  width: 100%;
}

.orange-outline-btn-main .orange-outline-inner,
.orange-outline-btn-main .orange-outline-inner:hover,
.orange-outline-btn-main .orange-outline-inner:focus {
  border-radius: 8px;
  border: 1px solid #ee682b;
  padding: 10px 20px;
  color: #ee682b;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-transform: math-auto;
}

.upload-img-file-modal .upload-label-details,
.upload-img-file-modal .upload-label-details:hover,
.upload-img-file-modal .upload-label-details:focus {
  background: transparent;
  box-shadow: none;
  border-radius: 8px;
  border: 2.6px dashed #1e77cc;
  width: 100%;
  text-align: center;
  flex-direction: column;
  padding: 30px;
}

.upload-img-file-modal .upload-label-details .browse-cloud-text {
  color: #000;
  font-size: 20px;
  line-height: 26px;
  text-transform: math-auto;
  margin-bottom: 5.2px;
}

.upload-img-file-modal .upload-label-details .browse-cloud-accepted {
  color: #b4b4b4;
  font-size: 12px;
  line-height: 16px;
  text-transform: math-auto;
}

.upload-main-content .upload-cloud {
  max-width: 98.8px;
  height: 83.2px;
  object-fit: cover;
  margin-bottom: 5.2px;
}

.payment-upload-title .upload-pay-title {
  color: #3d3d3d;
  font-size: 20px;
  font-weight: 500;
  line-height: 26px;
}

.payment-upload-title {
  margin-bottom: 30px;
}

.modal-hgt-scroll {
  max-height: 550px;
  overflow: auto;
  padding: 0px 15px;
}

.common-view-details-group .common-label-title {
  color: #808080;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 10px;
}

.common-view-details-group .common-details-show {
  font-weight: 500;
}

.po-view-mb-details {
  margin-bottom: 20px;
}

/* stepper css */
.stepper-list .stepper-list-details {
  padding: 0px;
  display: flex;
  justify-content: center;
  align-items: start;
  width: 100%;
}

.stepper-list-details .stepper-listitem-details {
  padding: 0px;
  margin-right: 85px;
  position: relative;
}

.stepper-listitem-details .border-stepper-green {
  background: #e5f5eb;
  width: 27px;
  height: 27px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  margin-bottom: 8px;
  z-index: 9;
  position: relative;
}

.border-stepper-green .white-border-stepper {
  background: #fff;
  height: 13px;
  width: 13px;
  border-radius: 50%;
}

.stpper-content-details {
  text-align: center;
}

.stpper-content-details .stpper-name-title,
.stpper-content-details .stpper-traker-date {
  color: #a8a8a8;
  font-family: "Poppins";
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 4px;
}

.stepper-list-details .stepper-listitem-details:last-child {
  margin-right: 0px;
}

.stepper-max-content-details {
  max-width: 830px;
  margin: auto;
}

.stepper-list-details .stepper-listitem-details::after {
  background: #e5f5eb;
  content: "";
  height: 6px;
  position: absolute;
  right: 60%;
  bottom: -2px;
  top: 11px;
  width: calc(100% - -81px);
}

.stepper-active-order .border-stepper-green {
  background: #11a64a;
}

.stepper-list-details .stepper-active-order.stepper-listitem-details::after {
  background: #11a64a;
}

.stepper-list-details .stepper-active-order .stpper-traker-date {
  display: block;
}

.stepper-list-details .stepper-active-order .stpper-name-title,
.stepper-list-details .stepper-active-order .stpper-traker-date {
  color: #000;
}

.stepper-list-details .stepper-listitem-details:first-child::after,
.stpper-content-details .stpper-traker-date {
  display: none;
}

.stepper-details-check {
  margin-bottom: 70px;
}

/* stepper css */

.dashboard-bg-main .ra-value.request-color-text {
  color: #bebebe;
}

.dashboard-bg-main .ra-value.scheduled-color-text {
  color: #0b80c4;
}

.textarea-common-control .common-textarea-autosize {
  border-radius: 10px;
  border: 1px solid var(--strokes-2, #cacaca);
  width: 100%;
  resize: none;
  padding: 11px 10px;
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  font-family: "Inter";
}

.common-table {
  min-width: 949px;
}

.common-table-small {
  min-width: 649px;
}

.my-setting-input-main .comman-form-group .comman-form-control input {
  padding: 11px 50px 11px 10px;
}

.view-seller-image{
  padding-left: 3px;
  color: green;
  cursor: pointer;
}

@media (max-width: 767px) {
      .setting-row {
            grid-template-columns: repeat(1, 1fr);
      }

      .setting-content-main {
            padding: 20px 15px 30px;
      }

      .my-setting-input-main .input-edit-text {
            top: 33px;
            right: 10px;
            font-size: 14px;
            line-height: 18px;
      }
}
.my-account-flex-main {
      display: flex;
}

.my-account-main {
      background-color: #EBEBEB;
      padding: 30px 0px;
}

.my-account-left-main {
      max-width: 307px;
      width: 100%;
      border-right: 1px solid #EBEBEB;
      background: #FFF;
}

.my-account-right-main {
      flex: 1;
      background: #FFF;
}

.user-profile-flex {
      display: flex;
      align-items: center;
      padding: 0px 17px;
}

.account-prfile-main {
      margin: 25px 8px 0px;
      border-bottom: 1px solid #EBEBEB;
}

.my-account-left-main .user-img-main {
      border: 2px solid #EE682B;
}

.user-img-main {
      background-color: #27447C;
      width: 91px;
      height: 91px;
      border-radius: 50%;
      margin-right: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
}

.user-img {
      width: 89px;
      height: 89px;
      object-fit: cover;
      border-radius: 50%;
}

.user-img-main .user-name-small {
      color: #FFF;
      font-family: "Inter";
      font-size: 30px;
      font-weight: 600;
      line-height: 26px;
}

.user-content-box .user-name-text {
      color: #000;
      font-family: "Inter";
      font-size: 20px;
      font-weight: 600;
      line-height: 26px;
      margin-bottom: 5px;
}

.user-content-box .user-number-text {
      color: #BEBEBE;
      font-family: "Inter";
      font-size: 18px;
      font-weight: 400;
      line-height: 22px;
}

.orange-fill-btn-main .account-user-verify {
      width: 100%;

}

.account-veryfy-btn-main {
      margin: 24px 12px 31px;
}

.account-tabs-main .account-tabs {
      border-right: 0px;
}

.user-verify-bedge-main {
      position: absolute;
      display: flex;
      top: -6px;
      right: 0px;
}

.erroricon {
      height: 24px;
      width: 24px;
      object-fit: contain;
}


.user-verify-icon-flex .user-verify-text {
      color: #000;
      font-family: "Inter";
      font-size: 14px;
      font-weight: 500;
      line-height: 18px;
}

.user-verify-icon-flex {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      padding-left: 23px;
      margin-top: 6px;
}

.user-verify-icon {
      height: 20px;
      width: 20px;
      object-fit: contain;
}
.track-order-main-content {
    padding: 24px;
}

.track-order-flex-justify {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
}

.order-id-details .order-id-title {
    color: #000;
    font-family: 'Poppins';
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
}

.order-id-details .order-id-title span {
    color: #EE682B;
}

.estimated-time-details {
    text-align: end;
}

.estimated-time-details .estimated-time-title,
.estimated-time-details .estimated-date-title {
    color: #000;
    font-family: Poppins;
    font-size: 18px;
    line-height: 22px;
    font-weight: 400;
}

.estimated-time-details .estimated-date-title span {
    color: #989898;
    font-size: 16px;
    line-height: 20px;
    margin-left: 7px;
}

.estimated-time-details .estimated-date-title {
    margin-top: 4px;
}


.flex-odrer-show {
    display: flex;
    margin-bottom: 40px;
}

.order-img-show-conteny .order-cart-show {
    border-radius: 20.625px;
    width: 241px;
    height: 169px;
    object-fit: cover;
}

.order-img-show-conteny {
    margin-right: 18px;
}

.order-view-listing .description-order-title {
    color: #000;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    margin-bottom: 20px;
}

.order-view-listing {
    max-width: 310px;
    width: 100%;
}

.description-order-content .order-listing-your {
    margin: unset !important;
}

.your-order-id-details .your-orderide-title {
    color: #000;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
    margin-bottom: 20px;
}

.shipping-address-title .ship-address-content {
    color: #000;
    font-size: 20px;
    font-weight: 500;
    line-height: 26px;
    margin-bottom: 20px;
}

.shipping-address-details {
    max-width: 379px;
    width: 100%;
}

.ship-address-show .ship-title-address {
    color: #333;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
}
.main-blogs {
    background-color: #f5f5f5;
    color: #000;
}

.recent-blogs .blogs-title {
    font-size: 50px;
    font-weight: 500;
    line-height: 57px;
    margin-bottom: 30px;
    margin-top: 16px;
}

.recent-blogs .recent-blogs-wrapper {
    display: flex;
    gap: 40px;
    padding-bottom: 50px;
}

.recent-blogs .recent-blogs-wrapper .recent-blogs-item {
    width: calc(60% - 20px);
}

.recent-blogs .recent-blogs-wrapper .recent-blogs-item .blog-image-box {
    display: flex;
}

.recent-blogs .recent-blogs-wrapper .recent-blogs-item .blog-image {
    border-radius: 8px;
    margin-bottom: 30px;
    width: 100%;
}

.recent-blogs .recent-blogs-wrapper .recent-blogs-item .blogs-item-title {
    font-size: 30px;
    line-height: 36px;
    font-weight: 600;
    margin-bottom: 20px;
    display: inline-block;
    color: #000;
}

.recent-blogs .recent-blogs-wrapper .recent-blogs-item .blogs-item-content {
    font-size: 20px;
    line-height: 26px;
}

.recent-blogs .recent-blogs-wrapper .recent-blogs-list {
    width: calc(40% - 20px);
}

.recent-blogs .recent-blogs-wrapper .recent-blogs-list .blogs-list-title {
    font-size: 30px;
    line-height: 36px;
    font-weight: 600;
    margin-bottom: 40px;
    display: flex;
    align-items: center;
    gap: 10px;
}

.recent-blogs .recent-blogs-wrapper .blogs-list-item-box .blogs-list-item {
    display: flex;
    align-items: flex-end;
    gap: 26px;
}

.recent-blogs .recent-blogs-wrapper .blogs-list-item-box .blogs-list-item:not(:last-child) {
    margin-bottom: 30px;
}

.recent-blogs .recent-blogs-wrapper .blogs-list-item .blog-image {
    width: 130px;
    height: 130px;
    border-radius: 4px;
    object-fit: cover;
}

.recent-blogs .recent-blogs-wrapper .blogs-list-item .blogs-list-item-title {
    font-weight: 700;
    margin-bottom: 20px;
    font-size: 14px;
    line-height: 18px;
}

.recent-blogs .recent-blogs-wrapper .blogs-list-item .blogs-list-item-content {
    font-size: 14px;
    line-height: 18px;
}
.upload-catalogue {
    border-top: 1px solid #D9D9D9;
    padding: 40px 0;
}

.upload-catalogue .upload-catalogue-title {
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    margin: 0 0 30px;
}